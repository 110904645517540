import React from 'react'

const TemarioVidaSaludable = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Módulo 1</span>
            <br /><br />
            <ul>
                <li className="fw-light">Generalidades sobre el Sistema digestivo.</li>
                <li className="fw-light">Concepto de Nutrición y Alimentación.</li>
                <li className="fw-light">Clasificación de los nutrientes:
                    <li>Macronutrientes: Hidratos de Carbono, proteínas y lípidos</li>
                    <li>Micronutrientes: Vitaminas y Minerales</li>
                </li>
                <li className="fw-light">Concepto de alimento.</li>
                <li className="fw-light">Leyes Fundamentales de la Alimentación.</li>
                <li className="fw-light">Metabolismo: definición.</li>
                <li className="fw-light">Concepto de metabolismo basal.</li>
                <li className="fw-light">Concepto de calorías.</li>
                <li className="fw-light">Requerimiento energético diario (RED).</li>
            </ul>
            <span>Módulo 2</span>
            <br /><br />
            <ul>
                <li className="fw-light">Concepto de alimentación saludable.</li>
                <li className="fw-light">Pirámide Nutricional antigua y Nueva Gráfica.</li>
                <li className="fw-light">¿Es posible comer sano sin eliminar grupos de alimentos?</li>
                <li className="fw-light">Dietas de moda (cetogénica o keto, détox, ayuno intermitente, DASH, Metabolismo acelerado): ventajas y desventajas.</li>
                <li className="fw-light">Efecto Rebote.</li>
                <li className="fw-light">Definición de alimentación Vegetariana y Vegana.</li>
                <li className="fw-light">Proteína animal vs proteína vegetal.</li>
            </ul>
            <span>Módulo 3</span>
            <br /><br />
            <ul>
                <li className="fw-light">Parte práctica</li>
                <li className="fw-light">Selección de alimentos.</li>
                <li className="fw-light">Definición de plato saludable.</li>
            </ul>
        </div>
    )
}

export default TemarioVidaSaludable