import React from 'react'
import { Link } from 'react-router-dom'

const Line = ({texto,href}) => {
    return (
        <div className="line d-flex align-items-center">
            <p className='poppins fs-3 ps-5 pe-5 pb-0 mb-0'>+</p>
            <p className='poppins ps-2 pb-0 mb-0'>{texto}</p>
            <button className='mx-3'>
                <Link to={href} className='poppins d-flex align-items-center'>
                    Ver servicio
                    <svg width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke='#FFFFFF' fill="none">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 5l0 14" />
                        <path d="M18 13l-6 6" />
                        <path d="M6 13l6 6" />
                    </svg>
                </Link>
            </button>
        </div>
    )
}

export default Line