import React from 'react';
import { motion } from 'framer-motion';
import Logo from '../multimedia/imagenes/LOGO.png'

const transicionEscalera = (ComponenteOriginal) => {
    const TransicionComponent = () => {
        const listItems = [1, 2, 3, 4, 5]; 
        const bigScreen = () => {
            return(
                <>
                    <ComponenteOriginal />
                    <motion.ul className='stair-transition'>
                        {listItems.map((item) => (
                        <motion.li
                        key={item}
                        initial={{ scaleY: 1 , pointerEvents:'all'}}
                        animate={{ scaleY: 0 , pointerEvents:'all'}}
                        exit={{ scaleY: 1 , pointerEvents:'all'}}
                        transition={{ duration: 0.8, ease: [0.22, 1, 0.36, 1], delay: item * 0.2 }}
                        />
                    ))}
                    </motion.ul>
                    <div className="stair-transition-logo">
                        <motion.img src={Logo} alt="Logo FISP" 
                        initial={{ scaleY: 1, y: 0, pointerEvents:'none' }}
                        animate={{ scaleY: 0, y: 500, pointerEvents:'none'}}
                        exit={{ scaleY: 1, y: 0, pointerEvents:'none' }}
                        transition={{ duration: 1.6, ease: [0.22, 1, 0.36, 1], delay: 0.6}}/>
                    </div>
                </>
            )
        }
        return bigScreen();
    };
    return TransicionComponent;
};

export default transicionEscalera;