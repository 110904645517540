import React, { useEffect, useRef, useState } from 'react'
import Axios from "axios";
import 'react-quill/dist/quill.snow.css';
import NavbarPortal from '../Navbars/NavbarPortal';
import FooterPortal from './FooterPortal';
import transicionEscalera from '../Transiciones/transicionEscalera';
import transicionBottomTop from '../Transiciones/transicionBottomTop';
import { ToastContainer, toast } from 'react-toastify'
import { Helmet } from 'react-helmet';
import idStreamImg from '../multimedia/imagenes/portal/id-stream.png'

const EditarStream = () => {

    const [idStream,setIdStream] = useState("")
    const [stream, setStream] = useState("")
    const actualizarStream = ()=>{
        if(idStream!=""){
            Axios.post("https://srv452353.hstgr.cloud/cambiarstream",{
                idStream: idStream,
            }).then((result)=>{
                console.log(result)
                console.log("Se actualizó el stream con éxito")
            }).catch((e)=>{
                console.log("Error")
                console.log(e)
            }).finally(()=>{
                toast.success("Fin del proceso")
                setTimeout(()=>{
                    window.location.reload();
                },[1500])
            })
        } else{
            alert("Falta ingresar el link o la posición del reel")
        }
    }

    const obtenerStream = ()=>{
        Axios.get("https://srv452353.hstgr.cloud/obtenerstream/")
        .then((response) => {
            console.log("Stream recibido con éxito")
            setStream(Array.from(response.data)) 
        })
        .catch((error) => {
            console.log("Error al traer el stream del home")
            console.error(error);
        })
    }

    const isMountedRef = useRef(false);
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        obtenerStream()
    },[])

    return (
        <main className='main-editor-container'>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <NavbarPortal />
            <ToastContainer />
            <div className='container-xxl editor-container d-flex flex-column align-items-center'>
                <div className="editor d-flex align-items-center flex-column gap-2 w-100">
                    <div className="col-12 pt-4 pb-5 d-flex flex-column px-4 bg-light">
                        <div className="stream-editor-container d-flex justify-content-center gap-5">
                            <div className="d-flex flex-column">
                                {
                                    stream!="" ? (
                                        <>
                                            <p>Stream actual en display:</p>
                                            <p>ID de Stream Actual: {stream[0].stream_embed_id}</p>
                                            <iframe width="560" height="349" src={`https://www.youtube.com/embed/${stream[0].stream_embed_id}`} frameborder="0" allowfullscreen></iframe>
                                        </>
                                    ) : (
                                        <div className="">Loading...</div>
                                    )
                                }
                            </div>
                            <div className="d-flex flex-column">
                                <p>Stream a modificar (ID Ingresada en el campo inferior):</p>
                                <p>ID a modificar: {idStream}</p>
                                <iframe width="560" height="349" src={`https://www.youtube.com/embed/${idStream}`} frameborder="0" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="mx-auto col-8 border-top d-flex gap-4 my-5 py-5">
                            <img className='rounded-5 bg-light' width={'400px'} src={idStreamImg} alt="idStreamImg" />
                            <div className="d-flex flex-column w-50">
                                <label htmlFor="link-reel">ID de Stream de Youtube</label>
                                <input className='p-2 rounded-3' id='link-reel' type="text" onChange={(e)=>{setIdStream(e.target.value)}} />
                            </div>
                        </div>
                        <div className="border p-2">
                            <button className='px-5 w-100 py-3 border-0' onClick={()=>{
                                actualizarStream()
                            }}>
                                Actualizar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <FooterPortal />
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(EditarStream) : transicionBottomTop(EditarStream);
