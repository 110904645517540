import React from 'react'

const TemarioNutricionDeportiva = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Módulo 1. Introducción a la Nutrición.</span>
            <br /><br />
                <ul>
                    <li className='fw-light'>Objetivos específicos:</li>
                    <li className='fw-light'>Establecer los conocimientos científicos básicos actualizados sobre nutrición.</li>
                    <li className='fw-light'>Bases moleculares de la nutrición</li>
                    <li className='fw-light'>Actualización en la composición de alimentos</li>
                    <li className='fw-light'>Tablas de composición de alimentos</li>
                    <li className='fw-light'>Nuevos alimentos:</li>
                    <ul>
                        <li className='fw-light'>Nutrientes funcionales</li>
                        <li className='fw-light'>Probióticos y Prebióticos</li>
                    </ul>
                    <li className='fw-light'>El agua y la correcta hidratación</li>
                    <li className='fw-light'>Nuevo etiquetado alimentario en Argentina</li>
                </ul>
            <span>Módulo 2. Metabolismo digestivo.</span>
            <br /><br />
                <ul>
                    <li className='fw-light'>Objetivos específicos:</li>
                    <li className='fw-light'>Establecer los conocimientos científicos sobre el metabolismo digestivo.</li>
                    <li className='fw-light'>Como funciona nuestro metabolismo.</li>
                    <li className='fw-light'>Fisiología digestiva.</li>
                    <li className='fw-light'>Regulación de la alimentación: Apetito y saciedad</li>
                    <li className='fw-light'>Sistema circadiano: ¿El horario de la comida es la clave?</li>
                </ul>
            <span>Módulo 3. Valoración del estado nutricional.</span>
            <br /><br />
                <ul>
                    <li className='fw-light'>Objetivos específicos:</li>
                    <li className='fw-light'>Brindar herramientas para la evaluación del estado nutricional.</li>
                    <li className='fw-light'>Bioenergética y nutrición</li>
                    <ul>
                        <li className='fw-light'>Necesidades energéticas</li>
                        <li className='fw-light'>Gasto energético diario basal</li>
                    </ul>
                    <li className='fw-light'>Valoración del estado nutricional</li>
                    <ul>
                        <li className='fw-light'>Análisis de la composición corporal por antropometría</li>
                        <li className='fw-light'>Nuevos métodos de evaluación. Análisis InBody.</li>
                    </ul>
                    <li className='fw-light'>Actualización en requerimientos nutricionales e ingestas recomendadas</li>
                    <li className='fw-light'>Alimentación en el adulto sano. Dietas de moda.</li>
                </ul>
            <span>Módulo 4. Fisiología muscular y metabólica relacionada con el ejercicio.</span>
            <br /><br />
                <ul>
                    <li className='fw-light'>Objetivos específicos:</li>
                    <li className='fw-light'>Establecer los conocimientos científicos básicos actualizados sobre la fisiología del deporte.</li>
                    <li className='fw-light'>Estructura del músculo y tipos de fibras musculares</li>
                    <ul>
                        <li className='fw-light'>La fibra muscular</li>
                        <li className='fw-light'>Fibra muscular tipo I</li>
                        <li className='fw-light'>Fibras musculares tipo II</li>
                    </ul>
                    <li className='fw-light'>Concepto de umbral láctico</li>
                    <li className='fw-light'>ATP y metabolismo de los fosfágenos</li>
                    <ul>
                        <li className='fw-light'>Rutas metabólicas para la resítesis de ATP durante el ejercicio</li>
                        <li className='fw-light'>Metabolismo de los fosfágenos</li>
                    </ul>
                </ul>
            <span>Módulo 5. Metabolismo en la práctica deportiva.</span>
            <br /><br />
                <ul>
                    <li className='fw-light'>Objetivos específicos:</li>
                    <li className='fw-light'>Establecer los conocimientos científicos básicos actualizados sobre la nutrición en sí aplicada al deporte</li>
                    <li className='fw-light'>Fisiología del ejercicio</li>
                    <li className='fw-light'>Adaptación fisiológica a los distintos tipos de ejercicio</li>
                    <li className='fw-light'>Adaptación metabólica al ejercicio. Regulación y control</li>
                    <li className='fw-light'>Valoración de las necesidades energéticas y del estado nutricional del deportista</li>
                </ul>
            <span>Módulo 6. Suplementos deportivos.</span>
            <br /><br />
                <ul>
                    <li className='fw-light'>Objetivos específicos:</li>
                    <li className='fw-light'>Ayudas Ergogénicas: suplementos nutricionales y actividad física</li>
                    <li className='fw-light'>Uso de sustancias anabólicas</li>
                    <li className='fw-light'>Suplementos Nutricionales: Estudios científicos</li>
                    <li className='fw-light'>Marco Legal de los suplementos nutricionales</li>
                    <li className='fw-light'>Suplementos Proteicos: industrialización, obtención y consumo.</li>
                    <li className='fw-light'>Cuando es necesaria la suplementación deportiva</li>
                </ul>
            <span>Módulo 7. Alimentación aplicada al deporte.</span>
            <br /><br />
                <ul>
                    <li className='fw-light'>Objetivos específicos:</li>
                    <li className='fw-light'>Establecer los conocimientos científicos básicos actualizados sobre los alimentos aplicados al deporte</li>
                    <li className='fw-light'>Metabolismo de los hidratos de carbono</li>
                    <ul>
                        <li className='fw-light'>Movilización de los hidratos de carbono durante el ejercicio</li>
                        <li className='fw-light'>Tipos de glucólisis</li>
                    </ul>
                    <li className='fw-light'>Metabolismo de los lípidos</li>
                    <ul>
                        <li className='fw-light'>Lipólisis</li>
                        <li className='fw-light'>Oxidación de grasa durante el ejercicio</li>
                        <li className='fw-light'>Cuerpos cetónicos</li>
                    </ul>
                    <li className='fw-light'>Metabolismo de las proteínas</li>
                    <ul>
                        <li className='fw-light'>Metabolismo del amonio</li>
                        <li className='fw-light'>Oxidación de aminoácidos</li>
                    </ul>
                    <li className='fw-light'>Bioenergética mixta de las fibras musculares</li>
                    <ul>
                        <li className='fw-light'>Fuentes energéticas y su relación con el ejercicio</li>
                        <li className='fw-light'>Uso de una u otra fuente de energía durante el ejercicio</li>
                    </ul>
                </ul>
            <span>Módulo 8. Vegetarianismo y veganismo.</span>
            <br /><br />
                <ul>
                    <li className='fw-light'>Objetivos específicos:</li>
                    <li className='fw-light'>Brindar los conceptos actualizados sobre la alimentación en Dieta vegetariana y vegana aplicada al deporte.</li>
                    <li className='fw-light'>Vegetarianismo y veganismo en la historia del deporte</li>
                    <li className='fw-light'>Deportista vegano</li>
                    <li className='fw-light'>Deportista vegetariano</li>
                    <li className='fw-light'>Errores frecuentes</li>
                    <ul>
                        <li className='fw-light'>Balance energético</li>
                        <li className='fw-light'>Consumo de proteína y fuentes de proteína</li>
                    </ul>
                    <li className='fw-light'>Vitaminas y minerales. Aporte correcto de Vitamina B12</li>
                </ul>
            <span>Módulo 9. Generalidades de la nutrición aplicada a enfermedades endocrino-metabólicas.</span>
            <br /><br />
                <ul>
                    <li className='fw-light'>Objetivos específicos:</li>
                    <li className='fw-light'>Conocer las pautas para aplicar el deporte en patologías específicas</li>
                    <li className='fw-light'>Diabetes tipo 2</li>
                    <li className='fw-light'>Obesidad</li>
                    <li className='fw-light'>Hipertensión arterial</li>
                    <li className='fw-light'>Dislipidemias</li>
                </ul>
            <span>Módulo 10. Nutrición, deporte y envejecimiento.</span>
            <br /><br />
                <ul>
                    <li className='fw-light'>Objetivos específicos:</li>
                    <li className='fw-light'>Brindar la actualización sobre antienvejecimiento en el deporte.</li>
                    <li className='fw-light'>Dinapenia y sarcopenia</li>
                    <li className='fw-light'>Envejecimiento:</li>
                    <ul>
                        <li className='fw-light'>Definición de envejecimiento</li>
                        <li className='fw-light'>¿Podemos considerar al envejecimiento una enfermedad?</li>
                    </ul>
                    <li className='fw-light'>Terapéutica nutricional hoy y a futuro.</li>
                </ul>
        </div>
    )
}

export default TemarioNutricionDeportiva