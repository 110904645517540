import React from 'react'

const TemarioEsteticaTerapeutica = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <b>Dermatología General. Fisiología de la piel </b>
            <br /><br />
                <span>A) Historia clínica en dermatología estética</span>
            <ul>
                <li><span className='fw-light'>Anamnesis</span></li>
                <li><span className='fw-light'>Exploración dermatológica y general.</span></li>
            </ul>
            <span>B) Lesiones Elementales</span>
            <ul>
                <li><span className='fw-light'>Lesiones elementales primarias</span></li>
                <li><span className='fw-light'>Lesiones elementales secundarias</span></li>
                <li><span className='fw-light'>características lesionales </span></li>
            </ul>
            <span>C) Exploración y exámenes complementarios.</span>
            <ul>
                <li><span className='fw-light'>Biopsia cutánea</span></li>
                <li><span className='fw-light'>examen directo de mucosas y cultivo</span></li>
                <li><span className='fw-light'>técnicas de inmunofluorescencia </span></li>
                <li><span className='fw-light'>pruebas espontaneas</span></li>
            </ul>
            <span>D) Fisiología de la piel</span>
            <ul>
                <li><span className='fw-light'>La piel: estructura y funciones</span></li>
            </ul>
            <span>E) Estructura de la piel. Epidermis, dermis e hipodermis</span>
            <ul>
                <li><span className='fw-light'>Anexos cutáneos</span></li>
                <li><span className='fw-light'>Pelo y glándulas sebáceas</span></li>
                <li><span className='fw-light'>Glándulas sudoríparas, ecrinas y apocrinas</span></li>
                <li><span className='fw-light'>Uñas</span></li>
            </ul>

            <span>F) Características funcionales de la piel</span>
            <ul>
                <li><span className='fw-light'>Trastornos de las glándulas sebáceas- sudoríparas. Alteraciones ungueales</span></li>
            </ul>
            <br />
            <span>Objetivos: al finalizar el módulo tendrán capacidad para diagnosticar lesiones elementales, primarias y secundarias de piel, así como también que patologías son compatibles con cada una de las lesiones. </span>
            <br /><br />
            <b>Patología dermatológica y su relación con estética.</b>
            <br /><br />
            <span>A) Patología dermatológica y su relación con estética.</span>
            <ul>
                <li><span className='fw-light'>Rostro: acné, rosácea, micosis, dermatitis, vitíligo, nevos, melasma, infecciones vasculares, angiomas, telangiectasias, adenomas, siringomas, cicatrices.</span></li>
                <li><span className='fw-light'>Cabello: alopecias, dermatitis seborreicas, micosis</span></li>
                <li><span className='fw-light'>Uñas: micosis, distrofias, granulomas, nevus</span></li>
                <li><span className='fw-light'>En cuerpo: Nevus, psoriasis, vitíligo, escabiosis, estrías, cicatrices hipertróficas, cicatrices queloides, hiperpigmentación, flaccidez, lesiones vasculares, angiomas y malformaciones vasculares, verrugas, papilomas</span></li>
            </ul>
            <span>Objetivo: Tendrán que tener la capacidad para el diagnóstico de patologías dermatológicas, solicitud de estudios complementarios: laboratorio, diagnostico por imágenes y dermatoscopias.</span>
            <br /><br />
            <b>Inesteticismos faciales</b>
            <ul>
                <li><span className='fw-light'>Consultas frecuentes por inesteticismos en rostro: acné, cicatrices residuales. Hipercromías, melasmas – fotodaño, lentigos. Nevos, verrugas, lesiones tumorales. Secuelas: asimetrías faciales. Flaccidez de la piel, surcos. Arrugas de expresión. Flaccidez.</span></li>
                <li><span className='fw-light'>Tratamientos de inesteticismos: Quirúrgicos, Peelings, Criocirugía, Aplicación de rellenos, Aplicación de toxina botulínica</span></li>
                <li><span className='fw-light'>Procedimientos quirúrgicos</span></li>
            </ul>
            <span>Objetivo: finalizado el módulo tendrán la capacidad de reconocer los diferentes inesteticismos cutáneos, realizar diagnósticos diferenciales y adquirir la practica para realizar el tratamiento adecuado con los procedimientos desarrollados en el módulo.</span>
            <br /><br />
            <b>Inesteticismos corporales  </b>
            <ul>
                <li><span className='fw-light'>Consultas frecuentes por inesteticismos corporales: Adiposidad y adiposidades localizadas, celulitis, flaccidez corporal, cicatrices corporales, lesiones tumorales, hipercromías, cambios de piel por envejecimiento.</span></li>
                <li><span className='fw-light'>Tratamientos adecuados: Radiofrecuencia, Ondas interferenciales, Ultrasonido, Radiofrecuencia fraccionada, Electroporación, Luz pulsada, Drenaje Linfático, Láseres, Aplicación de fosfatidil colina, Mesoterapia, Nuevas Tecnologías</span></li>
            </ul>
            <span>Objetivo: capacitar al alumno en el diagnóstico y estadio de inesteticismos corporales que presenta. Capacitar para la elección del tratamiento adecuado de acuerdo al grado de inisteticismos y características de cada paciente</span>
            <br /><br />
            <b>Potencial terapéutico de peelings superficiales, medios y profundos.</b>
            <ul>
                <li><span className='fw-light'>Tratamiento con diferentes tipos de peelings químicos como terapéutica y su asociación con peelings mecánicos. Microdermoabrasión</span></li>
            </ul>
            <span>Objetivo: capacitar al alumno para el correcto empleo de diferentes sustancias químicas y porcentaje de las mismas según la patología a tratar. </span>

            <br /><br />


            <b>Patología de cuero cabelludo, pelo y uñas.</b>
            <ul>
                <li><span className='fw-light'>Diagnóstico de diferentes tipos de alopecias y sus respectivos tratamientos.</span></li>
                <li><span className='fw-light'>Diagnostico de las distintas patologías ungueales y sus respectivos tratamientos. </span></li>
            </ul>
            <span>Objetivo: capacitar al alumno con tratamientos actualizados con un abordaje clínico y considerando el aspecto emocional.</span>

            &nbsp;

            <b>Aparatología, laser, bioseguridad, aspectos legales y gestión. </b>
            <ul>
                <li><span className='fw-light'>Aparatología adecuada para cada tipo de tratamientos.</span></li>
                <li><span className='fw-light'>Utilización de láseres en estética. Depilación, hiperpigmentaciones, rosácea, lesiones vasculares, tatuajes.</span></li>
                <li><span className='fw-light'>Bioseguridad aplicada en consultorios, centros y clínicas.</span></li>
                <li><span className='fw-light'>Aspectos legales a considerar.</span></li>
                <li><span className='fw-light'>Gestión aplicada en consultorios, centros y clínicas. Innovaciones tecnológicas, software historias clínicas, software y hardware.</span></li>
            </ul>
            <span>Objetivo: capacitar con las últimas innovaciones tecnológicas para su uso en terapéutica dermato-estetica. </span>

            <span> </span>
        </div>
    )
}

export default TemarioEsteticaTerapeutica