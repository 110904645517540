import React from 'react'
import KommoForm from './KommoForm.jsx'

const TestKommo = () => {
    return (
        <>
            <div className='py-5 d-flex align-items-center' style={{background: '#656565', height: '100vh'}}>
                <KommoForm />
            </div>
        </>
    )
}

export default TestKommo