import React, { useEffect, useRef } from 'react';
import Imagen from '../../multimedia/imagenes/servicios-tecnologicos/plataforma-salud.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar';
import SideNav from '../../Navbars/SideNav';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';
import HIS_cloud from '../../../Componentes/multimedia/imagenes/servicios-tecnologicos/plataforma-salud/his-cloud.jpg'
import teleconsulta from '../../../Componentes/multimedia/imagenes/servicios-tecnologicos/plataforma-salud/teleconsulta.jpeg'
import recuperacion_gastos from '../../../Componentes/multimedia/imagenes/servicios-tecnologicos/plataforma-salud/recuperacion-gastos.jpeg'
import receta_digital from '../../../Componentes/multimedia/imagenes/servicios-tecnologicos/plataforma-salud/receta-digital.jpg'
import integracion_whatsapp from '../../../Componentes/multimedia/imagenes/servicios-tecnologicos/plataforma-salud/integracion-whatsapp.jpeg'
import EHR from '../../../Componentes/multimedia/imagenes/servicios-tecnologicos/plataforma-salud/EHR.jpg'
import interoperabilidad from '../../../Componentes/multimedia/imagenes/servicios-tecnologicos/plataforma-salud/interoperabilidad.jpg'
import internacion from '../../../Componentes/multimedia/imagenes/servicios-tecnologicos/plataforma-salud/internacion.jpg'
import monitoreo from '../../../Componentes/multimedia/imagenes/servicios-tecnologicos/plataforma-salud/monitoreo.jpg'
import { Link } from 'react-router-dom';

const FirmaDigital = () => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        const progressBar = document.getElementById('progress-bar');
        const updateProgressBar = () => {
            const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
            const progress = (window.scrollY / totalHeight) * 100;
            progressBar.style.width = progress + "%";
        };
        window.addEventListener("scroll", updateProgressBar);
        return () => {
            window.removeEventListener("scroll", updateProgressBar);
        };
    }, []);

    return (
        <>
            <main className='servicio-tecnologico-detalle' id="main-element">
                <SideNav />
                <div className="servicio-main-image container-fluid px-0">
                    <img className='w-100' src={Imagen} alt="Plataforma de salud para organizaciones" />
                </div>
                <div className="container-servicio-content pt-0 pb-5 my-sm-5 mt-0 px-2 px-sm-5">
                    <div className="container-xxl">
                        <div className="sticky-title py-3" id='sticky-title'>
                            <h1 className='poppins'>Plataforma de salud para organizaciones</h1>
                            <div id="progress-bar"></div>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 mt-5'>Nuestro software, está diseñado para  procesos clínicos digitales. A través de nuestra plataforma los efectores de salud transfieren a sus pacientes los beneficios de la tecnología para una mejorar atención y seguridad del paciente</p>
                        <div className="d-flex mx-0 row justify-content-center pt-3 pb-5">
                            <div className="col-lg-6 pe-lg-4 pe-0 col-12 d-flex flex-column gap-3 pb-3 px-0">
                                <div className="detail-service-card pb-3 rounded-3 border box-shadow d-flex flex-column align-items-center">
                                    <img className='w-100 pb-3' src={HIS_cloud} alt="HIS_cloud" />
                                    <h2 className='poppins fs-5 fw-semibold'>HIS Cloud</h2>
                                    <p className='fs-5 px-3 fw-normal text-center'>Sistema Integral de Información Hospitalario</p>
                                </div>
                                <div className="detail-service-card pb-3 rounded-3 border box-shadow d-flex flex-column align-items-center">
                                    <img className='w-100 pb-3' src={recuperacion_gastos} alt="Recuperación de gastos" />
                                    <h2 className='poppins fs-5 fw-semibold'>Recupero de gastos</h2>
                                    <p className='fs-5 px-3 fw-normal text-center'>Plataforma para recupero de Gastos Hospitalarios, copagos, convenios, liquidación honorarios médicos, etc..</p>
                                </div>
                                <div className="detail-service-card pb-3 rounded-3 border box-shadow d-flex flex-column align-items-center">
                                    <img className='w-100 pb-3' src={receta_digital} alt="Receta Digital" />
                                    <h2 className='poppins fs-5 fw-semibold'>Receta Digital</h2>
                                    <p className='fs-5 px-3 fw-normal text-center'>Prescripción integrada, despacho domiciliario de Medicamentos, portal de recetas, etc.</p>
                                </div>
                                <div className="detail-service-card pb-3 rounded-3 border box-shadow d-flex flex-column align-items-center">
                                    <img className='w-100 pb-3' src={interoperabilidad} alt="Interoperabilidad" />
                                    <h2 className='poppins fs-5 fw-semibold'>Interoperabilidad</h2>
                                    <p className='fs-5 px-3 fw-normal text-center'>Plataforma de Interoperabilidad, HL7, FHIR, Red Nacional de Salud Digital.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 ps-lg-4 ps-0 col-12 d-flex flex-column gap-3 pb-3 px-0">
                                <div className="detail-service-card pb-3 rounded-3 border box-shadow d-flex flex-column align-items-center">
                                    <img className='w-100 pb-3' src={teleconsulta} alt="Teleconsulta" />
                                    <h2 className='poppins fs-5 fw-semibold'>Teleconsulta</h2>
                                    <p className='fs-5 px-3 fw-normal text-center'>Plataforma de Teleconsulta</p>
                                </div>
                                <div className="detail-service-card pb-3 rounded-3 border box-shadow d-flex flex-column align-items-center">
                                    <img className='w-100 pb-3' src={integracion_whatsapp} alt="Integración con WhatsApp" />
                                    <h2 className='poppins fs-5 fw-semibold'>Integración con WhatsApp</h2>
                                    <p className='fs-5 px-3 fw-normal text-center'>Envio de Notificación de Mensajes, cancelación de turnos, estudios de laboratorio, etc.</p>
                                </div>
                                <div className="detail-service-card pb-3 rounded-3 border box-shadow d-flex flex-column align-items-center">
                                    <img className='w-100 pb-3' src={EHR} alt="EHR" />
                                    <h2 className='poppins fs-5 fw-semibold'>EHR</h2>
                                    <p className='fs-5 px-3 fw-normal text-center'>Historia Clínica completa, SNOMED integrado, Soporte para toma de decisiones, etc.</p>
                                </div>
                                <div className="detail-service-card pb-3 rounded-3 border box-shadow d-flex flex-column align-items-center">
                                    <img className='w-100 pb-3' src={internacion} alt="Internación" />
                                    <h2 className='poppins fs-5 fw-semibold'>Internación</h2>
                                    <p className='fs-5 px-3 fw-normal text-center'>Escritorios para Médicos y enfermeras, Indicadores, Salas de situación, etc.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 d-flex flex-column gap-3 pt-lg-3 pt-0">
                                <div className="detail-service-card pb-3 rounded-3 border box-shadow d-flex flex-column align-items-center">
                                    <img className='w-100 pb-3' src={monitoreo} alt="Monitoreo de pacientes" />
                                    <h2 className='poppins fs-5 fw-semibold'>Monitoreo de pacientes</h2>
                                    <p className='fs-5 px-3 fw-normal text-center'>Monitoreo de patologías crónicas, COVID, Dengue, protocolos a medida, post internación, app móvil para plataformas Android / iOS.</p>
                                </div>
                            </div>
                        </div>
                        <Link to='/servicios/contacto/'>
                            <button className='py-3 position-relative text-white text-shadow d-flex align-items-center justify-content-center mb-5 rounded-4'>
                                CONSULTA TU PRESUPUESTO
                                <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </button>
                        </Link>
                    </div>
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default transicionBottomTop(FirmaDigital);