import React, { useContext, useEffect, useRef, useState } from 'react'
import Axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';   
import Calendar from 'react-calendar';  
import 'react-calendar/dist/Calendar.css';
import { ToastContainer, toast } from 'react-toastify'
import NavbarPortal from '../Navbars/NavbarPortal';
import FooterPortal from './FooterPortal';
import transicionEscalera from '../Transiciones/transicionEscalera';
import transicionBottomTop from '../Transiciones/transicionBottomTop';
import { context } from '../Context/Context';
import { Helmet } from 'react-helmet';

const AgregarNota = () => {
    const TOOLBAR_OPTIONS = {
        toolbar:  [
        [{ header: [1, 2, 3, 4, 5, false] }],
        [{ font: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ["image", "blockquote", "code-block"],
        ["clean"],
        ["link"]
    ]
    }

    const { convertTittleToUrl } = useContext(context)

    const [titulo,setTitulo] = useState("")
    const [categoria,setCategoria] = useState("")
    const [descripcion,setDescripcion] = useState("")
    const [imagen,setImagen] = useState()
    const [linkVideo,setLinkVideo] = useState("")
    const [cuerpoNota,setCuerpoNota] = useState("")
    const [mensajeCaracteres, setMensajeCaracteres] = useState('');
    const [calendarValue, setCalendarValue] = useState();
    const [artRel1, setArtRel1] = useState("")
    const [artRel2, setArtRel2] = useState("")
    const [artRel3, setArtRel3] = useState("")
    const [noticias,setNoticias] = useState([])

    const obtenerNotas = ()=>{
        Axios.get("https://srv452353.hstgr.cloud/obtenernotas/")
        .then((response) => {
            console.log("Noticias recibidas")
            setNoticias(Array.from(response.data));
            console.log(response.data)
        })
        .catch((error) => {
            console.log("Error")
            console.error(error);
        })
        .finally(() => {
            console.log("Proceso finalizado")
        });
    }

    const enviarNoticia = ()=>{
        const dateElements = calendarValue.toLocaleDateString().split('/');
        const formattedDate = `${dateElements[2]}/${dateElements[1]}/${dateElements[0]}`;
        if(titulo!=""&&categoria!=""&&descripcion!=""&&imagen!=""&&linkVideo!=""&&cuerpoNota!=""&&calendarValue!=""&&artRel1!=""&&artRel2!=""&&artRel3!=""){
            if(titulo.length>=20&&titulo.length<=65){
                Axios.post("https://srv452353.hstgr.cloud/subirnota",{
                    titulo: titulo,
                    categoria: categoria,
                    descripcion: descripcion,
                    imagen: imagen,
                    linkVideo: linkVideo,
                    cuerpoNota: cuerpoNota,
                    url: convertTittleToUrl(titulo),
                    fecha: formattedDate,
                    artRel1: artRel1,
                    artRel2: artRel2,
                    artRel3: artRel3
                }).then((result)=>{
                    console.log("Se envio con exito")
                    toast.success("Se subió la nota con éxito.")
                }).catch((e)=>{
                    console.log("Error")
                    console.log(e)
                    toast.error("Error al subir la nota.")
                }).finally(()=>{
                    toast.success("Terminó el proceso")
                    setTimeout(()=>{
                        window.location.reload();
                    },[1500])
                })
            } else{
                toast.error("El titulo debe ser corregido a la cantidad de caracteres correspondiente.")
            }
        }else{
            toast.error("Debes completar todos los campos.")
        }
        
    }

    const subirImagen = async (e) => {
        const img = e.target.files[0];
        const base64 = await convertirBase64(img);
        setImagen(base64);
    };

    const convertirBase64 = (file) => {
        return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
        resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
        reject(error);
        };
    });
    };

    const tituloChange = (e) => {
        const value = e.target.value;
        if (value.length <= 99) {
            setTitulo(value);
            if (value.length < 20) {
                setMensajeCaracteres('*El titulo debe por lo menos tener 20 caracteres.');
            } else {
                setMensajeCaracteres('');
            }
        } else {
            setMensajeCaracteres('Llegaste al limite de 100 caracteres.');
        }
    };

    const isMountedRef = useRef(false);
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        obtenerNotas()
    },[])

    return (
        <main className='main-editor-container'>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <NavbarPortal />
            <ToastContainer />
            <div className='container-xxl editor-container d-flex flex-column align-items-center'>
                <div className="editor d-flex align-items-center flex-column gap-2 w-100">
                    <div className="col-12 pt-4 pb-5 d-flex flex-column px-4">
                        <label className='pb-2' htmlFor="title-input">Titulo de la noticia</label>
                        <input 
                            id='title-input'
                            className="p-2 mb-3" 
                            placeholder="Titulo de la noticia..."
                            maxLength={65}
                            autoComplete="off"
                            type="text" 
                            name="titulo"
                            onChange={(e)=>{
                                tituloChange(e)
                            }}
                        />
                        {mensajeCaracteres && <p className="text-danger" style={{ fontSize: '14px' }}>{mensajeCaracteres}</p>}
                        <label className='pb-2' htmlFor="image-input">Imagen principal</label>
                        <input
                            id='image-input'
                            name='imagen'
                            className="mb-3" 
                            type="file" 
                            required
                            onChange={(e)=>{
                                subirImagen(e)
                            }}
                        />
                        <img className='w-100 mb-3' src={imagen} />
                        <input 
                            className="p-3 mb-3" 
                            placeholder="Descripcion/Preview"
                            autoComplete="off"
                            type="text" 
                            name="descripcion"
                            onChange={(e)=>{
                                setDescripcion(e.target.value)
                            }}
                        />
                        <label className='pb-2' htmlFor="category-input">Categoria</label>
                        <select 
                            name="category"
                            id="category-input"
                            onChange={(e)=>{
                                setCategoria(e.target.value)
                            }}
                            className="form-select mb-3 p-2"
                            aria-label="Default select example">
                            <option value="" selected disabled></option>
                            <option value="datafisp">DataFISP</option>
                            <option value="desde-adentro">Desde Adentro</option>
                            <option value="vida-sana">Vida Sana</option>
                            <option value="real-food">Real Food</option>
                            <option value="el-nudo">El Nudo</option>
                            <option value="tecnofisp">TecnoFISP</option>
                            <option value="en-foco">En Foco</option>
                            <option value="eso-que-no-sabias">Eso que no sabías</option>
                        </select>
                        <label className='pb-2' htmlFor="youtube-input">ID - Link Youtube (Opción de Incorporar en video)</label>
                        <input 
                            id='youtube-input'
                            className="p-2 mb-3" 
                            placeholder="ID - Link Youtube (Opción de Incorporar en video)"
                            autoComplete="off"
                            type="text" 
                            name="Titulo"
                            onChange={(e)=>{
                                setLinkVideo(e.target.value)
                            }}
                        />
                        <div className="editor">
                            <ReactQuill theme="snow" value={cuerpoNota} onChange={setCuerpoNota} modules={TOOLBAR_OPTIONS} />
                        </div>
                        <div className="d-flex calendar-container py-5">
                            <div className='col-4 border p-5 d-flex justify-content-center align-items-center'>
                                <p className='uppercase text-center text-balanced'>Seleccionar fecha para la que la noticia sea visible</p>
                            </div>
                            <div className="col-4 d-flex justify-content-center align-items-center">
                                <Calendar onChange={setCalendarValue} value={calendarValue} />
                            </div>
                            <div className="col-4 border p-5 d-flex justify-content-center align-items-center flex-column">
                                <p>La nota <b className='text-danger'>NO</b> será visible hasta:</p>
                                <p className='fs-2'>{calendarValue ? calendarValue.toLocaleDateString() : ''}</p>
                            </div>
                        </div>
                        <div className="d-flex flex-column py-5 px-2">
                            <div className="d-flex flex-column">
                                <label className='pb-2' htmlFor="article-select">Seleccionar articulo relacionado 3</label>
                                <select 
                                    name="article"
                                    id="article-select"
                                    onChange={(e)=>{
                                        const selectedNoticia = noticias.find(noticia => noticia.titulo === e.target.value);
                                        console.log(selectedNoticia.titulo)
                                        setArtRel1(selectedNoticia.titulo)
                                    }}
                                    className="form-select mb-3 p-2"
                                    aria-label="Default select example">
                                    <option value="" selected disabled></option>
                                    {
                                        noticias.map((noticia,index)=>{
                                            return(
                                                <option key={index} value={noticia.titulo}>{noticia.titulo}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div w-100 className="d-flex flex-column">
                                <label className='pb-2' htmlFor="article-select">Seleccionar articulo relacionado 2</label>
                                <select 
                                    name="article"
                                    id="article-select"
                                    onChange={(e)=>{
                                        const selectedNoticia = noticias.find(noticia => noticia.titulo === e.target.value);
                                        console.log(selectedNoticia.titulo)
                                        setArtRel2(selectedNoticia.titulo)
                                    }}
                                    className="form-select mb-3 p-2"
                                    aria-label="Default select example">
                                    <option value="" selected disabled></option>
                                    {
                                        noticias.map((noticia,index)=>{
                                            return(
                                                <option key={index} value={noticia.titulo}>{noticia.titulo}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div w-100 className="d-flex flex-column">
                                <label className='pb-2' htmlFor="article-select">Seleccionar articulo relacionado 3</label>
                                <select 
                                    name="article"
                                    id="article-select"
                                    onChange={(e)=>{
                                        const selectedNoticia = noticias.find(noticia => noticia.titulo === e.target.value);
                                        console.log(selectedNoticia.titulo)
                                        setArtRel3(selectedNoticia.titulo)
                                    }}
                                    className="form-select mb-3 p-2"
                                    aria-label="Default select example">
                                    <option value="" selected disabled></option>
                                    {
                                        noticias.map((noticia,index)=>{
                                            return(
                                                <option key={index} value={noticia.titulo}>{noticia.titulo}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="border p-2">
                            <button className='px-5 w-100 py-3 border-0' onClick={()=>{
                                enviarNoticia()
                            }}>
                                Subir nota
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <FooterPortal />
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(AgregarNota) : transicionBottomTop(AgregarNota);
