import React from 'react'
import { Link } from 'react-router-dom'

const FooterPortal = () => {
    return (
        <div className='container-fluid footer-portal'>
            <div className="container-xxl h-100 d-flex flex-column align-items-center justify-content-center">
                <div className='footer-items-container h-50 d-flex align-items-center'>
                    <div className="col h-100 py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column gap-2">
                            <h5>CONTACTO</h5>
                            <h6>Cerrito 1050, Piso 9 CABA, Buenos Aires, C1010</h6>
                            <h6>
                                <a className='d-flex align-items-center gap-1' href="https://mail.google.com/mail/?view=cm&to=marketing@fisp.org.ar">
                                    <svg width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#888888" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                                        <path d="M3 7l9 6l9 -6" />
                                    </svg>
                                    marketing@fisp.org.ar
                                </a>
                            </h6>
                            <h6>
                                <a className='d-flex align-items-center gap-1' href="tel:+541152522597">
                                    <svg width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#888888" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                    </svg>
                                    +54 9 11 5252-2597
                                </a>
                            </h6>
                            <h6><Link to="/terminos-y-condiciones">Terminos y condiciones</Link></h6>
                        </div>
                    </div>
                </div>
                <div className="mt-3 w-75 d-flex justify-content-center copyright">
                    <h6>© Copyright FISP 2024</h6>
                </div>
            </div>
        </div>
    )
}

export default FooterPortal