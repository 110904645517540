import React from 'react'
import Logo from '../../Componentes/multimedia/imagenes/logo-iniciales-oscuro.png'
import { Link } from 'react-router-dom'

const RegularNavbar = () => {
    return (
        <nav className='regular-navbar'>
            <div className='container-xxl h-100'>
                <ul className='d-flex align-items-center position-relative w-100 gap-5 px-0 mx-0 py-3 my-0'>
                    <div className="position-absolute logo-container d-flex align-items-center gap-5">
                        <Link to="/">
                            <img src={Logo} alt="logo fisp" />
                        </Link>
                    </div>
                    <input type="checkbox" id="hamburger_checkbox" />
                    <label htmlFor="hamburger_checkbox" className="hamburger_button">
                        <span></span>
                        <span></span>
                        <span></span>
                    </label>
                    <div className='d-flex items'>
                        <li><Link to="/la-fundacion/">LA FUNDACION</Link></li>
                        <li><Link to="/staff/">STAFF</Link></li>
                        <li><Link to="/servicios/contacto/">CONTACTO</Link></li>
                        <div className="socials">
                            <ul className='d-flex list-unstyled gap-3'>
                                <li>
                                    <a href="https://www.youtube.com/channel/UC2beEC4cxsAIzpcYtyryb-Q" target='_blank'>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M3 5m0 4a4 4 0 0 1 4 -4h10a4 4 0 0 1 4 4v6a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4z" />
                                            <path d="M10 9l5 3l-5 3z" />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/fispsalud/" target='_blank'>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
                                            <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                            <path d="M16.5 7.5l0 .01" />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/fispsalud" target='_blank'>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/fisp-org/" target='_blank'>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                                            <path d="M8 11l0 5" />
                                            <path d="M8 8l0 .01" />
                                            <path d="M12 16l0 -5" />
                                            <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tiktok.com/@fispsalud" target='_blank'>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M9 12a4 4 0 1 0 4 4v-12a5 5 0 0 0 5 5"></path>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://open.spotify.com/show/5VlWWkUHnHON4PIodMQw79?si=mKHoxjCURxW336L2W-R1CA&nd=1" target='_blank'>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                            <path d="M8 11.973c2.5 -1.473 5.5 -.973 7.5 .527" />
                                            <path d="M9 15c1.5 -1 4 -1 5 .5" />
                                            <path d="M7 9c2 -1 6 -2 10 .5" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ul>
                <div className="bottom-line d-flex align-items-center justify-content-center gap-3 w-100">
                    <p className=''>© Copyright FISP 2024</p>
                    <p className=''><Link to="/terminos-y-condiciones">Terminos y condiciones</Link></p>
                </div>
            </div>
        </nav>
        )
}

export default RegularNavbar