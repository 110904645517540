import { useParams } from 'react-router-dom';
import CharlasCorporativas from './CharlasCorporativas';
import EstrategiaRedesSociales from './EstrategiaRedesSociales';
import PublicidadEnLinea from './PublicidadEnLinea';
import ConsultoriaAsesoria from './ConsultoriaAsesoria';
import EmailWhatsappMarketing from './EmailWhatsappMarketing';
import ContenidoMundoDigital from './ContenidoMundoDigital';
import PaginaError from '../../PaginaError';
import ArmadoPaginasWeb from './ArmadoPaginasWeb';

const ProduccionMarketingRedirect = () => {
    const { service } = useParams();
    let serviceComponent;
    switch (service) {
        case 'charlas-corporativas':
            serviceComponent = <CharlasCorporativas />
            break;
        case 'estrategias-redes-sociales':
            serviceComponent = <EstrategiaRedesSociales />
            break;
        case 'publicidad-en-linea':
            serviceComponent = <PublicidadEnLinea />
            break;
        case 'consultoria-y-asesoria':
            serviceComponent = <ConsultoriaAsesoria />
            break;
        case 'email-whatsapp-marketing':
            serviceComponent = <EmailWhatsappMarketing />
            break;
        case 'contenido-mundo-digital':
            serviceComponent = <ContenidoMundoDigital />
            break;
        case 'armado-de-paginas-web':
            serviceComponent = <ArmadoPaginasWeb />
            break;
        default:
            serviceComponent = <PaginaError />
            break;
    }
    return serviceComponent
};

export default ProduccionMarketingRedirect;