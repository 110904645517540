import React, { useContext, useEffect } from 'react'
import { Navigate } from "react-router-dom";
import { context } from '../Context/Context';

const LoginEducativoRedirect = ({RutaPrivada}) => {
    const {isLogged} = useContext(context)

    return isLogged || sessionStorage.getItem("Login") ? RutaPrivada : <Navigate to="/educativo/login" />;
};

export default LoginEducativoRedirect