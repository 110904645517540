import React,{useRef,useEffect} from 'react'
import Cubo from './Cubo'
import RegularNavbar from '../../Navbars/RegularNavbar'
import SideNav from '../../Navbars/SideNav'
import { Link } from 'react-router-dom'
import kinesiologia from '../../multimedia/imagenes/empresas-saludables/iconos/kinesiologia.svg'
import estresLaboral from '../../multimedia/imagenes/empresas-saludables/iconos/estres-laboral.svg'
import primerosAuxilios from '../../multimedia/imagenes/empresas-saludables/iconos/primeros-auxilios.svg'
import medicina from '../../multimedia/imagenes/empresas-saludables/iconos/medicina.svg'
import nutricionista from '../../multimedia/imagenes/empresas-saludables/iconos/nutricionista.svg'
import cardiologia from '../../multimedia/imagenes/empresas-saludables/iconos/cardiologia.svg'
import lifeCoach from '../../multimedia/imagenes/empresas-saludables/iconos/life-coach.svg'
import toxicologia from '../../multimedia/imagenes/empresas-saludables/iconos/toxicologia.svg'
import dermatologia from '../../multimedia/imagenes/empresas-saludables/iconos/dermatologia.svg'
import ginecologia from '../../multimedia/imagenes/empresas-saludables/iconos/ginecologia.svg'
import fitness from '../../multimedia/imagenes/empresas-saludables/iconos/fitness.svg'
import yoga from '../../multimedia/imagenes/empresas-saludables/iconos/yoga.svg'
import calidadvida from '../../multimedia/imagenes/empresas-saludables/calidad-vida.svg'
import imagenYoga from '../../multimedia/imagenes/empresas-saludables/yoga.jpg'
import imagenRcp from '../../multimedia/imagenes/empresas-saludables/rcp.jpg'
import imagenCharlas from '../../multimedia/imagenes/empresas-saludables/charlas.jpg'
import imagenNutricion from '../../multimedia/imagenes/empresas-saludables/nutricion.jpg'

import DADIC from '../../multimedia/imagenes/fundacion/staff-academico/Dadic.png'
import PIETRANTONIO from '../../multimedia/imagenes/fundacion/staff-academico/Pietrantonio.png'
import FARINELLI from '../../multimedia/imagenes/fundacion/staff-ejecutivo/Farinelli.png'
import CRESCENTI from '../../multimedia/imagenes/fundacion/staff-academico/Crescenti.png'
import PASTOR from '../../multimedia/imagenes/fundacion/staff-academico/Pastor.png'
import ALET from '../../multimedia/imagenes/fundacion/staff-academico/Alet.jpg'

import transicionEscalera from '../../Transiciones/transicionEscalera'
import transicionBottomTop from '../../Transiciones/transicionBottomTop'

const WellnessCorporativo = () => {
    const isMountedRef = useRef(false);
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    },[])

    const staff = [
        {
            nombre: 'Dr. Alberto Felix Crescenti',
            imagen: CRESCENTI,
            especialidad: ''
        },
        {
            nombre: 'Dr. Francisco Dadic',
            imagen: DADIC,
            especialidad: 'Especialista en Toxicología Médica'
        },
        {
            nombre: 'Dr. Carlos A. Di Pietrantonio',
            imagen: PIETRANTONIO,
            especialidad: 'Médico epidemiólogo'
        },
        {
            nombre: 'Dr. Raúl F. Pastor',
            imagen: PASTOR,
            especialidad: 'Médico especialista en Calidad de Vida'
        },
        {
            nombre: 'Lic. Aldana Farinelli',
            imagen: FARINELLI,
            especialidad: 'Especialista en Marketing médico'
        },
        {
            nombre: 'Dr. Matias Alet',
            imagen: ALET,
            especialidad: 'Director de Neurología'
        }
    ];

    return (
        <>
            <main className='empresas-saludables-container container-fluid d-flex flex-column align-items-center px-0 position-relative' id='main-element'>
                <SideNav />
                <div className="empresas-saludables-intro vh-100 container-fluid px-0 position-relative">
                    <div className="empresas-saludables-main-image w-100 h-100"></div>
                    <div className="overlay d-flex flex-column justify-content-center">
                        <div className="container-xxl ps-sm-5">
                            <h1 className='poppins text-light text-shadow fs-7 fw-semibold'>Programa de Wellness Corporativo</h1>
                            <p className='poppins text-light fw-light fs-4 mt-4 mb-5'>En el mundo laboral actual, la salud y el bienestar de los empleados son fundamentales para el éxito y la productividad de una empresa.</p>
                            <button className='py-3 position-relative text-white text-shadow d-flex align-items-center justify-content-center gap-1'>
                                <Link to="/servicios/contacto/">
                                    CONTACTANOS
                                    <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M9 6l6 6l-6 6" />
                                    </svg>
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='empresas-saludables-content container section-md d-flex flex-column align-items-center py-5'>
                    <div className="d-flex w-100 flex-wrap justify-content-between calidad-vida">
                        <div className='col-md-7 col-sm-12 d-flex justify-content-center'>
                            <div className="d-flex gap-3 overflow-hidden">
                                <div className="col d-flex flex-column gap-3 mt-5 pt-md-5 pt-sm-0">
                                    <div className='overflow-hidden rounded-4'>
                                        <img src={imagenYoga} className='rounded-4' alt="Yoga" />
                                    </div>
                                    <div className="overflow-hidden rounded-4">
                                        <img src={imagenRcp} className='rounded-4' alt="Rcp" />
                                    </div>
                                </div>
                                <div className="col d-flex flex-column gap-3">
                                    <div className="overflow-hidden rounded-4">
                                        <img src={imagenCharlas} className='rounded-4' alt="Charlas" />
                                    </div>
                                    <div className="position-relative">
                                        <div className="overflow-hidden rounded-4">
                                            <img src={imagenNutricion} className='rounded-4 z-10 position-relative' alt="Nutricion" />
                                        </div>
                                        <div className="dot-pattern z-5"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12 py-sm-5 content rounded-4">
                            <div className='px-4 d-flex flex-column justify-content-center gap-md-5 gap-sm-4 gap-xs-3 h-100 position-relative z-10'>
                                <p className='fw-regular fs-4 my-0 rounded-4 position-relative ps-md-4 ps-lg-5'>En Fisp creamos contenidos, experiencias y programas de bienestar para tus trabajadores.</p>
                                <p className='fw-regular fs-4 my-0 rounded-4 position-relative ps-md-4 ps-lg-5'>Mantener a tus equipos motivados, productivos y saludables puede parecer difícil; sin embargo, con la correcta estrategia de bienestar corporativo, no tiene por qué resultar así.</p>
                                <p className='fw-regular fs-4 my-0 rounded-4 position-relative ps-md-4 ps-lg-5'>Creemos firmemente que el éxito de una empresa está estrechamente ligada con la salud de las personas que la integran‌</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="empresas-saludables-services d-flex gap-md-5 gap-sm-1 flex-wrap justify-content-center py-5">
                    <h3 className='py-lg-5 px-4 pb-4 py-md-0 w-100 fw-semibold text-center'>Nos especializamos en las siguientes areas:</h3>
                    <Cubo iconImage={kinesiologia} iconDescription="Kinesiología" />
                    <Cubo iconImage={estresLaboral} iconDescription="Psicólogo especialista en estrés laboral" />
                    <Cubo iconImage={primerosAuxilios} iconDescription="Capacitaciones de RCP y primeros auxilios" />
                    <Cubo iconImage={medicina} iconDescription="Medicina de calidad de vida" />
                    <Cubo iconImage={cardiologia} iconDescription="Cardiología" />
                    <Cubo iconImage={nutricionista} iconDescription="Nutricionista" />
                    <Cubo iconImage={toxicologia} iconDescription="Toxicología" />
                    <Cubo iconImage={lifeCoach} iconDescription="Life Coach y organizacional" />
                    <Cubo iconImage={dermatologia} iconDescription="Dermatología" />
                    <Cubo iconImage={ginecologia} iconDescription="Ginecología" />
                    <Cubo iconImage={fitness} iconDescription="Entrenador de Fitness" />
                    <Cubo iconImage={yoga} iconDescription="Yoga" />
                </div>
                <div className="container-xxl charlas-wellness">
                    <div className='mh-500 d-flex flex-column align-items-center pb-5'>
                        <h1 className='poppins text-center fs-7 pb-4'>Charlas corporativas</h1>
                        <p className='poppins text-center fs-5 col-11 col-sm-9'>¿Buscás nuevas formas de motivar a tus empleados? Potenciá su desarrollo profesional, fortalece el conocimiento y la conciencia sobre la importancia de la salud.</p>
                        <p className='poppins text-center fs-5 col-11 col-sm-9'>Podrás sumar a tu organización charlas de la mano de destacados profesionales en diversos campos de la salud pública comprometidos en compartir su amplia experiencia y conocimientos con su empresa.</p>
                        <p className='poppins text-center fs-5 col-11 col-sm-9'>Nuestras charlas ofrecerán ideas prácticas y aplicables a su entorno empresarial. Desde la importancia de la prevención y el bienestar en el lugar de trabajo hasta el impacto positivo de las políticas sanitarias en la productividad.</p>
                        <p className='poppins text-center fs-5 col-11 col-sm-9'>Además, nuestras charlas se adaptan a las necesidades específicas de su organización: Desde una sesión breve como parte de un evento o una serie de conferencias más completa.</p>
                    </div>
                    <div className='container-xxl charlas-corporativas-staff d-flex gap-5 flex-wrap justify-content-center pb-5 mb-5'>
                        <h3 className='poppins w-100 text-center'>Nuestro equipo</h3>
                        {
                            staff.map((miembro, index) => (
                                <div key={index} className="charlas-corporativas-member col-lg-3 col-md-5 col-9 border position-relative">
                                    <img loading='lazy' src={miembro.imagen} className='object-fit-cover w-100 h-100 position-absolute top-0 start-0' alt={miembro.nombre} />
                                    <div className="overlay">
                                        <h4 className='text-light fw-bold'>{miembro.nombre}</h4>
                                        {miembro.especialidad && <p className='text-white text-shadow fw-medium px-3 fs-6 pt-2'>{miembro.especialidad}</p>}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="w-100 d-flex justify-content-center mb-5">
                        <button className='py-3 position-relative text-white text-shadow d-flex align-items-center justify-content-center mb-5'>
                            <Link className='d-flex justify-content-center' to="/servicios/contacto/">
                                CONTACTANOS
                                <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </Link>
                        </button>
                    </div>
                </div>
            </main>
            <RegularNavbar />
        </>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(WellnessCorporativo) : transicionBottomTop(WellnessCorporativo);
