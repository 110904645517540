import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const ContadorFundacion = ({ end }) => {
    const [value, setValue] = useState(0);
    const ref = useRef(0);

    // Calculate the increment for the counter to achieve the desired duration (3 seconds)
    const increment = (end / 3000) * 70; // 3000 milliseconds for 3 seconds

    const Count = () => {
        if (ref.current < end) {
            const result = ref.current + increment;
            if (result > end) return setValue(end);
            setValue(result);
            ref.current = result;
        }
        setTimeout(Count, 70);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            gsap.registerPlugin(ScrollTrigger);
            const triggerElement = document.getElementById('fisp-data');
            if (triggerElement) {
                gsap.to(triggerElement, {
                    scrollTrigger: {
                        trigger: triggerElement,
                        start: 'top 80%',
                        onEnter: () => {
                            Count()
                        }
                    }
                });
            }
        }
        return () => (isMounted = false);
    }, [end, increment]);

    return (
        <span className='fs-big-title poppins pe-sm-3 pe-1 counter'>+{Math.ceil(value)}</span>
    );
}

export default ContadorFundacion;
