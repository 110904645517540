import React from 'react'

const TemarioToxicologia = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Introducción. Definiciones. Toxicocinética y toxicodinamia. Áreas de la  toxicología. Estadísticas en toxicología  </span>
            <br /><br />
            <ul>
                <li className='fw-light'>Historia y orígenes de la toxicología en América y el mundo.</li>
                <li className='fw-light'>Definiciones</li>
                <li className='fw-light'>Áreas de la toxicología</li>
                <li className='fw-light'>Toxicocinética y toxicodinamia</li>
                <li className='fw-light'>Estadísticas en América y el mundo</li>
            </ul>
            <br /><br />
            <span>Abordaje de un paciente intoxicado.</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Abordaje del paciente intoxicado - signos y síntomas relacionados</li>
                <li className='fw-light'>Síndromes tóxicos</li>
                <li className='fw-light'>Diagnóstico y tratamiento de un paciente intoxicado</li>
                <li className='fw-light'>Métodos de descontaminación</li>
                <li className='fw-light'>Antídotos</li>
                <li className='fw-light'>Casos clínicos frecuentes</li>
            </ul>
            <br /><br />
            <span>Intoxicaciones medicamentosas</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Intoxicaciones medicamentosas frecuentes</li>
                <li className='fw-light'>Sobreingesta accidental e intentos autolieticos</li>
                <li className='fw-light'>Aines. Paracetamol. Aspirina</li>
                <li className='fw-light'>Antidepresivos</li>
                <li className='fw-light'>Benzodiacepinas</li>
                <li className='fw-light'>Neurolépticos</li>
                <li className='fw-light'>Anticonvulsivante</li>
                <li className='fw-light'>Otros (hierro – litio – ergotamina – antidiabéticos -betabloqueantes, y otros)</li>
                <li className='fw-light'>Interacciones medicamentosas más frecuentes</li>
                <li className='fw-light'>Casos clínicos</li>
            </ul>
            <br /><br />
            <span>Gases tóxicos</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Gases tóxicos, generalidades</li>
                <li className='fw-light'>Monóxido de carbono</li>
                <li className='fw-light'>Cianuro y sulfuro de hidrógeno</li>
                <li className='fw-light'>Otros gases tóxicos</li>
                <li className='fw-light'>Otros gases tóxicos - Gases lacrimógenos</li>
                <li className='fw-light'>Metahemoglobinemia</li>
                <li className='fw-light'>Casos clínicos</li>
            </ul>
            <br /><br />
            <span>Armas químicas</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Armas de guerra</li>
                <li className='fw-light'>Casos clínicos</li>
            </ul>
            <br /><br />
            <span>Radiación</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Radiación</li>
                <li className='fw-light'>Síndrome agudo por radiación</li>
                <li className='fw-light'>Síndrome radioinducido</li>
                <li className='fw-light'>Casos clínicos</li>
            </ul>
            <br /><br />
            <span>Intoxicación por cáusticos</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Ácidos y alcalinos</li>
                <li className='fw-light'>Casos clínicos</li>
            </ul>
            <br /><br />
            <span>Intoxicación por hidrocarburos</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Intoxicación por hidrocarburos</li>
                <li className='fw-light'>Casos clínicos</li>
            </ul>
            <br /><br />
            <span>Productos de uso doméstico.</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Intoxicación por productos de uso doméstico</li>
                <li className='fw-light'>Casos clínicos</li>
                <li className='fw-light'>Conocer las sustancias NO tóxicas, causantes de consultas por ingestas en el hogar</li>
            </ul>
            <br /><br />
            <span>Intoxicación por metales </span>
            <br /><br />
            <ul>
                <li className='fw-light'>Intoxicación por metales pesados</li>
                <li className='fw-light'>Plomo – mercurio – arsénico</li>
                <li className='fw-light'>Intoxicación por otros metales pesados</li>
                <li className='fw-light'>Detección temprana y diagnóstico</li>
                <li className='fw-light'>Casos clínicos</li>
            </ul>
            <br /><br />
            <span>Intoxicación por plaguicidas</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Estadísticas</li>
                <li className='fw-light'>Marco legal</li>
                <li className='fw-light'>Plaguicidas más frecuentes</li>
                <li className='fw-light'>Intoxicación por plaguicidas 2</li>
                <li className='fw-light'>Otros plaguicidas</li>
                <li className='fw-light'>Raticidas</li>
                <li className='fw-light'>Casos clínicos</li>
            </ul>
            <br /><br />
            <span>Animales venenosos de Argentina y América </span>
            <br /><br />
            <ul>
                <li className='fw-light'>Distribución</li>
                <li className='fw-light'>Estadísticas</li>
                <li className='fw-light'>Serpientes, arañas y escorpiones</li>
                <li className='fw-light'>Animales venenosos acuáticos y terrestres</li>
                <li className='fw-light'>Casos clínicos</li>
            </ul>
            <br /><br />
            <span>Plantas venenosas </span>
            <br /><br />
            <ul>
                <li className='fw-light'>Aprender sobre las distintas plantas alucinógenas y aquellas usadas como drogas de abuso</li>
                <li className='fw-light'>Su mecanismo de acción, sintomatología de intoxicación y tratamiento</li>
                <li className='fw-light'>Plantas venenosas</li>
                <li className='fw-light'>Casos clínicos</li>
            </ul>
            <br /><br />
            <span>Intoxicaciones alimentarias </span>
            <br /><br />
            <ul>
                <li className='fw-light'>Intoxicaciones alimentarias más frecuentes</li>
                <li className='fw-light'>Estadísticas en pediatría y adultos</li>
                <li className='fw-light'>SUH</li>
                <li className='fw-light'>Salmonelosis</li>
                <li className='fw-light'>Shigelosis, otras</li>
                <li className='fw-light'>Botulismo del adulto y del lactante</li>
                <li className='fw-light'>Marea roja y otras costeras</li>
                <li className='fw-light'>Hongos tóxicos</li>
                <li className='fw-light'>Casos clínicos</li>
            </ul>
            <br /><br />
            <span>Intoxicación por drogas de abuso</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Historia del consumo de sustancias de abuso</li>
                <li className='fw-light'>Estadísticas y tendencias actuales con respecto a su utilización</li>
                <li className='fw-light'>Mecanismo neurobiológico de adicción</li>
                <li className='fw-light'>Consumo problemático. Diferencias entre abuso – dependencia adicción</li>
                <li className='fw-light'>Características, la toxicocinética, mecanismo de acción, forma de ingreso y administración, sintomatología de intoxicación, diagnóstico y tratamiento de todas ellas</li>
                <li className='fw-light'>Características, la toxicocinética, mecanismo de acción, forma de ingreso y administración, sintomatología de intoxicación, diagnóstico y tratamiento de la intoxicación por cocaína</li>
                <li className='fw-light'>Características, la toxicocinética, mecanismo de acción, forma de ingreso y administración, sintomatología de intoxicación, diagnóstico y tratamiento de la intoxicación por sustancias opioides</li>
                <li className='fw-light'>Características, la toxicocinética, mecanismo de acción, forma de ingreso y administración, sintomatología de intoxicación, diagnóstico y tratamiento de la intoxicación por marihuana</li>
                <li className='fw-light'>Características, la toxicocinética, mecanismo de acción, forma de ingreso y administración, sintomatología de intoxicación, diagnóstico y tratamiento de la intoxicación por MDMA, éxtasis y derivados</li>
                <li className='fw-light'>Drogas de abuso, así como sus mezclas y modalidades de consumo</li>
                <li className='fw-light'>Abordaje inicial de un paciente bajo los efectos de una sustancia de abuso</li>
                <li className='fw-light'>Alucinógenos, drogas estimulantes, y depresoras</li>
            </ul>
        </div>
    )
}

export default TemarioToxicologia