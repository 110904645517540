import React from 'react'

const TemarioRCP = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Reconocimiento de la patología</span>
            <br /><br />
            <span>Manejo de la escena</span>
            <br /><br />
            <span className="fw-light">Aprenderás lo esencial para garantizar la seguridad tanto de la víctima como de quien brinda ayuda al realizar RCP.</span>
            <ul>
                <li className='fw-light'>Seguridad personal</li>
                <li className='fw-light'>Víctima</li>
                <li className='fw-light'>Emergencias</li>
            </ul>
            <br />
            <span>Cadena de la vida</span>
            <br /><br />
            <span className="fw-light">Eslabones esenciales que deben seguirse en una secuencia lógica durante una emergencia médica, como un paro cardiorrespiratorio, para aumentar las posibilidades de supervivencia de la víctima.</span>
            <br /><br />
            <span className="fw-light">Cómo llevar a cabo una correcta técnica de RCP</span>
            <br />
            <ul>
                <li className="fw-light">Posición de seguridad </li>
                <li className="fw-light">Compresiones torácicas</li>
                <li className="fw-light">Diferencia en la RCP para niños y lactantes</li>
            </ul>
            <br />
            <span>Uso de DEA en ambientes cardioprotegidos</span>
            <br /><br />
            <span className="fw-light">Cómo llevar a cabo una correcta técnica de RCP</span>
            <br />
            <ul>
                <li className="fw-light">Introducción al DEA</li>
                <li className="fw-light">Cómo utilizar un DEA en una emergencia</li>
            </ul>
            
        </div>
    )
}

export default TemarioRCP