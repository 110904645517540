import React, { useEffect, useRef, useState } from 'react';
import ImagenMain from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/email-wpp.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar';
import SideNav from '../../Navbars/SideNav';
import { Link } from 'react-router-dom';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';
import IMG_Recopilacion from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/recopilacion.png'
import IMG_Segmentacion from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/segmentacion.png'
import IMG_CreacionContenido from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/creacion-contenido.png'
import IMG_DisenoCorreo from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/diseño-correo.avif'
import IMG_LineaAsunto from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/clickbait.jpg'
import IMG_EnvioCorreo from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/envio.jpg'
import IMG_Automatizacion from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/automatizacion.jpg'
import IMG_Personalizacion from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/personalizacion.jpg'
import IMG_CTA from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/cta.jpeg'
import IMG_Medicion from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/medicion.jpg'
import IMG_Ajustes from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/ajustes.png'
import IMG_CumplimientoNormativo from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/normativo.png'

const EmailWhatsappMarketing = () => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    }, []);

    return (
        <>
            <main className='pym-detalle' id="main-element">
                <SideNav />
                <div className="servicio-main-image container-fluid px-0">
                    <img className='w-100' src={ImagenMain} alt="E-Mail y WhatsApp Marketing" />
                </div>
                <div className="container-servicio-content pt-0 pb-0 my-5">
                    <div className="container-xxl pb-5">
                        <div>
                            <h1 className='poppins'>E-Mail y WhatsApp Marketing</h1>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 mt-5'>El Email Marketing es una estrategia de marketing digital que implica el uso del correo electrónico para comunicarse con una audiencia específica con el objetivo de promocionar productos, servicios, contenido o eventos, así como para mantener y nutrir las relaciones con los clientes. Aquí te explico cómo funciona el Email Marketing en general:</p>
                        <div className="d-flex gap-5 justify-content-center flex-wrap">
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_Recopilacion} alt="Recopilación de Suscriptores" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Recopilación de Suscriptores:</h3>
                                    <p className='parrafo-servicio'>Lo primero es crear una lista de suscriptores interesados en recibir correos electrónicos de tu parte. Puedes recopilar direcciones de correo electrónico a través de formularios en tu sitio web, landing pages, eventos, redes sociales, entre otros.</p>
                                </div>
                            </div>

                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_Segmentacion} alt="Segmentación de la Lista" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Segmentación de la Lista:</h3>
                                    <p className='parrafo-servicio'>Es importante dividir tu lista de suscriptores en segmentos según criterios relevantes como intereses, ubicación, historial de compras u otros datos demográficos. Esto te permite enviar correos electrónicos más relevantes y personalizados.</p>
                                </div>
                            </div>

                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_CreacionContenido} alt="Creación de Contenido" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Creación de Contenido:</h3>
                                    <p className='parrafo-servicio'>Diseña contenido de calidad para tus correos electrónicos. Esto puede incluir ofertas especiales, actualizaciones de productos, noticias relevantes, contenido educativo, historias de éxito, entre otros.</p>
                                </div>
                            </div>

                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_DisenoCorreo} alt="Diseño del Correo Electrónico" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Diseño del Correo Electrónico:</h3>
                                    <p className='parrafo-servicio'>Crea un diseño atractivo y profesional para tus correos electrónicos. Utiliza un diseño responsivo que se adapte a diferentes dispositivos y asegúrate de que el contenido sea fácil de leer y comprender.</p>
                                </div>
                            </div>

                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_LineaAsunto} alt="Línea de Asunto Atractiva" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Línea de Asunto Atractiva:</h3>
                                    <p className='parrafo-servicio'>La línea de asunto es crucial para que los destinatarios abran tus correos electrónicos. Debe ser atractiva, clara y relacionada con el contenido del correo electrónico.</p>
                                </div>
                            </div>

                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_EnvioCorreo} alt="Envío de Correos Electrónicos" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Envío de Correos Electrónicos:</h3>
                                    <p className='parrafo-servicio'>Utiliza una plataforma de Email Marketing para enviar tus correos electrónicos de manera eficiente. Asegúrate de programar los envíos en momentos que sean más propicios para la audiencia.</p>
                                </div>
                            </div>

                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_Automatizacion} alt="Automatización" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Automatización:</h3>
                                    <p className='parrafo-servicio'>Puedes configurar secuencias automatizadas de correos electrónicos basados en el comportamiento del suscriptor, como bienvenidas, seguimientos de carritos abandonados, agradecimientos después de la compra, etc.</p>
                                </div>
                            </div>

                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_Personalizacion} alt="Personalización" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Personalización:</h3>
                                    <p className='parrafo-servicio'>Utiliza el nombre del destinatario y otros datos personalizados en tus correos electrónicos para hacer que la comunicación sea más cercana y relevante.</p>
                                </div>
                            </div>

                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_CTA} alt="Llamada a la Acción (CTA)" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Llamada a la Acción (CTA):</h3>
                                    <p className='parrafo-servicio'>Cada correo electrónico debe tener una llamada a la acción clara y específica, ya sea para realizar una compra, descargar un recurso, registrarse para un evento, etc.</p>
                                </div>
                            </div>

                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_Medicion} alt="Medición y Análisis" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Medición y Análisis:</h3>
                                    <p className='parrafo-servicio'>Utiliza las métricas proporcionadas por tu plataforma de Email Marketing para evaluar el rendimiento de tus campañas. Puedes medir tasas de apertura, clics, conversiones y más.</p>
                                </div>
                            </div>

                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_Ajustes} alt="Ajustes y Mejoras" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Ajustes y Mejoras:</h3>
                                    <p className='parrafo-servicio'>Basándote en los resultados, ajusta tus estrategias. Prueba diferentes líneas de asunto, horarios de envío, contenido y enfoques para mejorar continuamente tus campañas.</p>
                                </div>
                            </div>

                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_CumplimientoNormativo} alt="Cumplimiento Normativo" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Cumplimiento Normativo:</h3>
                                    <p className='parrafo-servicio'>Asegúrate de cumplir con las leyes de privacidad y regulaciones de Email Marketing, como el cumplimiento con la Ley CAN-SPAM (en Estados Unidos) o el Reglamento General de Protección de Datos (RGPD) en Europa.</p>
                                </div>
                            </div>
                        </div>
                        <button className='py-3 mt-5 text-white text-shadow d-flex align-items-center justify-content-center rounded-4'>
                            CONTACTANOS
                            <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M9 6l6 6l-6 6" />
                            </svg>
                        </button>
                        <div className="py-5"></div>
                    </div>
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default transicionBottomTop(EmailWhatsappMarketing);