import React from 'react'

const TemarioMicrobiota = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <b>Módulo 1. Microbiota. Microbioma. Metagenómica </b>
            <br /><br />
        <span className='fw-light'>Definición de cada concepto. </span><br />
        <span className='fw-light'>Composición de la Microbiota: géneros, especies y cepas </span>
        <br /><br />
        <span className='fw-light'>Grupos de microorganismos que interaccionan con la especie humana: Bacterias, Hongos, Virus y Protozoos </span><br />
        <span className='fw-light'>Conceptos de: simbiosis, comensalismo, mutualismo, parasitismo </span>
        <br /><br />
        <span className='fw-light'>Diferentes Microbiotas humanas: Generalidades:</span>
        <br />
        <span className='fw-light'>- Microbiota Gastrointestinal </span><br />
        <span className='fw-light'>- Microbiota Oral </span><br />
        <span className='fw-light'>- Microbiota de la Piel </span><br />
        <span className='fw-light'>- Microbiota del Tracto Respiratorio </span><br />
        <span className='fw-light'>- Microbiota del Tracto Urinario </span><br />
        <span className='fw-light'>- Microbiota del Aparato Reproductor </span>
        <br /><br />
        <b>Modulo 2. Microbiología en Medicina.</b>
        <br /><br />

        <a className='p-0 bg-light text-primary' href="https://www.ugr.es/~eianez/Microbiologia/01historia.htm"><span className='fw-light'>Concepto y desarrollo de la Microbiología. Ubicación de los microorganismos en el mundo vivo.</span></a>
        <br /><br />
        <span className='fw-light'>Bacterias. Organismos Procariotas. Características generales. Metabolismo y fisiología. Genética bacteriana. Taxonomía. </span>
        <br /><br />
        <span className='fw-light'>Virus. Caracteres generales. Métodos de estudio. Composición química y estructura de los virus. Grupos de virus</span>
        <br /><br />
        <span className='fw-light'>Hongos. Caracteres generales.</span>
        <br /><br />
        <span className='fw-light'>Interacciones entre microorganismos.</span>
        <br /><br />
        <b>Módulo 3. Microbiota hoy</b>
        <br /><br />
        <span className='fw-light'>Dieta y estilo de vida</span>
        <br /><br />
        <span className='fw-light'>Eje Microbiota-Intestino-Cerebro</span>
        <br /><br />
        <span className='fw-light'>Factores que influyen en el estado de la Microbiota</span>
        <br /><br />
        <span className='fw-light'>Composición de la Microbiota Intestinal en las diferentes etapas de la vida </span>
        <br /><br />
        <span className='fw-light'>Envejecimiento y Microbiota </span><br />
        <span className='fw-light'>Actualizaciones en investigación científica</span><br />
        <span className='fw-light'>¿Qué es y para qué sirve el Trasplante fecal?</span>
        <br /><br />
        <b>Módulo 4. Aparato digestivo y Homeostasis intestinal</b>
        <br /><br />
        <span className='fw-light'>Fisiología del aparato digestivo. Composición de la microbiota en las diferentes partes del tubo digestivo:</span>
        <ol>
            <li><span className='fw-light'> Microbiota del intestino delgado en individuos sanos</span></li>
            <li><span className='fw-light'> Microbiota del colon en individuos sanos</span></li>
        </ol>
        <span className='fw-light'>Composición de la microbiota </span>
        <br /><br />
        <span className='fw-light'>Microbiota protectora (Lactobacillus, Bifidobacterium, Bacteroides) </span>
        <br /><br />
        <span className='fw-light'>Microbiota inmunomoduladora (Enterococcus faecalis y Escherichia coli) </span>
        <br /><br />
        <span className='fw-light'>Microbiota muconutritiva o mucoprotectora (Faecalibacterium prausnitzii y Akkermansia muciniphila) </span>
        <br /><br />
        <span className='fw-light'>Microbiota con actividades proteolítica o proinflamatoria (E. coli Biovare,Clostridium, Proteus, Pseudomonas, Enterobacter, Citrobacter, Klebsiella, Desulfovibrio, Bilophila) </span>
        <br /><br />
        <span className='fw-light'>Microbiota fúngica (Candida, Geotrichum)</span>
        <br /><br />
        <b>Módulo 5. Aparto digestivo y Homeostasis intestinal 2</b>
        <br /><br />
        <span className='fw-light'>Funciones de la microbiota intestinal: Metabólicas. Nutritivas y Tróficas. Protectoras y de barrera Inmunitarias. Interrelaciones entre la microbiota intestinal y órganos alejados (cerebro, pulmón, corazón, hígado, páncreas, etc.) </span>
        <br /><br />
        <span className='fw-light'>Mucosa intestinal y Sistema inmunitario de la mucosa </span>
        <br /><br />
        <span className='fw-light'>Anatomía, características y funciones (Sistema MALT, GALT Y BALT) </span>
        <br /><br />
        <span className='fw-light'>¿Qué es la Homeostasis intestinal? Papel de las bacterias sobre la homeostasis </span>
        <ol>
            <li><span className='fw-light'> Efectos sobre la digestión y la nutrición </span><br />
            <span className='fw-light'>2. Estimulación de las defensas, dificultar la colonización por microorganismos patógenos </span><br />
            <span className='fw-light'>3. Producción de vitaminas de los grupos B y K </span><br />
            <span className='fw-light'>4. Producción de ácidos grasos de cadena corta (butírico, propiónico, acético, etc.) </span><br />
            <span className='fw-light'>5. Producción de gases (metano, dióxido de carbono, hidrógeno molecular). Propiedades y funciones </span><br />
            <span className='fw-light'>6. El ácido láctico</span></li>
        </ol>
        <b>Módulo 6. Patologías y Microbiota intestinal. Disbiosis intestinal. SIBO</b>
        <br /><br />
        <span className='fw-light'>¿Qué es la Disbiosis intestinal?</span><br />
        <span className='fw-light'>La barrera intestinal. Fisiología. Función. Permeabilidad intestinal e hiperpermeabilidad intestinal. Relación entre Disbiosis intestinal e Hiperpermeabilidad intestinal </span><br />
        <span className='fw-light'>Relación de la Disbiosis intestinal y otros tipos de desórdenes: inmunológicos, metabólicos, neurológicos y gástricos (Helicobacter Pylori) </span>
        <br /><br />
        <span className='fw-light'>Trastornos Funcionales Digestivos </span>
        <ol>
            <li><span className='fw-light'> Enfermedad inflamatoria intestinal (EII) </span><br />
            <span className='fw-light'>2.  Enfermedades inflamatorias crónicas intestinales: enfermedad de Crohn. Colitis ulcerosa </span><br />
            <span className='fw-light'>3.  Síndrome de Intestino Irritable (SII). Marcadores de permeabilidad: Alfa 1 Antitripsina. Zonulina. Las Tight Juntions y su función principal. </span></li>
            <li><span className='fw-light'> Diveticulosis </span><br />
            <span className='fw-light'>5.  Diarrea. Diarrea provocada por Clostridium difficile.</span></li>
        </ol>
        <br /><br />
        <b>Módulo 7. Microbiota y sistema inmunitario </b>
        <br /><br />
        <span className='fw-light'>Fisiología del sistema inmunitario. Componentes del Sistema Inmunitario</span>
        <ol>
            <li><span className='fw-light'> Tejido Linfoide </span><br />
            <span className='fw-light'>2. Células Inmunitarias</span><br />
            <span className='fw-light'>3. Sistemas Químicos </span></li>
        </ol>
        <span className='fw-light'>Órganos que intervienen en la inmunidad</span>
        <ol>
            <li><span className='fw-light'> Órganos primarios </span><br />
            <span className='fw-light'>2. Órganos secundarios </span></li>
        </ol>
        <span className='fw-light'>Inmunidad innata, inespecífica o natural </span><br />
        <span className='fw-light'>- Inmunidad adquirida, adaptativa o específica </span>
        <br /><br />
        <span className='fw-light'>Relación bidireccional entre Microbiota y sistema Neuroinmunoendocrino </span><br />
        <span className='fw-light'>Microbiota, Inmunidad y Trastornos del Sistema Nervioso </span><br />
        <span className='fw-light'>investigación científica</span>
        <br /><br />
        <b>Módulo 8. Relación intolerancias/alergias y microbiota</b>
        <br /><br />
        <span className='fw-light'>Microbiota e Intolerancias a la lactosa </span><br />
        <span className='fw-light'>Microbiota e Intolerancias a las proteínas lácteas: caseínas, albúminas, etc. </span><br />
        <span className='fw-light'>Microbiota y alergias a la leche </span>
        <br /><br />
        <span className='fw-light'>Microbiota e Intolerancia al gluten </span>
        <br /><br />
        <span className='fw-light'>Microbiota y Enfermedad celiaca (EC)</span>
        <br /><br />
        <b>Módulo 9. Alimentos funcionales. Nutraceúticos.</b>
        <br /><br />
        <span className='fw-light'>Alimentos fermentados que impulsan una Microbiota saludable.</span>
        <br /><br />
        <span className='fw-light'>Probióticos</span>
        <br /><br />
        <span className='fw-light'>Prebióticos</span>
        <br /><br />
        <span className='fw-light'>Posbioticos</span>
        <br /><br />
        <span className='fw-light'>Sinbioticos</span>
        </div>
    )
}

export default TemarioMicrobiota