import React, { useEffect, useRef } from 'react';
import RCP_1 from '../../multimedia/imagenes/servicios-medicos/RCP/RCP_1.jpg'
import RCP_2 from '../../multimedia/imagenes/servicios-medicos/RCP/RCP_2.jpg'
import RCP_3 from '../../multimedia/imagenes/servicios-medicos/RCP/RCP_3.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar';
import SideNav from '../../Navbars/SideNav';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';
import { Link } from 'react-router-dom';

const RcpVirtual = () => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        const progressBar = document.getElementById('progress-bar');
        const updateProgressBar = () => {
            const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
            const progress = (window.scrollY / totalHeight) * 100;
            progressBar.style.width = progress + "%";
        };
        window.addEventListener("scroll", updateProgressBar);
        return () => {
            window.removeEventListener("scroll", updateProgressBar);
        };
    }, []);
    return (
        <>
            <main className='servicio-medico-detalle' id="main-element">
                <SideNav />
                <div className="servicio-main-image container-fluid px-0">
                    <img className='w-100 object-fit-cover' src={RCP_1} alt="RCP Virtual" />
                </div>
                <div className="container-servicio-content pt-0 pb-5 my-sm-5 my-0 px-2 px-sm-5">
                    <div className="container-xxl">
                        <div className="sticky-title" id='sticky-title'>
                            <h1 className='poppins'>Capacitación de RCP con realidad virtual</h1>
                            <div id="progress-bar"></div>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 pt-5'>La capacitación en RCP (Reanimación Cardiopulmonar) con lentes de realidad virtual (RV) emerge como una valiosa herramienta para el aprendizaje y desarrollo de habilidades en esta importante técnica de primeros auxilios. En este texto, exploraremos las ventajas de utilizar la RV en este contexto.</p>
                        <p className='parrafo-servicio fs-5 pb-2'>La RCP es un procedimiento crucial para salvar vidas en situaciones de emergencia mientras se espera la asistencia médica. La realización correcta de la RCP puede marcar la diferencia entre la vida y la muerte de una persona. Sin embargo, aprender y entrenar adecuadamente en esta técnica fundamental podría ser desafiante y costoso.</p>
                        <p className='parrafo-servicio fs-5 pb-2'>Aquí es donde la tecnología de la realidad virtual entra en juego. Al utilizar lentes de RV diseñados específicamente para capacitar en RCP, los profesionales de la salud y los socorristas pueden beneficiarse de una serie de ventajas significativas:</p>
                        <div className='d-flex bi-image-section gap-5 my-5'>
                            <div className='col col-lg-6 col-xl bg-dark'>
                                <img className='object-fit-cover w-100 h-100' src={RCP_2} alt="RCP Virtual" />
                            </div>
                            <div className='col col-lg-6 col-xl bg-dark'>
                                <img className='object-fit-cover w-100 h-100' src={RCP_3} alt="RCP Virtual" />
                            </div>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2'><span>Realismo inmersivo:</span> La RV proporciona una experiencia de aprendizaje altamente inmersiva, permitiendo a los participantes sumergirse en un entorno virtual realista. Esto les brinda la oportunidad de practicar habilidades de RCP en un entorno simulado que se asemeja lo más posible a una situación de emergencia real. Al mejorar el realismo, los participantes adquieren una mayor sensación de urgencia y se preparan mejor para responder bajo presión.</p>
                        <p className='parrafo-servicio fs-5 pb-2'><span>Repetición y práctica ilimitadas:</span> Con los lentes de RV, los usuarios pueden repetir y practicar la RCP las veces que sean necesarias. Esto es particularmente valioso, ya que la repetición de las habilidades es esencial para adquirir destrezas efectivas en RCP. Los errores pueden ser identificados y corregidos de inmediato, lo que permite un aprendizaje más rápido y preciso.</p>
                        <p className='parrafo-servicio fs-5 pb-2'><span>Adaptabilidad y personalización:</span> La RV en la capacitación en RCP permite adaptar el entrenamiento a diferentes escenarios y dificultades. Los instructores pueden ajustar la dificultad de manera gradual, comenzando con situaciones más simples y avanzando hacia desafíos más complejos a medida que los participantes adquieren confianza y habilidades. Esto garantiza que el entrenamiento se ajuste al nivel de cada individuo, optimizando así la efectividad del aprendizaje.</p>
                        <p className='parrafo-servicio fs-5 pb-2'><span>Retroalimentación inmediata y precisa:</span> Los lentes de RV pueden proporcionar comentarios inmediatos y precisos sobre la calidad de las técnicas de RCP aplicadas. Al analizar el desempeño del usuario en tiempo real, se puede brindar retroalimentación específica y detallada sobre aspectos como la profundidad y frecuencia de las compresiones torácicas. Esto es especialmente beneficioso para mejorar la técnica y garantizar un correcto procedimiento de RCP en situaciones reales.</p>
                        <p className='parrafo-servicio fs-5 pb-4'><span>Reducción de costos y riesgos:</span> La capacitación en RCP con lentes de RV puede ser más económica y segura en comparación con los métodos tradicionales. No se requiere la contratación de instructores adicionales ni la adquisición de maniquíes especializados. Además, se reduce el riesgo de lesiones causadas por prácticas incorrectas o la falta de disponibilidad de casos de entrenamiento.</p>
                        <Link to='/servicios/contacto/'>
                            <button className='py-3 position-relative text-white text-shadow d-flex align-items-center justify-content-center rounded-4 mb-3'>
                                CONTACTANOS
                                <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </button>
                        </Link>
                    </div>
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default transicionBottomTop(RcpVirtual);