import React, { useEffect, useRef, useState } from 'react';
import Imagen from '../../multimedia/imagenes/servicios-tecnologicos/telemedicina.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar';
import SideNav from '../../Navbars/SideNav';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';
import { Link } from 'react-router-dom';
import IMG_Telemetria from '../../multimedia/imagenes/servicios-tecnologicos/telemedicina/dispositivos-telemetria.jpg';
import IMG_CentralOperativa from '../../multimedia/imagenes/servicios-tecnologicos/telemedicina/central_operativa.jpg';
import IMG_PlataformaComunicacion from '../../multimedia/imagenes/servicios-tecnologicos/telemedicina/plataforma_comunicacion.jpg';
import IMG_DeteccionEmergencias from '../../multimedia/imagenes/servicios-tecnologicos/telemedicina/deteccion_emergencias.jpg';
import IMG_EvaluacionTratamiento from '../../multimedia/imagenes/servicios-tecnologicos/telemedicina/evaluacion_tratamiento.jpg';
import IMG_ConexionCentral from '../../multimedia/imagenes/servicios-tecnologicos/telemedicina/conexion_central.jpg';
import IMG_RegistroSeguimiento from '../../multimedia/imagenes/servicios-tecnologicos/telemedicina/registro_seguimiento.jpg';

const ComponentesClave = [{
    componente: "Dispositivos de Telemetría",
    descripcion: "Se distribuyen dispositivos médicos avanzados a los hogares de los ciudadanos, que pueden medir diversos parámetros vitales, como la presión arterial, la frecuencia cardíaca, la temperatura corporal, la saturación de oxígeno y otros indicadores relevantes para la atención médica de urgencia. Estos dispositivos están equipados con sensores y conectividad de alta velocidad para transmitir datos en tiempo real.",
    imagen: IMG_Telemetria,
},{
    componente: "Central Operativa",
    descripcion: "La central operativa es el núcleo del sistema, donde se encuentran especialistas médicos disponibles las 24 horas del día, los 7 días de la semana. La central está equipada con estaciones de trabajo avanzadas que permiten la visualización de datos en tiempo real y la comunicación bidireccional con los pacientes.",
    imagen: IMG_CentralOperativa,
},{
    componente: "Plataforma de Comunicación",
    descripcion: "Se utiliza una plataforma de comunicación segura y encriptada para facilitar la interacción entre los pacientes y los especialistas médicos. Los pacientes pueden acceder a esta plataforma a través de una aplicación móvil o una página web dedicada.",
    imagen: IMG_PlataformaComunicacion,
},{
    componente: "Detección de Emergencias",
    descripcion: "Cuando un paciente experimenta una emergencia médica, utiliza el dispositivo de telemetría para medir sus signos vitales y otros datos relevantes. En caso de anomalías o situaciones críticas, el dispositivo activa una alarma y comienza a transmitir datos a la central operativa.",
    imagen: IMG_DeteccionEmergencias,
},{
    componente: "Conexión con la Central",
    descripcion: "Los datos se transmiten en tiempo real a la central operativa, donde los especialistas médicos pueden acceder a la información del paciente de manera inmediata. La plataforma de comunicación permite una interacción en tiempo real con el paciente para recopilar información adicional y brindar orientación.",
    imagen: IMG_ConexionCentral,
},{
    componente: "Evaluación y Tratamiento",
    descripcion: "Los especialistas médicos evalúan los datos proporcionados por el paciente y, en función de la gravedad de la situación, pueden proporcionar recomendaciones, coordinar una ambulancia o incluso iniciar tratamientos de emergencia a través de la comunicación en tiempo real.",
    imagen: IMG_EvaluacionTratamiento,
},{
    componente: "Registro y Seguimiento",
    descripcion: "Todos los datos, conversaciones y acciones se registran en un sistema de gestión de registros médicos para un seguimiento continuo y una atención de seguimiento adecuada.",
    imagen: IMG_RegistroSeguimiento,
}]

const Telemedicina = () => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        const progressBar = document.getElementById('progress-bar');
        const updateProgressBar = () => {
            const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
            const progress = (window.scrollY / totalHeight) * 100;
            progressBar.style.width = progress + "%";
        };
        window.addEventListener("scroll", updateProgressBar);
        return () => {
            window.removeEventListener("scroll", updateProgressBar);
        };
    }, []);

    return (
        <>
            <main className='servicio-tecnologico-detalle' id="main-element">
                <SideNav />
                <div className="servicio-main-image container-fluid px-0">
                    <img className='w-100' src={Imagen} alt="Sistemas de Telemedicina de Urgencias" />
                </div>
                <div className="container-servicio-content pt-0 pb-5 my-sm-5 mt-0 px-2 px-sm-5">
                    <div className="container-xxl">
                        <div className="sticky-title" id='sticky-title'>
                            <h1 className='poppins'>Sistemas de Telemedicina de Urgencias</h1>
                            <div id="progress-bar"></div>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 mt-5'><span>Visión General</span></p>
                        <p className='parrafo-servicio fs-5 pb-2'>El sistema de telemedicina de urgencias  es una plataforma tecnológica avanzada diseñada para ofrecer atención médica inmediata y especializada a los residentes de la ciudad en situaciones de emergencia. El sistema aprovecha la telemetría y la comunicación en tiempo real para conectar a los pacientes con especialistas médicos en una central operativa, lo que permite una evaluación y un tratamiento más rápidos y precisos.</p>
                        <p className='parrafo-servicio fs-5 pb-2 mt-5'><span>Componentes clave:</span></p>
                        <div className="d-flex flex-column gap-4 mb-5">
                            {
                                ComponentesClave.map((componente,index)=>{
                                    return(
                                        <div className="d-flex box-shadow border componente-clave" key={index}>
                                            <div className="bg-dark col-md-4 col-12">
                                                <img className='w-100 h-100 object-fit-cover ratio-2x1' src={componente.imagen} alt={componente.componente} />
                                            </div>
                                            <div className="col-md-8 col-12 ps-md-4 px-4">
                                                <p className='parrafo-servicio my-4 fs-5 d-flex flex-column gap-1 justify-content-center'>
                                                    <span className='pb-2 me-4 border-bottom'>{componente.componente}</span>
                                                    <p className='fw-normal pt-2 mb-0'>{componente.descripcion}</p>
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 text-center'><span>Ventajas del Sistema</span></p>
                        <div className='parrafo-servicio fs-5 p-3 px-sm-5 py-sm-4 mb-5 rounded-4 bg-light box-shadow-medium'>
                                <ul className='px-md-5 px-0 mx-md-5 mx-0 mb-0'>
                                    <li className='fw-medium px-2 py-2'>Respuesta rápida en situaciones de emergencia.</li>
                                    <li className='fw-medium px-2 py-2'>Reducción de tiempos de espera en hospitales y clínicas.</li>
                                    <li className='fw-medium px-2 py-2'>Mayor precisión en el diagnóstico y tratamiento.</li>
                                    <li className='fw-medium px-2 py-2'>Acceso a atención médica especializada las 24 horas del día.</li>
                                    <li className='fw-medium px-2 py-2'>Registro detallado de la atención brindada para seguimiento médico.</li>
                                </ul>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2'>El sistema de telemedicina de urgencias para la ciudad representa un avance significativo en la atención médica de emergencia al utilizar la tecnología para mejorar la respuesta y la calidad de la atención. Además, promueve la seguridad y el bienestar de los residentes al garantizar un acceso rápido y eficaz a la atención médica especializada en situaciones críticas</p>
                        <Link to='/servicios/contacto/'>
                            <button className='py-3 position-relative text-white text-shadow d-flex align-items-center justify-content-center mb-5 rounded-4'>
                                CONSULTA TU PRESUPUESTO
                                <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </button>
                        </Link>                        
                    </div>
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default transicionBottomTop(Telemedicina);