import { useParams } from 'react-router-dom';
import Nutricion from '../NutricionClinica';
import DermatologiaEsteticaTerapeutica from '../DermatologiaEsteticaTerapeutica';
import PaginaError from '../../../PaginaError';
import GestionAdministracionEnfermeria from '../GestionAdministracionEnfermeria';
import PsiquiatriaClinica from '../PsiquiatriaClinica';
import NutricionMicrobiota from '../NutricionMicrobiota';
import MedicinaPrecision from '../MedicinaPrecision';

const PosgradoRedirect = () => {
    const { curso } = useParams();
    let serviceComponent;
    switch (curso) {
        case 'nutricion-clinica':
            serviceComponent = <Nutricion />
            break;
        case 'dermatologia-estetica-terapeutica':
            serviceComponent = <DermatologiaEsteticaTerapeutica />
            break;
        case 'gestion-y-administracion-en-enfermeria':
            serviceComponent = <GestionAdministracionEnfermeria />
            break;
        case 'nutricion-y-microbiota':
            serviceComponent = <NutricionMicrobiota />
            break;
        case 'psiquiatria-clinica':
            serviceComponent = <PsiquiatriaClinica />
            break;
        case 'medicina-precision-y-genomica':
            serviceComponent = <MedicinaPrecision />
            break;
        default:
            serviceComponent = <PaginaError />
            break;
    }

    return serviceComponent
};

export default PosgradoRedirect;