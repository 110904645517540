import React, { useState } from 'react';
import Axios from "axios";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';

const KommoForm = () => {
    const navigate = useNavigate()
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [curso, setCurso] = useState('Diplomatura en nutrición y microbiota');
    const [url, setUrl] = useState('https://inscripciones.fisp.org.ar/finalizar-compra/?add-to-cart=366');
    const [formMessage,setFormMessage] = useState("")
    const [isError, setIsError] = useState()
    // const [errorMessage, setErrorMessage] = useState()

    const handleSubmitContact = async (event) => {
        event.preventDefault();
        crearContacto()
    };
    // const handleSubmitLead = async (event) => {
    //     event.preventDefault();
    //     console.log("Test")
    //     crearLead()
    //     // crearContacto()
    // };

    const crearContacto = ()=>{
        Axios.post('https://srv452353.hstgr.cloud/kommoCrearContacto', {
            nombre: name,
            telefono: phone,
            email: email,
            curso: curso,
        })
        .then((response) => {
            console.log("Contacto creado")
            console.log(response.data)
            console.log(response.data._embedded.contacts[0].id)
            crearLead(response.data._embedded.contacts[0].id)
        })
        .catch((error) => {
            resetForm()
            console.log("Error al crear contacto")
            setIsError(true)
            setFormMessage("Ha habido un error al crear el contacto. Si el error persiste, contactese directamente con nosotros.")
            console.error(error);
        })
        .finally(() => {
            console.log("Proceso finalizado")
        });
    }

    const crearLead = (contactoId)=>{
        Axios.post('https://srv452353.hstgr.cloud/kommoCrearLead', {
            nombre: name,
            telefono: phone,
            email: email,
            curso: curso,
            url: url,
            contactoId: contactoId
        })
        .then((response) => {
            console.log("Lead creado")
            setIsError(false)
            setFormMessage("Sus datos se han registrado con éxito. En unos instantes le llegará un mensaje a su telefono con información sobre precios y formas de inscripcion para '" + curso + "'")
            console.log(response.data)
            setTimeout(()=>{
                navigate(`/educativo`);
            },10000)
        })
        .catch((error) => {
            resetForm()
            console.log("Error al crear lead")
            setIsError(true)
            setFormMessage("Ha habido un error al registrar los datos, pruebe denuevo mas tarde. Si el error persiste, contactese directamente con nosotros.")
            console.error(error);
        })
        .finally(() => {
            console.log("Proceso finalizado")
        });
    }

    const resetForm = ()=>{
        setName('')
        setEmail('')
        setPhone('')
    }

    return (
        <>
            <div className='kommo-form box-shadow p-5 pb-3 rounded-2'>
                <h1 className='montserrat fs-3 pb-2'>CREAR CONTACTO</h1>
                <h2 className='uppercase' style={{color: 'blue',fontSize: '14px'}}>{curso}</h2>
                <form onSubmit={handleSubmitContact}>
                    <div className='d-flex flex-column py-2 kommo-input-container montserrat'>
                        <input
                            required
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder='Nombre y apellido'
                        />
                    </div>
                    <div className='d-flex flex-column py-2 kommo-input-container montserrat'>
                        <input
                            required
                            placeholder='Email'
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='d-flex flex-column py-2 kommo-input-container montserrat'>
                        <PhoneInput
                            required
                            placeholder="Número telefónico"
                            value={phone}
                            defaultCountry='AR'
                            onChange={setPhone}/>
                    </div>
                    <button type="submit">Enviar</button>
                </form>
                <div className='d-flex flex-column kommo-input-container border montserrat mt-3'>
                    {formMessage && (
                        <p className={`p-3 m-0 montserrat fade-in ${isError ? 'form-error' : 'form-success'}`} style={{fontSize: '14px',fontWeight: '500'}}>{formMessage}</p>
                    )}
                </div>
            </div>
            {/* <div className='kommo-form box-shadow p-5 rounded-2'>
                <h1 className='montserrat fs-3 pb-2'>CREAR LEAD</h1>
                <h2 className='uppercase' style={{color: 'blue',fontSize: '14px'}}>{curso}</h2>
                <form onSubmit={handleSubmitLead}>
                    <div className='d-flex flex-column py-2 kommo-input-container montserrat'>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder='Nombre y apellido'
                        />
                    </div>
                    <div className='d-flex flex-column py-2 kommo-input-container montserrat'>
                        <input
                            placeholder='Email'
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='d-flex flex-column py-2 kommo-input-container montserrat'>
                        <PhoneInput
                            placeholder="Número telefónico"
                            value={phone}
                            defaultCountry='AR'
                            onChange={setPhone}/>
                    </div>
                    <button type="submit">Enviar</button>
                    <div className='d-flex flex-column py-2 kommo-input-container montserrat'>
                        {errorMessage && (
                            <p>{JSON.stringify(errorMessage)}</p>
                        )}
                        {respuesta2 && (
                            <p>{JSON.stringify(respuesta2)}</p>
                        )}
                        {respuesta3 && (
                            <p>{JSON.stringify(respuesta3)}</p>
                        )}
                    </div>

                </form>
            </div> */}
        </>
    );
};

export default KommoForm;