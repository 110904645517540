import React from 'react'

const TemarioPediatricos = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <b>Objetivos específicos:</b>
            <ul>
                <li><span className='fw-light'>Reconocer los signos y síntomas de alarma de las situaciones que afecten la función respiratoria del niño, evaluando prioridades.</span></li>
                <li><span className='fw-light'>Educar al niño y su familia en la prevención de enfermedades respiratorias </span></li>
            </ul>
            <b>Contenido:</b>
            <ul>
                <li><span className='fw-light'>Características del sistema respiratorio del niño y lactante</span></li>
                <li><span className='fw-light'>Valoración del sistema respiratorio del niño y lactante</span></li>
                <li><span className='fw-light'>Educación prevención de las patologías respiratorias</span></li>
            </ul>
            <br />

            <b>Objetivos específicos: </b>

            <br />
            <ul>
                <li><span className='fw-light'>Reconocer los distintos grados de dificultad respiratoria en el niño, y los criterios de actuación para dicha situación clínica. </span></li>
            </ul>
            <br />

            <b>Contenido</b>

            <br />
            <ul>
                <li><span className='fw-light'>Insuficiencia respiratoria: mecanismo. </span></li>
                <li><span className='fw-light'>Criterios de gravedad, escalas de valoración</span></li>
                <li><span className='fw-light'>Patologías respiratorias más frecuentes en pediatría: </span></li>
            </ul>
            <span className='fw-light'>.- bronquiolitis, </span>
            <span className='fw-light'>- bronquitis, </span>
            <span className='fw-light'>- neumonía, </span>
            <span className='fw-light'>- asma.</span>
            <br /><br />
            <b>Objetivos específicos.</b>
            <ul>
                <li><span className='fw-light'>Reconocer los dispositivos adecuados a cada situación de dificultad respiratoria en el niño</span></li>
                <li><span className='fw-light'>Establecer prioridades de actuación para prevenir la claudicación respiratoria</span></li>
            </ul>
            <br />

            <b>Contenido</b>
            <ul>
                <li><span className='fw-light'>Proceso de atención de enfermería al niño con alteraciones de la función respiratoria: </span></li>
                <li><span className='fw-light'>manejo inicial de la dificultad respiratoria, </span></li>
                <li><span className='fw-light'>oxigenoterapia y medicación inhalada, </span></li>
                <li><span className='fw-light'>guías para el manejo de la dificultad respiratoria, </span></li>
                <li><span className='fw-light'>dispositivos para evitar la claudicación.</span></li>
            </ul>
            <br />
        </div>
    )
}

export default TemarioPediatricos