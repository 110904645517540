import React from 'react'
import Logo from './multimedia/imagenes/logo-iniciales-oscuro.png'
import { Link } from 'react-router-dom'
import fondo from './multimedia/imagenes/pagina-error/Fondo.jpg'
import transicionEscalera from './Transiciones/transicionEscalera'
import transicionBottomTop from './Transiciones/transicionBottomTop'

const PaginaError = () => {
    return (
        <main className='error-page-container'>
            <div className="container-fluid vh-100 d-flex align-items-center justify-content-center">
                <img className='w-100 h-100 position-absolute z-5' src={fondo} alt="" />
                <div className="container-xxl d-flex align-items-center justify-content-center flex-column position-relative z-10">
                    <img src={Logo} className='logo-pagina-error' alt="logo-pagina-error" />
                    <h1 className='poppins py-5'>La página que buscaste no existe</h1>
                    <Link to="/"><button className='p-4 rounded-5 poppins'>Volver al inicio</button></Link>
                </div>
            </div>
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(PaginaError) : transicionBottomTop(PaginaError);