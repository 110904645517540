import React, { useEffect, useRef } from 'react';
import ImagenMain from '../../multimedia/imagenes/produccion-marketing/publicidad-en-linea.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar';
import SideNav from '../../Navbars/SideNav';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';
import { Link } from 'react-router-dom';

const PublicidadEnLinea = () => {
    const isMountedRef = useRef(false);

    const iconoResultados = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
                <path d="M18 12v-5a2 2 0 0 0 -2 -2h-2" />
                <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                <path d="M8 11h4" />
                <path d="M8 15h3" />
                <path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                <path d="M18.5 19.5l2.5 2.5" />
            </svg>
            )
    }
    const iconoOptimizacion = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
                <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
            </svg>
        )
    }
    const iconoSegmentacion = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                <path d="M12 7a5 5 0 1 0 5 5" />
                <path d="M13 3.055a9 9 0 1 0 7.941 7.945" />
                <path d="M15 6v3h3l3 -3h-3v-3z" />
                <path d="M15 9l-3 3" />
            </svg>
        )
    }
    const iconoProgramatica = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
                <path d="M10 9v6l5 -3z" />
            </svg>
        )
    }
    const iconoRetargeting = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M20 11a8.1 8.1 0 0 0 -6.986 -6.918a8.095 8.095 0 0 0 -8.019 3.918" />
                <path d="M4 13a8.1 8.1 0 0 0 15 3" />
                <path d="M19 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                <path d="M5 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
            </svg>
        )
    }
    const items = [
        {
            titulo: "Segmentación de Audiencia",
            descripcion: "Los anunciantes pueden segmentar su audiencia según una variedad de criterios, como ubicación geográfica, edad, género, intereses, comportamientos de navegación y más. Esto garantiza que los anuncios se muestren a las personas más relevantes.",
            icono: iconoSegmentacion()
        },{
            titulo: "Optimización continua",
            descripcion: "Basándose en los datos recopilados, los anunciantes ajustan sus estrategias para mejorar el rendimiento de sus campañas. Pueden ajustar los presupuestos, probar diferentes creatividades, cambiar la segmentación, entre otros.",
            icono: iconoOptimizacion()
        },{
            titulo: "Resultados y Objetivos",
            descripcion: "La publicidad en línea puede tener varios objetivos, como generar ventas, leads, aumentar la visibilidad de la marca, etc. Los resultados se evalúan en función de estos objetivos.",
            icono: iconoResultados()
        },{
            titulo: "Publicidad Programática",
            descripcion: "Una tendencia en la publicidad en línea es la programática, que utiliza algoritmos y tecnología para automatizar el proceso de compra y entrega de anuncios, optimizando la relevancia y el alcance.",
            icono: iconoProgramatica()
        },{
            titulo: "Retargeting o Remarketing",
            descripcion: "Esta técnica muestra anuncios específicos a usuarios que han interactuado previamente con el sitio web o el contenido de la empresa, lo que aumenta la posibilidad de conversión.",
            icono: iconoRetargeting()
        }
        ]

    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        const progressBar = document.getElementById('progress-bar');
        const updateProgressBar = () => {
            const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
            const progress = (window.scrollY / totalHeight) * 100;
            progressBar.style.width = progress + "%";
        };
        window.addEventListener("scroll", updateProgressBar);
        return () => {
            window.removeEventListener("scroll", updateProgressBar);
        };
    }, []);

    return (
        <>
            <main className='pym-detalle' id="main-element">
                <SideNav />
                <div className="servicio-main-image container-fluid px-0">
                    <img className='w-100' src={ImagenMain} alt="Publicidad en linea"/>
                </div>
                <div className="container-servicio-content pt-0 pb-5 my-5 px-2 px-sm-5">
                    <div className="container-xxl">
                        <div className="sticky-title z-20" id='sticky-title'>
                            <h1 className='poppins'>Publicidad en línea</h1>
                            <div id="progress-bar"></div>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 mt-5'>La publicidad en línea, funciona a través de diversos canales y tecnologías que permiten a los anunciantes mostrar sus mensajes a usuarios potenciales. </p>
                        <p className='parrafo-servicio fs-5 pb-2'>Los anunciantes eligen las plataformas y canales en línea en los que desean mostrar su publicidad. Estos pueden incluir motores de búsqueda (Google Ads), redes sociales (Facebook, Instagram), sitios web, aplicaciones móviles y más.</p>
                        <div className="row justify-content-center gap-5 pb-5">
                            {items.map((item,index)=>{
                                return (
                                    <div className='row gap-2 gap-sm-4 gap-md-5 servicio' key={index} onClick={(e)=>{e.target.scrollIntoView({block:'center'})}}>
                                        <div className="col-8 py-1 position-relative rounded-4">
                                            <h2 className='fs-5 fw-semibold poppins dropdown-title'>{item.titulo}</h2>
                                            <div className='item-dropdown'>
                                                <p className='parrafo-servicio'>{item.descripcion}</p>
                                            </div>
                                        </div>
                                        <div className="col-3 py-1 d-flex align-items-center justify-content-center rounded-4">
                                            {item.icono}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <Link to='/servicios/contacto/'>
                            <button className='py-3 position-relative text-white text-shadow d-flex align-items-center justify-content-center mb-5 rounded-4'>
                                CONTACTANOS
                                <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </button>
                        </Link>
                    </div>
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default transicionBottomTop(PublicidadEnLinea);