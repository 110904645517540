import React from 'react'

const TemarioSkincare = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Módulo 1: introducción al cuidado de la piel</span>
            <br /><br />
            <ul>
                <span>Objetivo: comprender la importancia del Skincare y su impacto en la salud de la piel</span>
                <br /><br />
                <li className='fw-light'>Fundamentos de anatomía y fisiología de la piel</li>
                <li className='fw-light'>Identificación de tipos de piel y sus características</li>
                <li className='fw-light'>Introducción a ingredientes básicos en productos de Skincare</li>
            </ul>
            <br />
            <br />
            <span>Módulo 2: problemas comunes en la piel</span>
            <br /><br />
            <ul>
                <span>Objetivo: identificar y abordar problemas específicos de la piel</span>
                <br /><br />
                <li className='fw-light'>Acné: causas y tratamientos</li>
                <li className='fw-light'>Pigmentación y melasmas</li>
                <li className='fw-light'>Envejecimiento cutáneo y prevención</li>
            </ul>
            <br />
            <span>Módulo 3: ingredientes clave en Skincare</span>
            <br /><br />
            <ul>
                <span>Objetivo: conocer los ingredientes comunes y su impacto en la piel </span>
                <br /><br />
                <li className='fw-light'>Acidos (AH, glicolico, salicílico, etc)</li>
                <li className='fw-light'>Antioxidantes (vit C, E, etc)</li>
                <li className='fw-light'>Hidratantes y emolientes</li>
            </ul>
            <br />
            <span>Módulo 4: rutina de Skincare diaria</span>
            <br /><br />
            <ul>
                <span>Objetivo: establecer una rutina de cuidado de la piel personalizada</span>
                <br /><br />
                <li className='fw-light'>asos esenciales en una rutina diaria</li>
                <li className='fw-light'>Selección de productos según tipo de piel </li>
                <li className='fw-light'>Importancia del uso de protector solar y su aplicación adecuada</li>
            </ul>
            <br />
            <span>Módulo 5: Skincare avanzado</span>
            <br /><br />
            <ul>
                <span>Objetivo: explorar técnicas y productos avanzados para el cuidado de la piel</span>
                <br /><br />
                <li className='fw-light'>Exfoliación y su importancia</li>
                <li className='fw-light'>Mascaras faciales y tratamientos específicos </li>
                <li className='fw-light'>Dispositivos y herramientas de Skincare</li>
            </ul>
        </div>
    )
}
/*
            <br />
            <span></span>
            <br /><br />
            <ul>
                <span></span>
                <br /><br />
                <li className='fw-light'></li>
                <li className='fw-light'></li>
                <li className='fw-light'></li>
            </ul>
*/

export default TemarioSkincare