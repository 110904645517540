import React from 'react'
import logo_mp from '../../../multimedia/imagenes/educativo/pricing/mercado-pago.png'
import paypal from '../../../multimedia/imagenes/educativo/pricing/paypal.png'
import { Link } from 'react-router-dom';

const PricingCurso = (
    { 
        //Id y Nombre de Curso
        cursoId, nombreCurso,
        //Booleans
        incluyeMatricula,incluyeExtranjero, cuotasFraccionadas, nuevoFormato,
        //Matriculas (Precios)
        matriculaLocal, matriculaExtranjero,
        //Descuento
        incluyeDescuento,
        //Precios local (Cantidad y Precios)
        precioLocal,
        primerasCuotasLocal, precioPrimerasCuotasLocal,
        ultimasCuotasLocal, precioUltimasCuotasLocal,
        //Precios extranjero (Cantidad y Precios)
        cuotasExtranjero,precioCuotasExtranjero,
        // Cupos llenos
        cuposLlenos
    }) => {
        
        return (
            <div className='d-flex flex-column gap-3 mt-4'>
                {
                    cuposLlenos ? (
                        <>
                            <div className="mx-4 p-4 pricing-header mb-4">
                                <h3 className='pricing-course-title pt-4 poppins fs-4'>{nombreCurso}</h3>
                            </div>
                            <div className='pricing-extranjero p-5 mx-4 rounded-3 d-flex flex-column justify-content-center align-items-center'>
                                <p className=' m-0 fs-4 text-center d-flex flex-column'>CUPOS LLENOS. <span>Cerrada inscripciones 2024</span></p>
                            </div>
                            <Link className='mt-3 mx-4 rounded-4' style={{textDecoration:'none'}} to="/educativo">
                                <button className='w-100 d-flex gap-2 justify-content-center align-items-center fw-bold  btn btn-light text-dark py-4 box-shadow'>
                                    VOLVER 
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-back-up" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M9 14l-4 -4l4 -4" />
                                        <path d="M5 10h11a4 4 0 1 1 0 8h-1" />
                                    </svg>
                                </button>
                            </Link>
                        </>
                    ) : (
                            nuevoFormato ? (
                                <>
                                    <div className="p-4 pricing-header mb-4">
                                        <h3 className='pricing-course-title pt-4 poppins fs-4'>{nombreCurso}</h3>
                                        <h3 className="poppins fs-5">Nuestros aranceles</h3>
                                    </div>
                                    <div className="pricing-boxes d-flex gap-3">
                                        <div className="col pricing-box pricing-box-small pricing-local justify-content-center">
                                            <p className='fw-normal poppins w-100 text-center'>Para <span className='fw-semibold poppins'>Argentinos:</span></p>
                                            <div className="d-flex flex-column mt-4">
                                                <p className='m-0 poppins fw-semibold text-center text-balance fs-4'>Hasta {primerasCuotasLocal} cuotas sin interés de $ {precioPrimerasCuotasLocal}</p>
                                                <p className='m-0 poppins fw-semibold text-center text-balance mt-2 fw-' style={{fontSize: '12px'}}>Con todas las tarjetas</p>
                                                <img className='w-50 py-2 paypal-logo align-self-center' src={logo_mp} alt="Logo Mercado Pago" />
                                            </div>
                                        </div>
                                        <div className="col pricing-box pricing-box-small pricing-extranjero">
                                            <p className='fw-normal poppins w-100 text-center'>Para <span className='fw-semibold poppins'>Extranjeros:</span></p>
                                            <div className="d-flex flex-column mt-4">
                                                <p className='m-0 poppins fw-semibold text-center text-balance fs-4 mt-3'>{cuotasExtranjero} cuotas de $ {precioCuotasExtranjero} USD</p>
                                                <div className="d-flex flex-column pt-2 align-items-center">
                                                    <p className='m-0 poppins mt-4' style={{fontSize: '12px'}}>Via suscripción de </p>
                                                    <img className='w-50 py-2 paypal-logo' src={paypal} alt="Logo PayPal" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pricing-disclaimer px-4 py-3 my-3">
                                        <p className='pt-3 poppins'>Para completar el proceso será necesario el envío de la documentación requerida y la aceptación a la suscripción de las cuotas. </p>
                                        <div className="w-100 d-flex justify-content-center">
                                            <a className=' w-100' href={`https://inscripciones.fisp.org.ar/finalizar-compra/?add-to-cart=${cursoId}`}><button className='btn btn-dark w-100 py-3'>Continuar Inscripción</button></a>
                                        </div>
                                    </div>
                                </>
                            ) : (
                            <div>
                                <div className="mx-4 p-4 pricing-header mb-4">
                                    <h3 className='pricing-course-title pt-4 poppins fs-4'>{nombreCurso}</h3>
                                    <h3 className="poppins fs-5">Nuestros aranceles</h3>
                                </div>
                                {incluyeMatricula ? (
                                        <div>
                                            {incluyeExtranjero ? (
                                                <div>
                                                    {cuotasFraccionadas ? (
                                                        //Hay matricula y cuotas fracionadas
                                                        <div className="d-flex gap-5 justify-content-center pricing-boxes">
                                                            <div className="pricing-box pricing-local col-5">
                                                                <p className='fw-normal poppins'>Para <span className='fw-semibold poppins'>Argentinos:</span></p>
                                                                <div className="d-flex flex-column ">
                                                                    <p className='m-0 uppercase fs-3'>Matrícula</p>
                                                                    <p className='m-0 poppins uppercase fs-2'>$ {matriculaLocal}</p>
                                                                </div>
                                                                <div className="d-flex flex-column pt-3">
                                                                    <p className='m-0 poppins'>+ {primerasCuotasLocal} primeras cuotas de $ {precioPrimerasCuotasLocal}</p>
                                                                    <p className='m-0 poppins'>+ {ultimasCuotasLocal} últimas cuotas de $ {precioUltimasCuotasLocal}</p>
                                                                </div>
                                                                <div className="d-flex flex-column py-3">
                                                                    <p className='m-0 poppins'>Via suscripción de </p>
                                                                    <img className='w-75 py-2' src={logo_mp} alt="Logo Mercado Pago" />
                                                                </div>
                                                            </div>
                                                            <div className="pricing-box pricing-extranjero col-5">
                                                                <p className='fw-normal poppins'>Para <span className='fw-semibold poppins '>Extranjeros:</span></p>
                                                                <div className="d-flex flex-column ">
                                                                    <p className='m-0 uppercase fs-3'>Matrícula</p>
                                                                    <p className='m-0 poppins uppercase fs-2'>{matriculaExtranjero} USD</p>
                                                                </div>
                                                                <div className="d-flex flex-column py-3">
                                                                    <p className='m-0 poppins'>+ {cuotasExtranjero} cuotas consecutivas de {precioCuotasExtranjero} USD</p>
                                                                </div>
                                                                <div className="d-flex flex-column pt-5">
                                                                    <p className='m-0 poppins'>Via suscripción de </p>
                                                                    <img className='w-75 py-2 paypal-logo' src={paypal} alt="Logo Mercado Pago" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        //Hay matricula y no hay cuotas fraccionadas
                                                        <div>
                                                            {incluyeDescuento ? (<div>
                                                            <div className="d-flex gap-5 justify-content-center pricing-boxes">
                                                                <div className="pricing-box pricing-local col-5">
                                                                    <p className='fw-normal poppins'>Para <span className='fw-semibold poppins'>Argentinos:</span></p>
                                                                    <div className="d-flex flex-column ">
                                                                        <p className='m-0 poppins text-danger'>{incluyeDescuento}% de descuento</p>
                                                                        <p className='m-0 uppercase fs-3'>Matrícula</p>
                                                                        {/* {incluyeDescuento != false ? (<p className='m-0 poppins uppercase fs-2'>$ {matriculaLocal}</p>) : ()} */}
                                                                        <p className='m-0 poppins text-danger' style={{textDecoration: 'line-through'}}>$ {matriculaLocal}</p>
                                                                        <p className='m-0 poppins uppercase fs-2'>$ {Math.round((matriculaLocal - (matriculaLocal * (incluyeDescuento / 100))) * 1000).toLocaleString('es-ES')}</p>
                                                                    </div>
                                                                    <div className="d-flex flex-column py-3">
                                                                        <p className='m-0 poppins'>+ {primerasCuotasLocal} cuotas consecutivas de $ {precioPrimerasCuotasLocal}</p>
                                                                    </div>
                                                                    <div className="d-flex flex-column py-3">
                                                                        <p className='m-0 poppins'>Via suscripción de </p>
                                                                        <img className='w-75 py-2' src={logo_mp} alt="Logo Mercado Pago" />
                                                                    </div>
                                                                </div>
                                                                <div className="pricing-box pricing-extranjero col-5">
                                                                    <p className='fw-normal poppins'>Para <span className='fw-semibold poppins '>Extranjeros:</span></p>
                                                                    <div className="d-flex flex-column ">
                                                                    <p className='m-0 poppins text-dark'>{incluyeDescuento}% de descuento</p>
                                                                    <p className='m-0 uppercase fs-3'>Matrícula</p>
                                                                        <p className='m-0 poppins text-dark' style={{textDecoration: 'line-through'}}>{matriculaExtranjero} USD</p>
                                                                        <p className='m-0 poppins uppercase fs-2'>{Math.ceil(matriculaExtranjero - (matriculaExtranjero * (incluyeDescuento / 100)))} USD</p>
                                                                    </div>
                                                                    <div className="d-flex flex-column py-3">
                                                                        <p className='m-0 poppins'>+ {cuotasExtranjero} cuotas consecutivas de {precioCuotasExtranjero} USD</p>
                                                                    </div>
                                                                    <div className="d-flex flex-column py-3">
                                                                        <p className='m-0 poppins'>Via suscripción de </p>
                                                                        <img className='w-75 py-2 paypal-logo' src={paypal} alt="Logo Mercado Pago" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        ) : (
                                                            <div>
                                                                <div className="d-flex gap-5 justify-content-center pricing-boxes">
                                                                    <div className="pricing-box pricing-local col-5">
                                                                        <p className='fw-normal poppins'>Para <span className='fw-semibold poppins'>Argentinos:</span></p>
                                                                        <div className="d-flex flex-column ">
                                                                            <p className='m-0 uppercase fs-3'>Matrícula</p>
                                                                            {/* {incluyeDescuento != false ? (<p className='m-0 poppins uppercase fs-2'>$ {matriculaLocal}</p>) : ()} */}
                                                                            <p className='m-0 poppins uppercase fs-2'>$ {matriculaLocal}</p>
                                                                        </div>
                                                                        <div className="d-flex flex-column py-3">
                                                                            <p className='m-0 poppins'>+ {primerasCuotasLocal} cuotas consecutivas de $ {precioPrimerasCuotasLocal}</p>
                                                                        </div>
                                                                        <div className="d-flex flex-column py-3">
                                                                            <p className='m-0 poppins'>Via suscripción de </p>
                                                                            <img className='w-75 py-2' src={logo_mp} alt="Logo Mercado Pago" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="pricing-box pricing-extranjero col-5">
                                                                        <p className='fw-normal poppins'>Para <span className='fw-semibold poppins '>Extranjeros:</span></p>
                                                                        <div className="d-flex flex-column ">
                                                                        <p className='m-0 uppercase fs-3'>Matrícula</p>
                                                                            <p className='m-0 poppins uppercase fs-2'>{matriculaExtranjero} USD</p>
                                                                        </div>
                                                                        <div className="d-flex flex-column py-3">
                                                                            <p className='m-0 poppins'>+ {cuotasExtranjero} cuotas consecutivas de {precioCuotasExtranjero} USD</p>
                                                                        </div>
                                                                        <div className="d-flex flex-column py-3">
                                                                            <p className='m-0 poppins'>Via suscripción de </p>
                                                                            <img className='w-75 py-2 paypal-logo' src={paypal} alt="Logo Mercado Pago" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) }
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                //No incluye extranjero (Operador Prehospitalario de Emergencias) 
                                                <div>
                                                    <div>
                                                        <div className="d-flex gap-5 justify-content-center pricing-boxes">
                                                            <div className="pricing-box pricing-local col-11 d-flex align-items-center flex-column">
                                                                <p className='fw-normal poppins'>Para <span className='fw-semibold poppins'>Argentinos:</span></p>
                                                                <div className="d-flex flex-column ">
                                                                    <p className='m-0 uppercase fs-3'>Matrícula</p>
                                                                    <p className='m-0 poppins uppercase fs-2'>$ {matriculaLocal}</p>
                                                                </div>
                                                                <div className="d-flex flex-column py-3">
                                                                    <p className='m-0 poppins'>+ {primerasCuotasLocal} cuotas consecutivas de $ {precioPrimerasCuotasLocal}</p>
                                                                </div>
                                                                <div className="d-flex flex-column align-items-center py-3">
                                                                    <p className='m-0 poppins'>Via suscripción de </p>
                                                                    <img className='w-25 py-2' src={logo_mp} alt="Logo Mercado Pago" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        //No incluye matricula (Programa Ampliado de Inmunizaciones)
                                        <div>
                                            <div>
                                                <div className="d-flex gap-5 justify-content-center pricing-boxes">
                                                    <div className="pricing-box pricing-local col-11 d-flex align-items-center justify-content-center flex-column">
                                                        <p className='fw-normal poppins'>Para <span className='fw-semibold poppins'>Argentinos:</span></p>
                                                        <div className="d-flex flex-column align-items-center py-3">
                                                            <p className='m-0 uppercase fs-3'>{primerasCuotasLocal} cuotas consecutivas de</p>
                                                            <p className='m-0 poppins uppercase fs-2'>$ {precioPrimerasCuotasLocal}</p>
                                                        </div>
                                                        <div className="d-flex flex-column align-items-center py-3">
                                                            <p className='m-0 poppins'>Via suscripción de </p>
                                                            <img className='w-25 py-2' src={logo_mp} alt="Logo Mercado Pago" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                <div className="pricing-disclaimer mx-4 px-4 py-3 my-3">
                                    <p className='pt-3 poppins'>De continuar con la inscripción se le cobrará la matrícula. Para completar el proceso será necesario el envío de la documentación requerida y la aceptación a la suscripción de las cuotas. </p>
                                    <p className='pb-3 poppins'><span>* </span>En caso de  no completar estos requisitos se realizará la devolución de la matrícula.</p>
                                    <div className="w-100 d-flex justify-content-center">
                                        <a className=' w-100' href={`https://inscripciones.fisp.org.ar/finalizar-compra/?add-to-cart=${cursoId}`}><button className='btn btn-dark w-100 py-3'>Continuar Inscripción</button></a>
                                    </div>
                                </div>
                            </div>
                            )
                    ) 
                }
            </div>
        );
}

export default PricingCurso