import React, { useContext, useEffect, useState } from 'react'
import Axios from "axios";
import NavbarPortal from '../Navbars/NavbarPortal';
import FooterPortal from './FooterPortal';
import transicionEscalera from '../Transiciones/transicionEscalera';
import transicionBottomTop from '../Transiciones/transicionBottomTop';
import editorLayout from '../multimedia/imagenes/portal/side-layout.png'
import { ToastContainer, toast } from 'react-toastify'
import { Link } from 'react-router-dom';

const OrganizarSide = () => {
    
    const [apartado,setApartado] = useState([])
    const [id,setId] = useState("")
    const [categoria,setCategoria] = useState("")
    const [descripcion,setDescripcion] = useState("")
    const [imagen,setImagen] = useState("")
    const [posicion,setPosicion] = useState("")
    const [noticias,setNoticias] = useState([])
    const [noticiasModal,setNoticiasModal] = useState([])
    const [toggleModal,setToggleModal] = useState(false)

    const obtenerNotas = ()=>{
        Axios.get("https://srv452353.hstgr.cloud/obtenernotas/")
        .then((response) => {
            console.log("Noticias recibidas")
            setNoticias(Array.from(response.data));
            setNoticiasModal(Array.from(response.data));
            console.log(response.data)
        })
        .catch((error) => {
            console.log("Error")
            console.error(error);
        })
        .finally(() => {
            console.log("Proceso finalizado")
        });
    }

    const obtenerApartado = ()=>{
        Axios.get("https://srv452353.hstgr.cloud/obtenerapartado/")
        .then((response) => {
            setApartado(Array.from(response.data))
        })
        .catch((error) => {
            console.log("Error al traer el apartado")
            console.error(error);
        })
    }

    const changeMessage = (text)=>{
        switch(text){
            case "desde-adentro":
                setDescripcion("Entrevistas intimas a reconocidas figuras")
                break;
            case "real-food":
                setDescripcion("Recorremos las principales cocinas creando platos 100% naturales")
                break;
            case "el-nudo":
                setDescripcion("Derribando mitos y tabúes")
                break;
            case "vida-sana":
                setDescripcion("Nos animamos a los deportes y te contamos los beneficios")
                break;
            default:
                setDescripcion("")
                break;
        }
    }

    const actualizarOrden = ()=>{
        if(categoria!=""&&descripcion!=""&&imagen!=""&&posicion!=""){
            Axios.post("https://srv452353.hstgr.cloud/ordenarside",{
                id: id,
                categoria: categoria,
                descripcion: descripcion,
                imagen: imagen,
                posicion: posicion
            }).then((result)=>{
                console.log("Actualizado el apartado con éxito.")
            }).catch((e)=>{
                console.log("Error al actualizar el apartado")
                console.log(e)
            }).finally(()=>{
                toast.success("Se actualizó con éxito.")
                setTimeout(()=>{
                    window.location.reload();
                },[1500])
            })
        }else{
            toast.error("Debes completar todos los campos.")
        }
    }

    const filtrarNotas = (titulo)=>{
        const filtro = noticias.filter(noticia=>formatString(noticia.titulo).includes(formatString(titulo)))
        setNoticiasModal(filtro)
    }
    function formatString(str) {
        str = removeAccents(str.toLowerCase());
        str = str.replace(/%20/g, '-');
        str = str.replace(/\s+/g, '-');
        str = str.replace(/[^a-z0-9\s\-]/g, '');
        
        return str;
    }
    function removeAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    useEffect(()=>{
        obtenerApartado()
        obtenerNotas()
    },[])

    return (
        <main className='main-editor-container'>
            <NavbarPortal />
            <ToastContainer />
            <div className='container-xxl editor-container d-flex flex-column align-items-center'>
                <div className="d-flex align-items-center flex-column gap-2 w-100" background={'red'}>
                    <div className="col-12 pb-5 d-flex flex-column px-4">
                        <div className="d-flex justify-content-center gap-5">
                            <div className="col-9 d-flex flex-column justify-content-center py-5 px-3 border bg-light">
                                <label className='pb-2' htmlFor="orden-home">Orden Side: </label>
                                <select 
                                    name="orden-home"
                                    id="orden-home" 
                                    className='p-2 col-1 rounded-3 w-20'
                                    onChange={(e)=>{setPosicion(e.target.value)}}
                                    >
                                    <option value="" disabled selected></option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </select>
                                <button className='w-20 py-2 px-2 rounded-2 my-3 border-none btn btn-dark' onClick={()=>{setToggleModal(!toggleModal)}}>Seleccionar imagen</button>
                                <div className={`modal-imagenes rounded-3 py-5 box-shadow-medium ${toggleModal ? 'd-flex' : 'd-none'} justify-content-center align-items-center`} onClick={()=>{console.log("TEST")}}>
                                    <div className="modal-container p-5 rounded-4 overflow-hidden">
                                        <div className="col-12 border-bottom mb-4 d-flex justify-content-between">
                                            <p className='poppins fs-4 uppercase'>Imagenes</p>
                                            <svg onClick={()=>{setToggleModal(false)}} width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M18 6l-12 12" />
                                                <path d="M6 6l12 12" />
                                            </svg>
                                        </div>
                                        <div className="col-12 pb-3">
                                            <label htmlFor="image-input" className='py-2'>Titulo de la nota</label>
                                            <input 
                                                id='image-input'
                                                type="text"
                                                className='w-100 p-2'
                                                onChange={(e)=>{filtrarNotas(e.target.value)}}
                                            />
                                        </div>
                                        <div className="col-12 imagenes-container d-flex gap-3 flex-wrap">
                                            {noticiasModal.map((noticia,index)=>{
                                                return(
                                                    <div className="col-2" key={index} onClick={()=>{setImagen(noticia.imagen); setToggleModal(false)}}>
                                                        <img title={noticia.titulo} className='w-100' src={noticia.imagen} alt="" />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex ">
                                    <img className='mb-3 object-fit-cover' src={imagen} width={600} />
                                </div>
                                <label className='pb-2' htmlFor="category-input-edit">Categoria</label>
                                <select 
                                    name="category"
                                    id="category-input-edit"
                                    onChange={(e)=>{
                                        setCategoria(e.target.value)
                                        changeMessage(e.target.value)
                                    }}
                                    className="form-select mb-3 p-2"
                                    defaultValue={categoria} 
                                    aria-label="Default select example">
                                    <option value=""></option>
                                    <option value="desde-adentro">Desde Adentro</option>
                                    <option value="vida-sana">Vida Sana</option>
                                    <option value="real-food">Real Food</option>
                                    <option value="el-nudo">El Nudo</option>
                                </select>
                                <button className='px-5 w-100 py-3 border-0 rounded-3' onClick={()=>{actualizarOrden()}}>Actualizar</button>
                            </div>
                            <div className="col-2 d-flex flex-column align-items-center justify-content-center py-3 border bg-light">
                                <p className='text-center poppins fs-2 border-bottom pb-2 uppercase'>Layout Side</p>
                                <img className='w-50' src={editorLayout} alt="" />
                            </div>
                        </div>
                        <div className="apartado d-flex justify-content-evenly bg-light mt-5 p-5">
                            {apartado.map(item=>{
                                return(
                                    <div className='col-3 border'>
                                        <div>
                                            <div className={`side-article-item col-lg-12 rounded-4 pt-4 pb-2 ${item.categoria}`}>
                                                <div className="px-4">
                                                    <img className='w-100 h-100' src={item.imagen} alt={item.categoria} />
                                                    <h4 className='text-dark fs-3 roboto mt-3 py-0 uppercase'>{item.categoria}</h4>
                                                    <p className='text-dark fs-5 text-balanced lh-1 roboto'>{item.descripcion}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <FooterPortal />
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(OrganizarSide) : transicionBottomTop(OrganizarSide);
