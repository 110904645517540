import React from 'react'

const TemarioGestionEnfermeria = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <b>Introducción a la Gestión en Enfermería</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Presentación del curso y objetivos generales y específicos.</span></li>
                    <li><span className='fw-light'>Importancia de la gestión en el ámbito de la enfermería y su impacto en la calidad de la atención.</span></li>
                    <li><span className='fw-light'>Conceptos fundamentales de gestión y su aplicación en el campo de la salud.</span></li>
                    <li><span className='fw-light'>Rol del administrador de servicios de enfermería y sus funciones principales.</span></li>
                    <li><span className='fw-light'>Relación entre gestión estratégica, planificación y toma de decisiones en el ámbito de la salud.</span></li>
                    <li><span className='fw-light'>Análisis de casos de éxito en la gestión de servicios de enfermería.</span></li>
                </ul>
                <br /><br />

                <b>Enfoques Teóricos en la Gestión de Enfermería</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Exploración de enfoques teóricos y modelos de gestión aplicados a la enfermería.</span></li>
                    <li><span className='fw-light'>Análisis de los principios y características de cada enfoque teórico.</span></li>
                    <li><span className='fw-light'>Relación entre los enfoques teóricos y la mejora de la calidad en la atención.</span></li>
                    <li><span className='fw-light'>Discusión y debate sobre la relevancia y aplicabilidad de los diferentes enfoques en el ámbito de la enfermería.</span></li>
                    <li><span className='fw-light'>Ejemplos prácticos de aplicación de enfoques teóricos en la gestión de servicios de salud.</span></li>
                </ul>
                <br /><br />

                <b>Gestión Estratégica y Toma de Decisiones en Salud </b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Enfoque en la gestión estratégica y su relación con la toma de decisiones en salud.</span></li>
                    <li><span className='fw-light'>Planificación estratégica en servicios de enfermería: visión, misión y objetivos.</span></li>
                    <li><span className='fw-light'>Identificación y análisis de factores internos y externos que influyen en la gestión estratégica.</span></li>
                    <li><span className='fw-light'>Métodos y técnicas para la toma de decisiones estratégicas en el ámbito de la salud.</span></li>
                    <li><span className='fw-light'>Análisis de casos prácticos de gestión estratégica en servicios de enfermería.</span></li>
                </ul>
                <br /><br />

                <b>Evaluación del Impacto de la Gestión Estratégica en la Calidad de la Atención</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Evaluación del impacto de la gestión estratégica en la calidad de la atención y la satisfacción del paciente.</span></li>
                    <li><span className='fw-light'>Indicadores de calidad y su relación con la gestión estratégica en servicios de enfermería.</span></li>
                    <li><span className='fw-light'>Métodos para medir la efectividad de los planes estratégicos implementados.</span></li>
                    <li><span className='fw-light'>Importancia de la retroalimentación y mejora continua en la gestión de servicios de salud.</span></li>
                    <li><span className='fw-light'>Presentación y discusión de resultados de estudios sobre el impacto de la gestión estratégica en servicios de enfermería.</span></li>
                </ul>
                <br /><br />

                <b>Fundamentos del Liderazgo Transformador en Enfermería y su Aporte al Alto Rendimiento del Equipo</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Introducción al liderazgo transformador y su relevancia en el ámbito de la enfermería.</span></li>
                    <li><span className='fw-light'>Teorías y enfoques del liderazgo aplicados a la gestión de servicios de salud.</span></li>
                    <li><span className='fw-light'>Importancia del coaching como herramienta para el desarrollo del liderazgo transformador.</span></li>
                    <li><span className='fw-light'>Habilidades de liderazgo para inspirar, motivar y empoderar al equipo de enfermería.</span></li>
                    <li><span className='fw-light'>Casos prácticos y ejemplos de liderazgo transformador en el ámbito de la salud.</span></li>
                </ul>
                <br /><br />

                <span>Comunicación Efectiva en el Cuidado de la Salud: Enfoque en la Relación con Pacientes y Familias. </span>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Concepto y elementos clave de la comunicación efectiva en el ámbito de la salud.</span></li>
                    <li><span className='fw-light'>Habilidades de comunicación para una interacción empática y centrada en el paciente.</span></li>
                    <li><span className='fw-light'>Comunicación con pacientes y familias en situaciones de estrés y emocionalmente cargadas.</span></li>
                    <li><span className='fw-light'>Uso de técnicas de comunicación para una toma de decisiones compartida y colaborativa.</span></li>
                    <li><span className='fw-light'>Rol del líder en la promoción de una comunicación efectiva en el equipo de enfermería</span></li>
                </ul>
                <br /><br />

                <span>Colaboración y Trabajo en Equipo Interprofesional en Servicios de Enfermería</span>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Importancia de la colaboración y trabajo en equipo en la prestación de servicios de salud.</span></li>
                    <li><span className='fw-light'>Enfoque interprofesional en el cuidado de pacientes complejos y crónicos.</span></li>
                    <li><span className='fw-light'>Estrategias para promover la colaboración entre el equipo de enfermería y otros profesionales de la salud.</span></li>
                    <li><span className='fw-light'>Toma de decisiones compartida y enfoque en la resolución de problemas de forma colaborativa.</span></li>
                    <li><span className='fw-light'>Análisis de casos de éxito en equipos interprofesionales en el ámbito de la enfermería.</span></li>
                </ul>
                <br /><br />

                <span>Integración de Estrategias de Liderazgo, Coaching y Comunicación para la Mejora Continua de la Atención</span>

                <br /><br />
                <ul>
                    <li>
                <ul>
                    <li><span className='fw-light'>Integración de estrategias de liderazgo transformador y coaching en la gestión del equipo de enfermería.</span></li>
                    <li><span className='fw-light'>Uso de técnicas de comunicación efectiva para la mejora continua de la atención.</span></li>
                    <li><span className='fw-light'>Implementación de estrategias de liderazgo y coaching para alcanzar el alto rendimiento del equipo.</span></li>
                    <li><span className='fw-light'>Evaluación del impacto de las estrategias aplicadas en la calidad de la atención y satisfacción del paciente.</span></li>
                </ul>
                </li>
                </ul>
                <ul>
                    <li aria-level="1"><span>Planificación de acciones para mantener y fortalecer el liderazgo transformador y la comunicación efectiva en el ámbito de la salud.</span></li>
                </ul>
                <br /><br />

                <b>Gestión del Talento y Desarrollo Profesional en Enfermería</b>

                <br /><br />

                <b>Diagnóstico de Necesidades de Desarrollo Profesional en Enfermería</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Importancia de la gestión del talento humano en la mejora de la atención en salud.</span></li>
                    <li><span className='fw-light'>Identificación de las necesidades de capacitación y formación del equipo de enfermería.</span></li>
                    <li><span className='fw-light'>Herramientas y técnicas para evaluar las competencias y habilidades del personal de enfermería.</span></li>
                    <li><span className='fw-light'>Análisis de tendencias y avances en el campo de la enfermería para mantener actualizado al personal.</span></li>
                </ul>
                <br /><br />

                <b>Estrategias de Reclutamiento y Selección de Talento en Enfermería</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Perfiles y competencias requeridas para diferentes puestos de enfermería.</span></li>
                    <li><span className='fw-light'>Diseño de estrategias de reclutamiento y atracción de talento en el ámbito de la salud.</span></li>
                    <li><span className='fw-light'>Proceso de selección y evaluación de candidatos para asegurar la contratación de personal idóneo.</span></li>
                </ul>
                <br /><br />

                <b>Planes de Desarrollo Profesional en Enfermería: Crecimiento y Retención del Talento.</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Diseño de planes de desarrollo profesional para el crecimiento y proyección del personal de enfermería.</span></li>
                    <li><span className='fw-light'>Identificación de oportunidades de capacitación, formación y especialización en el campo de la enfermería.</span></li>
                    <li><span className='fw-light'>Estrategias para fomentar la retención del talento y el desarrollo de carreras en servicios de enfermería.</span></li>
                </ul>
                <br /><br />

                <b>Cultura de Aprendizaje y Evaluación del Desempeño en Enfermería</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Importancia de una cultura de aprendizaje en el ámbito de la enfermería y su impacto en la calidad de la atención.</span></li>
                    <li><span className='fw-light'>Estrategias para fomentar el aprendizaje continuo y la actualización de conocimientos en el equipo.</span></li>
                    <li><span className='fw-light'>Diseño de sistemas de evaluación y seguimiento del desempeño del personal de enfermería.</span></li>
                    <li><span className='fw-light'>Técnicas de retroalimentación efectiva para promover el desarrollo profesional y la mejora continua.</span></li>
                </ul>
                <br /><br />

                <b>Innovación y Tecnología en la Administración de Servicios de Salud</b>

                <br /><br />

                <b>Tendencias y Avances Tecnológicos en la Gestión de Servicios de Salud </b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Introducción a la importancia de la innovación y la tecnología en la gestión de servicios de salud.</span></li>
                </ul>
                <ul>
                    <li><span className='fw-light'>Exploración de tendencias y avances tecnológicos relevantes en el ámbito de la salud.</span></li>
                    <li><span className='fw-light'>Identificación de tecnologías emergentes con potencial impacto en la enfermería.</span></li>
                </ul>
                <ul>
                    <li><span className='fw-light'>Casos prácticos y ejemplos de aplicaciones tecnológicas innovadoras en el ámbito de la salud.</span></li>
                </ul>
                <br /><br />

                <b>Impacto de la Innovación y la Tecnología en la Calidad y Eficiencia de la Atención </b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Análisis del impacto de la innovación y la tecnología en la calidad y seguridad de la atención en enfermería.</span></li>
                    <li><span className='fw-light'>Evaluación de los beneficios y desafíos de la implementación de tecnología en servicios de enfermería.</span></li>
                    <li><span className='fw-light'>Casos de éxito y estudios de casos sobre la mejora de la eficiencia en la atención mediante el uso de tecnología.</span></li>
                    <li><span className='fw-light'>Consideraciones éticas y legales en el uso de tecnología en la atención de salud.</span></li>
                </ul>
                <br /><br />

                <b>Implementación de Soluciones Tecnológicas en la Gestión de Procesos y Recursos en Enfermería </b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Selección y adopción de tecnologías apropiadas para la gestión de procesos y recursos en enfermería.</span></li>
                    <li><span className='fw-light'>Capacitación y formación del personal en el uso de las nuevas tecnologías.</span></li>
                    <li><span className='fw-light'>Integración de soluciones tecnológicas en la gestión de agendas, registros y seguimiento de pacientes.</span></li>
                    <li><span className='fw-light'>Monitoreo y evaluación de la efectividad de las soluciones tecnológicas implementadas.</span></li>
                </ul>
                <br /><br />

                <b>Uso de Tecnología de la Información para la Recopilación y Análisis de Datos en el Ámbito de la Salud</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Rol de la tecnología de la información en la recopilación y análisis de datos en el ámbito de la salud.</span></li>
                    <li><span className='fw-light'>Implementación de sistemas de información y registro electrónico en enfermería.</span></li>
                    <li><span className='fw-light'>Análisis de datos para la toma de decisiones basada en evidencia y mejora de la calidad.</span></li>
                    <li><span className='fw-light'>Casos prácticos y ejemplos de aplicaciones de análisis de datos en la gestión de servicios de salud.</span></li>
                </ul>
                <br /><br />

                <b>Teorías y Modelos de Enfermería: Inspiración y Aplicación en la Gestión</b>

                <br /><br />

                <b>Introducción a las Teorías y Modelos de Enfermería </b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Definición y relevancia de las teorías y modelos de enfermería en la práctica de gestión.</span></li>
                    <li><span className='fw-light'>Estudio de las principales teorías y modelos de enfermería y sus fundamentos.</span></li>
                    <li><span className='fw-light'>Identificación de las implicancias de cada teoría/modelo en la toma de decisiones en gestión de servicios de salud.</span></li>
                </ul>
                <br /><br />

                <b>Planificación de Proyectos de Intervención en Enfermería </b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Conceptos básicos de la planificación de proyectos en enfermería.</span></li>
                    <li><span className='fw-light'>Integración de enfoques basados en teorías de enfermería en la planificación de proyectos.</span></li>
                    <li><span className='fw-light'>Diseño y elaboración de un plan de intervención basado en teorías de enfermería.</span></li>
                </ul>
                <br /><br />

                <b>Atención Centrada en el Paciente y la Familia</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Importancia de la atención centrada en el paciente y la familia en la enfermería.</span></li>
                    <li><span className='fw-light'>Análisis de modelos de enfermería que promueven la atención centrada en el paciente.</span></li>
                    <li><span className='fw-light'>Estrategias para integrar la perspectiva del paciente y la familia en la gestión de servicios de salud.</span></li>
                </ul>
                <br /><br />

                <b>Creación de un Modelo o Teoría de Enfermería</b>

                <br /><br />
                <ul>
                    <li>
                <ul>
                    <li><span className='fw-light'>Análisis de las teorías y modelos estudiados previamente para identificar sus elementos comunes y divergentes.</span></li>
                    <li><span className='fw-light'>Exhortación a los estudiantes para que consideren la posibilidad de crear su propio modelo o teoría de enfermería.</span></li>
                    <li><span className='fw-light'>Guía y apoyo para desarrollar su propuesta, basada en la combinación o adaptación de teorías existentes y sus propias experiencias prácticas.</span></li>
                </ul>
                </li>
                </ul>
                <ul>
                    <li aria-level="1"><span>Presentación de las propuestas y retroalimentación entre los estudiantes.</span></li>
                </ul>
                <br /><br />

                <b>Gestión de la Seguridad del Paciente y Mejora Continua</b>

                <br /><br />

                <b>Fundamentos de Calidad y Seguridad en Servicios de Salud y Seguridad del Paciente y Gestión de Riesgos</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Concepto de seguridad del paciente y su relevancia en la atención de salud.</span></li>
                    <li><span className='fw-light'>Estándares y regulaciones de seguridad del paciente en el ámbito de la enfermería.</span></li>
                    <li><span className='fw-light'>Importancia de la cultura de seguridad y su impacto en la calidad de la atención.</span></li>
                    <li><span className='fw-light'>Identificación de riesgos y situaciones críticas que pueden afectar la seguridad del paciente.</span></li>
                    <li><span className='fw-light'>Análisis de eventos adversos y su impacto en la seguridad del paciente.</span></li>
                    <li><span className='fw-light'>Planificación de medidas preventivas para reducir la incidencia de errores y eventos adversos.</span></li>
                </ul>
                <br /><br />

                <b>Seguridad del Paciente y Prevención de Errores</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Promoción de una cultura de seguridad y trabajo en equipo en servicios de enfermería.</span></li>
                    <li><span className='fw-light'>Importancia de la notificación de eventos adversos y su impacto en la mejora de la seguridad del paciente.</span></li>
                    <li><span className='fw-light'>Implementación de estrategias de prevención de errores y mejora de la seguridad del paciente.</span></li>
                </ul>
                <br /><br />

                <b>Evaluación de la Experiencia del Paciente</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Métodos de evaluación de la satisfacción y experiencia del paciente en la atención.</span></li>
                    <li><span className='fw-light'>Uso de la retroalimentación del paciente para la mejora continua de la calidad de atención.</span></li>
                    <li><span className='fw-light'>Planificación de acciones correctivas y mejoras basadas en la retroalimentación del paciente.</span></li>
                </ul>
                <br /><br />

                <b>Gestión Financiera y Optimización de Recursos en Enfermería</b>

                <br /><br />

                <b>Fundamentos de Gestión Financiera en el Ámbito de la Salud</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Introducción a los principios de gestión financiera aplicados a servicios de enfermería.</span></li>
                    <li><span className='fw-light'>Análisis de estados financieros y su importancia para la toma de decisiones.</span></li>
                    <li><span className='fw-light'>Identificación de indicadores financieros clave para evaluar el desempeño de los servicios de salud.</span></li>
                </ul>
                <br /><br />

                <b>Presupuestación y Planificación Financiera en Enfermería y Administración y Control de Costos en el Ámbito de la Salud</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Proceso de elaboración de presupuestos en servicios de enfermería.</span></li>
                    <li><span className='fw-light'>Establecimiento de objetivos financieros y planificación para el cumplimiento de metas.</span></li>
                    <li><span className='fw-light'>Monitoreo y ajuste de presupuestos en función del desempeño y cambios en el entorno. </span></li>
                    <li><span className='fw-light'>Identificación y análisis de los costos operativos en servicios de enfermería.</span></li>
                </ul>
                <ul>
                    <li><span className='fw-light'>Implementación de estrategias para reducir costos y mejorar la eficiencia.</span></li>
                </ul>
                <ul>
                    <li><span className='fw-light'>Uso de herramientas de control de costos y análisis de variaciones.</span></li>
                </ul>
                <br /><br />

                <b>Gestión Financiera para la Toma de Decisiones Estratégicas</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Integración de la gestión financiera con la planificación estratégica en servicios de salud.</span></li>
                    <li><span className='fw-light'>Análisis de inversiones y retorno de inversión en proyectos de mejora de la calidad.</span></li>
                    <li><span className='fw-light'>Toma de decisiones informadas basadas en el análisis financiero y de costos.</span></li>
                </ul>
                <b>Organización de Servicios de Enfermería y Gestión de Calidad</b>

                <br /><br />

                <b>: Dotación de Personal y Gestión de Recursos Humanos en Enfermería</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Análisis de la importancia de la dotación de personal en la calidad de la atención de enfermería.</span></li>
                    <li><span className='fw-light'>Evaluación de las necesidades de personal según la demanda de atención y la complejidad de los casos.</span></li>
                    <li><span className='fw-light'>Cálculo de la dotación óptima en distintas áreas de atención de salud.</span></li>
                    <li><span className='fw-light'>Estrategias para gestionar la disponibilidad y asignación de personal en distintos turnos y servicios.</span></li>
                    <li><span className='fw-light'>Casos prácticos y ejemplos de gestión de recursos humanos en servicios de enfermería.</span></li>
                </ul>
                <br /><br />

                <b>Implementación de Normas y Protocolos en el Departamento de Enfermería </b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Importancia de la estandarización y la implementación de normas y protocolos en la prestación de servicios de enfermería.</span></li>
                    <li><span className='fw-light'>Desarrollo y adaptación de normas y protocolos según las necesidades específicas de cada institución de salud basados en la evidencia científica. </span></li>
                    <li><span className='fw-light'>Uso de tecnologías de la información para la gestión y actualización de las normas y protocolos.</span></li>
                    <li><span className='fw-light'>Casos prácticos y ejemplos de aplicación de normas y protocolos en servicios de enfermería.</span></li>
                </ul>
                <br /><br />

                <b>Gestión de Calidad en Servicios de Enfermería</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Concepto y principios de la gestión de calidad en el ámbito de la enfermería.</span></li>
                    <li><span className='fw-light'>Implementación de un sistema de gestión de calidad en el departamento de enfermería.</span></li>
                    <li><span className='fw-light'>Proceso de mejora continua y evaluación de la calidad en los servicios de enfermería.</span></li>
                    <li><span className='fw-light'>Monitoreo y medición del desempeño en base a indicadores de calidad.</span></li>
                    <li><span className='fw-light'>Casos prácticos y ejemplos de implementación de gestión de calidad en servicios de enfermería.</span></li>
                    <li><span className='fw-light'>Taller: Elaboración de indicadores de calidad. </span></li>
                </ul>
                <br /><br />

                <b>Auditoría y Acreditación en Servicios de Enfermería</b>

                <br /><br />
                <ul>
                    <li>
                <ul>
                    <li><span className='fw-light'>Importancia de la auditoría y acreditación en el aseguramiento de la calidad en servicios de salud.</span></li>
                    <li><span className='fw-light'>Proceso de auditoría y certificación de unidades de enfermería según estándares internacionales.</span></li>
                    <li><span className='fw-light'>Preparación para auditorías y evaluaciones de acreditación en enfermería.</span></li>
                    <li><span className='fw-light'>Uso de resultados de auditorías y acreditaciones para la mejora continua de la calidad.</span></li>
                </ul>
                </li>
                </ul>
                <ul>
                    <li aria-level="1"><span>Casos prácticos y ejemplos de auditoría y acreditación en servicios de enfermería.</span></li>
                </ul>
                <br /><br />

                <b>Investigación Aplicada a la Gestión en Enfermería</b>

                <br /><br />

                <b>Fundamentos de la Investigación Basada en la Evidencia en Enfermería</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Introducción a los principios de la Enfermería Basada en la Evidencia (EBE) aplicada a la gestión de servicios de enfermería.</span></li>
                    <li><span className='fw-light'>Identificación de preguntas de investigación relevantes para la mejora de la atención en base a la evidencia científica.</span></li>
                    <li><span className='fw-light'>Uso de fuentes de información y bases de datos especializadas en EBE para la investigación en salud.</span></li>
                </ul>
                <br /><br />

                <b>Diseño de Estudios de Investigación en Servicios de Enfermería con Enfoque en EBE</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Selección del diseño de investigación más apropiado para abordar preguntas específicas en el ámbito de la enfermería, enfocado en EBE.</span></li>
                    <li><span className='fw-light'>Definición de la muestra y métodos de recolección de datos en estudios basados en EBE.</span></li>
                    <li><span className='fw-light'>Consideraciones éticas en la investigación aplicada a la gestión de enfermería basada en la evidencia.</span></li>
                </ul>
                <br /><br />

                <b>Análisis de Datos en Investigación en Salud con Enfoque en EBE</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Métodos de análisis estadístico específicos para interpretar resultados de investigaciones en enfermería basadas en EBE.</span></li>
                    <li><span className='fw-light'>Uso de herramientas y software para el análisis de datos en estudios de salud con enfoque en EBE.</span></li>
                    <li><span className='fw-light'>Presentación y comunicación efectiva de los hallazgos de investigación basados en EBE.</span></li>
                </ul>
                <br /><br />

                <b>Aplicación de Resultados de Investigación en la Gestión de Enfermería con Enfoque en EBE</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Interpretación y aplicación de los hallazgos de investigación basados en EBE en la toma de decisiones.</span></li>
                    <li><span className='fw-light'>Uso de la evidencia científica para crear protocolos, guías y normas basados en EBE y mejorar la calidad y eficiencia de la atención en enfermería.</span></li>
                    <li><span className='fw-light'>Implementación de estrategias para promover la adopción de prácticas basadas en la evidencia en los servicios de salud. </span></li>
                </ul>
                <br /><br />

                <b>La Importancia del Autocuidado en los Profesionales de Enfermería e Implementación del Departamento de Bienestar. </b>

                <br /><br />

                <b>La Importancia del Autocuidado en Profesionales de Enfermería</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Introducción al concepto de autocuidado y su relevancia en la vida profesional de los y las enfermeras.</span></li>
                    <li><span className='fw-light'>Fatiga por compasión, desgaste profesional y síndrome de burnout: identificación de los riesgos y consecuencias del descuido del autocuidado.</span></li>
                    <li><span className='fw-light'>Estrategias y técnicas para promover el autocuidado en el día a día laboral y personal de los y las profesionales de enfermería.</span></li>
                </ul>
                <br /><br />

                <b>Beneficios de Implementar un Departamento de Bienestar en Entornos de Salud</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Análisis de la relevancia del bienestar en la salud mental y física de los y las profesionales de enfermería.</span></li>
                    <li><span className='fw-light'>Presentación de casos exitosos de implementación de Departamentos de Bienestar en instituciones de salud.</span></li>
                    <li><span className='fw-light'>El papel de la gestión y el liderazgo en la promoción del bienestar y autocuidado en los equipos de enfermería.</span></li>
                </ul>
                <br /><br />

                <b>Herramientas para la Implementación del Departamento de Bienestar</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Identificación de recursos y herramientas necesarias para establecer y mantener un Departamento de Bienestar efectivo.</span></li>
                    <li><span className='fw-light'>Diseño de estrategias de autocuidado personalizado y adaptado a las necesidades de cada profesional.</span></li>
                    <li><span className='fw-light'>Planificación de actividades y programas para promover el bienestar y el autocuidado en el entorno laboral.</span></li>
                </ul>
                <br /><br />

                <b>Integración del Autocuidado en la Cultura Organizacional</b>

                <br /><br />
                <ul>
                    <li><span className='fw-light'>Promoción de una cultura de autocuidado y bienestar en el departamento de enfermería.</span></li>
                    <li><span className='fw-light'>Evaluación y seguimiento del impacto del Departamento de Bienestar en la calidad de vida y bienestar de los y las profesionales de enfermería.</span></li>
                    <li><span className='fw-light'>Compromiso y liderazgo para asegurar la sostenibilidad del Departamento de Bienestar en el tiempo.</span></li>
                </ul>
        </div>
    )
}

export default TemarioGestionEnfermeria