import { useParams } from 'react-router-dom';
import FirmaDigital from './FirmaDigital';
import ImplementacionTecnologias from './ImplementacionTecnologias';
import Telemedicina from './Telemedicina';
import PaginaError from '../../PaginaError';
import PlataformaSalud from './PlataformaSalud';
import ConsultoriaTecnologias from './ConsultoriaTecnologias';

const ServicioTecnologicoRedirect = () => {
    const { service } = useParams();
    let serviceComponent;
    switch (service) {
        case 'gestion-de-consultoria-con-firma-digital':
            serviceComponent = <FirmaDigital />
            break;
        case 'implementacion-de-tecnologias':
            serviceComponent = <ImplementacionTecnologias />
            break;
        case 'sistemas-de-telemedicina-de-urgencias':
            serviceComponent = <Telemedicina />
            break;
        case 'consultoria-de-tecnologia-en-salud-para-empresas':
            serviceComponent = <ConsultoriaTecnologias />
            break;
        case 'plataforma-de-salud-para-organizaciones':
            serviceComponent = <PlataformaSalud />
            break;
        default:
            serviceComponent = <PaginaError />
            break;
    }
    return serviceComponent
};

export default ServicioTecnologicoRedirect;