import React, { useEffect, useRef } from 'react'
import SideNav from '../Navbars/SideNav'
import RegularNavbar from '../Navbars/RegularNavbar'
import transicionEscalera from '../Transiciones/transicionEscalera'
import transicionBottomTop from '../Transiciones/transicionBottomTop'
import edificios from '../multimedia/imagenes/fundacion/edificios.png'
import ContadorFundacion from './ContadorFundacion'

const Fundacion = () => {
    const isMountedRef = useRef(false);
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    },[])
    const counters = [
        { end: 1500, text: 'ALUMNOS' },
        { end: 100, text: 'PROFESIONALES', text2: 'DE ALTO NIVEL' },
        { end: 20, text: 'OFERTAS ACADEMICAS', text2: 'EN SALUD' }
    ];

    const maxEnd = Math.max(...counters.map(counter => counter.end));
    
    
    return (
        <main className='fundacion-section  overflow-hidden' id='main-element'>
            
            <SideNav />
            <div className="container-xxl">
                <div className="d-flex justify-content-center align-items-center flex-column section-md">
                    <div className="d-flex justify-content-center align-items-center flex-column intro-content py-5 position-relative">
                        <div className='d-flex justify-content-center align-items-center flex-column content'>
                            <h5 className='poppins fw-medium my-0'>BIENVENIDO A FISP</h5>
                            <h1 className="poppins fs-big-title">¿Quienes somos?</h1>
                            <p className='fw-small w-75 px-5 text-center'>Transformamos la salud con tecnología y profesionales capacitados.</p>
                        </div>
                        <a href="#fisp-data">
                            <svg id='scroll' width="42" height="42" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M6 3m0 4a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v10a4 4 0 0 1 -4 4h-4a4 4 0 0 1 -4 -4z" />
                                <path d="M12 7l0 4" />
                            </svg>
                        </a>
                        <svg id='down-arrow' className='mt-5' width="42" height="72" viewBox="0 0 24 34" strokeWidth="0.8" stroke="#000000" fill="none">
                            <path stroke="none" d="M0 0h24v34H0z" fill="none"/>
                            <path d="M12 5l0 23" />
                            <path d="M16 25l-4 4" />
                            <path d="M8 25l4 4" />
                        </svg>
                        <svg className='background-intro' xmlns="http://www.w3.org/2000/svg" version="1.1" width="1440" height="560" preserveAspectRatio="none" viewBox="0 0 1440 560">
                            <g mask="url(&quot;#SvgjsMask1014&quot;)" fill="none">
                                <rect width="1440" height="560" x="0" y="0" fill="none"></rect>
                                <path d="M-55.07038344303369 295.58551758077294L-28.201941074610588 447.96402630625624 97.30812528244961 268.71707521234987z" fill="rgba(27, 25, 98, 0.8)" className="triangle-float3"></path>
                                <path d="M452.865,342.184C513.529,346.683,569.223,307.13,598.354,253.728C626.325,202.453,620.578,140.327,590.19,90.446C561.059,42.628,508.843,13.624,452.865,14.913C399.009,16.154,350.609,48.569,326.078,96.53C303.493,140.687,317.751,190.532,340.498,234.606C365.988,283.993,397.44,338.074,452.865,342.184" fill="rgba(27, 25, 98, 0.7)" className="triangle-float3"></path>
                                <path d="M1061.989,426.096C1088.522,426.991,1114.808,415.54,1128.382,392.724C1142.239,369.432,1140.144,340.559,1126.966,316.877C1113.385,292.471,1089.919,273.384,1061.989,273.409C1034.095,273.434,1009.947,292.175,997.211,316.992C985.51,339.791,989.844,366.457,1002.927,388.492C1015.698,410.001,1036.988,425.253,1061.989,426.096" fill="rgba(27, 25, 98, 0.8)" className="triangle-float1"></path>
                                <path d="M533.3826374679899 178.3433739567118L660.0066787928291 288.628014420377 690.5253016035755 145.04918264512307z" fill="rgba(27, 25, 98, 0.4)" className="triangle-float1"></path>
                                <path d="M465.329,339.393C514.571,339.781,553.697,299.973,574.606,255.388C592.599,217.02,582.802,173.354,559.925,137.681C539.042,105.117,503.963,86.98,465.329,84.99C422.275,82.773,375.192,90.513,352.203,126.983C328.03,165.333,337.68,213.679,358.99,253.691C381.927,296.758,416.537,339.009,465.329,339.393" fill="rgba(27, 25, 98, 0.8)" className="triangle-float1"></path>
                                <path d="M118.00785570874521 523.299677318742L281.564150434079 546.2860155002708 304.5504886156077 382.72972077493705 140.99419389027398 359.7433825934083z" fill="rgba(27, 25, 98,0.8)" className="triangle-float3"></path>
                                <path d="M879.326334562539 608.6589906045131L911.4105647831195 457.71455506933114 728.3818990273571 576.5747603839326z" fill="rgba(27, 25, 98, 0.7)" className="triangle-float3"></path>
                                <path d="M743.0947479596099 434.1275438851565L773.182073253183 554.8012145137102 893.8557438817367 524.7138892201372 863.7684185881635 404.0402185915834z" fill="rgba(27, 25, 98, 0.8)" className="triangle-float1"></path>
                            </g>
                            <defs>
                                <mask id="SvgjsMask1014">
                                    <rect width="1440" height="560" fill="#ffffff"></rect>
                                </mask>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div className="section-sm py-sm-5 py-0">
                <div className="row w-100 d-flex justify-content-between">
                    <div className="heading box-shadow-medium d-flex flex-column" id='vision'>
                        <div className='p-4 cutoff-text' id='text-vision'>
                            <h2 className='poppins fw-semibold fs-4'>VISIÓN</h2>
                            <p className='pt-3'>Ser referentes en este mundo tan dinámico en dotar de las últimas tecnologías tanto en la capacitación del recurso humano como en el equipamiento de las instituciones.</p>
                        </div>
                    </div>
                    <div className="heading box-shadow-medium d-flex flex-column" id='mision'>
                        <div className='p-4 cutoff-text' id='text-mision'>
                            <h2 className='poppins fw-semibold fs-4'>MISIÓN</h2>
                            <p className='pt-3'>La Fundación Iberoamericana de Salud Pública, es una Organización no Gubernamental sin fines de lucro.</p>
                            <p>Creada por un grupo de Profesionales Multidisciplinarios con importantes Antecedentes Académicos, Científicos y Operativos en el Sector Salud.</p>
                            <p>Se agregan representantes que participan activamente en diferentes áreas de la Sociedad.</p>
                        </div>
                    </div>
                    <div className="heading box-shadow-medium d-flex flex-column" id='objetivos'>
                        <div className='p-4 cutoff-text' id='text-objetivos'>
                            <h2 className='poppins fw-semibold fs-4'>OBJETIVOS</h2>
                            <p className='pt-3'>● Integrar docencia, asistencia e investigación en los proyectos a desarrollar, con el criterio de creatividad, innovación y conocimiento.</p>
                            <p>● Promover la equidad en salud.</p>
                            <p>● Intervenir en el mejoramiento de la salud, participando en los programas de prevención primaria, secundaria y terciaria, y prestaciones de salud.</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="fisp-data row d-flex justify-content-between py-3 my-5" id='fisp-data'>
                <img src={edificios} alt="" />
                <div className="container-xxl d-flex justify-content-center py-5">
                    <div className="col-12 col-sm-11 col-md-10 col-xxl-12 data-content bg-light rounded-4">
                        <div className="p-4 d-flex flex-column">
                            <div className='d-flex align-items-center justify-content-center gap-lg-5 gap-sm-4 gap-2'>
                                {counters.map((counter, index) => (
                                    <div key={index} className='counter d-flex align-items-center mb-3'>
                                        <ContadorFundacion end={counter.end} />
                                        <div>
                                            <p className='fs-5 p-0 m-0 poppins'>{counter.text}</p>
                                            <p className='fs-5 p-0 m-0 poppins'>{counter.text2}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <p className='mt-4 px-md-5 px-sm-3 px-1 text-center'>La Fundación Iberoamericana de Salud Pública, es una organización de la sociedad civil sin fines de lucro cuyo objetivo principal es formar profesionales con los más altos estándares académicos y tecnológicos que se requieren hoy en día. Nuestros cursos y posgrados están avalados por la Facultad de Medicina de la UBA</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-5"></div>
            <RegularNavbar />
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(Fundacion) : transicionBottomTop(Fundacion);
