import React from 'react'

const TemarioPrehospitalario = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Principios generales de atención en emergencias pre-hospitalarias. </span>
            <span className='fw-light'>Sistemas de Atención Médica de Emergencia. Componentes del sistema de emergencias. Toma de decisiones críticas</span>
            <br /><br />
            <span>Aspectos médico-legales y éticos.  </span>
            <br /><br />
            <span className='fw-light'>Abandono de persona y omisión de auxilio. Rechazo de atención. Órdenes de no reanimar. Indicaciones del médico para medidas de soporte vital  </span>
            <span className='fw-light'>Docentes:</span>
            <br /><br />
            <span>Eventos con víctimas múltiples</span>
            <br /><br />
            <span className='fw-light'>Tipos de eventos con víctimas múltiples. Categorización. Rol del OPE Intermedio</span>

            <br /><br />
            <span>Valoración de la escena</span>
            <br /><br />
            <span className='fw-light'>Valoración de la escena. Seguridad de la escena. Características del llamado. Número de pacientes y suficiencia de recursos</span>
            <br /><br />
            <span>Sistemas corporales</span>
            <br /><br />
            <span className='fw-light'>Localización de órganos y estructuras corporales. Sistema músculo esquelético. Sistema respiratorio. Sistema cardiovascular. Sistema linfático. Sistema nervioso. Sistema digestivo. Sistema endocrino. Sistema renal. Sistema reproductivo</span>

            <br /><br />
            <span>Crecimiento y desarrollo vital</span>
            <br /><br />
            <span className='fw-light'>Lactancia (del nacimiento al primer año). Fisiológico. Psicosocial</span>
            <span className='fw-light'>Lactante mayor (12-36 meses). Fisiológico. Psicosocial</span>
            <span className='fw-light'>Edad preescolar (3-5 años). Fisiológico. Psicosocial</span>
            <span className='fw-light'>Edad escolar (6-12 años). Fisiológico. Psicosocial</span>
            <span className='fw-light'>Adolescencia (13-18 años). Fisiológico. Psicosocial</span>
            <span className='fw-light'>Adultez temprana (19-40 años). Fisiológico. Psicosocial</span>
            <span className='fw-light'>Adultez intermedia (41-60 años). Fisiológico. Psicosocial</span>
            <span className='fw-light'>Adultez mayor (61 años y más). Fisiológico. Psicosocial</span>

            <br /><br />
            <span>Fisiopatología</span>
            <br /><br />
            <span className='fw-light'>La célula. Características. Procesos biológicos. Vulnerabilidad de las células, órganos y sistemas orgánicos</span>

            <br /><br />
            <span>Shock</span>
            <br /><br />
            <span className='fw-light'>Docentes:</span>
            <br /><br />
            <span>Paro cardiorrespiratorio</span>
            <br /><br />
            <span className='fw-light'>Cadena de sobrevivencia. Manejo del paro cardiorrespiratorio. Desfibrilador automático externo. Consideraciones especiales para el uso del desfibrilador automático externo. Dispositivos mecánicos para reanimación cardiopulmonar. Terminación de la reanimación</span>

            <br /><br />
            <span>Manejo de la vía aérea</span>
            <br /><br />
            <span className='fw-light'>Fisiopatología de la vía aérea. Apertura de la vía aérea. Maniobra de inclinación de la cabeza y levantamiento del mentón. Maniobra modificada de mandíbula</span>
            <span className='fw-light'>Dispositivos adyuvantes para la vía aérea. Reglas para el uso de dispositivos adyuvantes para la vía aérea. Cánula oro faríngea. Cánula nasofaríngea</span>
            <span className='fw-light'>Aspiración. Dispositivos para aspiración. Técnicas de aspiración</span>
            <span className='fw-light'>Mantenimiento de una vía aérea abierta: manejo definitivo</span>

            <br /><br />
            <span>Respiración y ventilación artificial</span>
            <br /><br />
            <span className='fw-light'>Fisiopatología. Mecánica respiratoria. Fisiopatología del sistema cardiopulmonar</span>
            <span className='fw-light'>Respiración. Respiración adecuada e inadecuada</span>
            <span className='fw-light'>Ventilación con presión positiva. Técnicas de ventilación artificial</span>
            <span className='fw-light'>Oxigenoterapia. Importancia del oxígeno suplementario. Equipo para oxigenoterapia. Riesgos de la oxigenoterapia. Administración de oxígeno. Cálculo de uso</span>
            <span className='fw-light'>Asistencia para el uso de dispositivos avanzados para la vía aérea. Ventilación del paciente intubado. Asistencia en la intubación en el paciente con traumatismo</span>

            <br /><br />
            <span>Evaluación primaria</span>
            <br /><br />
            <span className='fw-light'>Realización de la evaluación primaria. Motivo de consulta principal. Valoración del ABC. Valoración del estado mental. Determinación de la prioridad</span>

            <br /><br />
            <span>Evaluación secundaria</span>
            <br /><br />
            <span className='fw-light'>Componentes de la evaluación secundaria. Técnicas de valoración</span>
            <span className='fw-light'>Exploración de sistemas corporales. Sistema respiratorio. Sistema cardiovascular Sistema nervioso. Sistema endocrino. Sistema digestivo. Sistema inmunitario. Sistema musculo esquelético</span>
            <span className='fw-light'>Evaluación secundaria del paciente traumatizado. Paciente traumatizado con lesión menor/prioridad baja. Colocación de collar cervical. Paciente traumatizado con lesión grave o politraumatismo/alta prioridad</span>
            <span className='fw-light'>Exploración física detallada. Paciente traumatizado con lesión grave. Paciente traumatizado sin lesiones graves</span>
            <span className='fw-light'>Revaloración. Componentes de la revaloración. Observación de tendencias</span>
            <span className='fw-light'>Toma de decisiones críticas Tendencia de signos vitales. Revaloración de pacientes estables e inestables</span>
            <span className='fw-light'>Pensamiento crítico y toma de decisiones. Diagnóstico y pensamiento crítico del OPE. Como establece el medico un diagnostico</span>

            <br /><br />
            <span>Signos vitales y dispositivos de vigilancia</span>
            <br /><br />
            <span className='fw-light'>Registro de signos vitales. Signos vitales. Pulso. Respiración. Piel. Pupilas. Presión arterial. Temperatura. Saturación de oxígeno. Medidores de glucosa sanguínea</span>

            <br /><br />
            <span>Comunicación y documentación</span>
            <br /><br />
            <span className='fw-light'>Sistemas de comunicación y comunicación por radio. Sistemas de comunicación. Comunicación por radio</span>
            <span className='fw-light'>Informe verbal</span>
            <span className='fw-light'>Comunicación interpersonal. Comunicación en el equipo. Comunicación terapéutica. Comunicación de malas noticias</span>
            <span className='fw-light'>Informe de atención pre hospitalaria. Funciones del informe de atención pre hospitalaria. Documento legal. Elementos del informe de atención pre hospitalaria</span>

            <br /><br />
            <span>Farmacología general</span>
            <br /><br />
            <span className='fw-light'>Información general sobre los fármacos. Nombres de los fármacos. Uso seguro de fármacos y juicio clínico. Autorización para administrar fármacos. Los cinco elementos correctos. Vías de administración. Aspectos relacionados con el peso y la edad. Revaloración y documentación. Fármacos utilizados con frecuencia por los pacientes</span>

            <br /><br />
            <span>Urgencias respiratorias</span>
            <br /><br />
            <span className='fw-light'>Respiración. Respiración adecuada. Respiración inadecuada. Ventilación artificial adecuada e inadecuada</span>
            <span className='fw-light'>Dificultad respiratoria. Presión positiva continua en la vía aérea</span>
            <span className='fw-light'>Trastornos respiratorios. Enfermedad pulmonar obstructiva crónica. Asma. Edema pulmonar. Neumonía. Neumotórax espontáneo. Embolia pulmonar. Epiglotitis. Infecciones respiratorias de origen viral</span>
            <span className='fw-light'>Fármacos para inhalación</span>

            <br /><br />
            <span>Urgencias cardiacas</span>
            <br /><br />
            <span className='fw-light'>Síndrome coronario agudo. Manejo del síndrome coronario agudo</span>
            <span className='fw-light'>Crisis hipertensiva. Taquiarritmias. Bradiarritmias. Aneurisma. Infarto agudo de miocardio. Insuficiencia cardíaca congestiva.</span>

            <br /><br />
            <span>Urgencias diabéticas</span>
            <br /><br />
            <span className='fw-light'>Fisiopatología. Diabetes. Insulina y páncreas. Hipoglucemia. Hiperglucemia. Glucómetros</span>
            <br /><br />
            <span>Reacción alérgica</span>
            <br /><br />
            <span className='fw-light'>Reacciones alérgicas. Diferenciación de la anafilaxia de una reacción alérgica leve</span>

            <br /><br />
            <span>Urgencias por envenenamiento y sobredosificación</span>
            <br /><br />
            <span className='fw-light'>Envenenamiento. Venenos ingeridos. Venenos inhalados. Venenos absorbidos. Venenos inyectados. Centros de información toxicológica. Consumo excesivo de alcohol. Consumo excesivo de sustancias</span>
            <br /><br />
            <span>Urgencias abdominales</span>
            <br /><br />
            <span className='fw-light'>Dolor o malestares abdominales. Valoración del dolor abdominal. Valoración de la escena. Evaluación primaria. Interrogatorio clínico. Exploración física del abdomen. Signos vitales. Malestar abdominal generalizado</span>
            <span className='fw-light'>Afecciones abdominales. Apendicitis. Peritonitis. Colecistitis/colelitiasis. Pancreatitis. Hemorragia gastrointestinal. Aneurisma aórtico abdominal. Hernia. Cólico renal. Patología cardiaca</span>
            <br /><br />
            <span>Urgencias psiquiátricas</span>
            <br /><br />
            <span className='fw-light'>Valoración del paciente con estado mental alterado. Seguridad. Evaluación primaria. Evaluación secundaria. Trastornos psiquiátricos. Causas físicas de la alteración del estado mental. Reacciones por estrés situacional</span>
            <span className='fw-light'>Toma de decisiones críticas ¿Trastorno psiquiátrico o afección médica oculta?</span>
            <span className='fw-light'>Atención urgente de las emergencias psiquiátricas. Suicidio. Pacientes agresivos u hostiles. Fuerza razonable e inmovilización. Traslado a una institución apropiada. Aspectos médico-legales</span>

            <br /><br />
            <span>Unidad 24 Urgencias hematológicas</span>
            <br /><br />
            <span className='fw-light'>Coagulopatías. Anemia</span>

            <br /><br />
            <span>Urgencias urinarias</span>
            <br /><br />
            <span className='fw-light'>Enfermedades del sistema renal. Infecciones de las vías urinarias. Cálculos renales. Pacientes con sondas urinarias. Insuficiencia renal. Pacientes con trasplante renal.</span>

            <br /><br />
            <span>Traumatismos en tejidos blandos</span>
            <br /><br />
            <span className='fw-light'>Traumatismos cerrados. Tipos de heridas cerradas. Atención de urgencia para lesiones cerradas</span>
            <span className='fw-light'>Lesiones. Tipos de lesiones. Cuidado de urgencias para las heridas</span>
            <span className='fw-light'>Tratamiento de tipos específicos de heridas. Tratamiento de abrasiones y laceraciones. Tratamiento de traumatismo penetrante. Tratamiento de heridas por empalamiento de objetos. Tratamiento de las avulsiones. Tratamiento de amputaciones. Tratamiento de lesiones genitales</span>
            <span className='fw-light'>Quemaduras. Clasificación de las quemaduras de acuerdo con el agente y la fuente. Clasificación de las quemaduras con base en su profundidad. Determinación y clasificación de la gravedad de las quemaduras. Tratamiento de tipos específicos de quemaduras</span>
            <span className='fw-light'>Ácidos y álcalis. Quemaduras radiológicas</span>
            <span className='fw-light'>Lesiones eléctricas</span>
            <br /><br />
            <span>Traumatismos torácico y abdominal</span>
            <br /><br />
            <span className='fw-light'>Lesiones torácicas. Lesiones torácicas cerradas. Lesiones torácicas abiertas. Curaciones oclusivas y con válvula unidireccional. Lesiones dentro de la cavidad torácica</span>
            <span className='fw-light'>Lesiones abdominales. Lesiones torácicas cerradas. Lesiones torácicas abiertas</span>

            <br /><br />
            <span>Traumatismo músculo esquelético</span>
            <br /><br />
            <span className='fw-light'>Cinemática. Mecanismos de lesión músculo esquelética. Lesión de los huesos y tejido conectivo. Valoración de lesiones músculo esqueléticas. Uso de férulas.</span>
            <span className='fw-light'>Atención de lesiones específicas. Lesiones de la extremidad superior. Lesiones de las extremidades inferiores.</span>
            <br /><br />
            <span>Traumatismo en la cabeza, cuello y columna vertebral</span>
            <br /><br />
            <span className='fw-light'>Lesiones en el cráneo y el encéfalo. Lesiones de la piel cabelluda. Lesiones óseas en la cabeza. Lesiones encefálicas. Lesiones craneales con objetos empalados. Lesiones en la cara y la mandíbula. Lesiones encefálicas no traumáticas. Escala de coma de Glasgow</span>
            <span className='fw-light'>Heridas en el cuello</span>
            <span className='fw-light'>Lesiones en la columna vertebral. Lesiones potenciales de la columna vertebral y la médula espinal. Inmovilización de la columna</span>

            <br /><br />
            <span>Politraumatismo</span>
            <br /><br />
            <span className='fw-light'>Politraumatismo. Determinación de la gravedad del paciente. Lesiones internas</span>
            <span className='fw-light'>Toma de decisiones críticas. Principios generales del tratamiento del politraumatismo. Calificación del traumatismo</span>

            <br /><br />
            <span>Urgencias ambientales</span>
            <br /><br />
            <span className='fw-light'>Exposición al frío. Mecanismo de pérdida de calor corporal. Hipotermia generalizada. Hipotermia extrema. Lesiones locales por frío</span>
            <span className='fw-light'>Exposición al calor. Efectos del calor sobre el organismo. Paciente con piel húmeda y pálida, y temperatura normal o fría. Paciente con piel caliente (seca o húmeda)</span>
            <span className='fw-light'>Urgencias relacionadas con el agua. Accidentes relacionados con el agua. Hipotermia e hipertermia. Ahogamiento.</span>
            <span className='fw-light'>Mordeduras y picaduras. Mordeduras y picaduras de insectos. Mordeduras de víboras</span>
            <br /><br />
            <span>Urgencias obstétricas y ginecológicas</span>
            <br /><br />
            <span className='fw-light'>Cambios fisiológicos del embarazo. Cambios fisiológicos en el embarazo</span>
            <span className='fw-light'>Trabajo de parto y parto. Etapas del trabajo de parto</span>
            <span className='fw-light'>Valoración de la paciente. Valoración de la mujer en trabajo de parto</span>
            <span className='fw-light'>Parto normal</span>
            <span className='fw-light'>El recién nacido. Valoración del recién nacido. Atención del recién nacido</span>
            <span className='fw-light'>Atención después del parto. Atención a la madre. Alumbramiento de la placenta. Control del sangrado vaginal después del nacimiento</span>
            <span className='fw-light'>Complicaciones del parto. Complicaciones del nacimiento. Urgencias en el embarazo</span>
            <span className='fw-light'>Urgencias ginecológicas. Hemorragia vaginal. Traumatismo a los genitales externos. Violencia sexual</span>

            <br /><br />
            <span>Urgencias pediátricas</span>
            <br /><br />
            <span className='fw-light'>Características del desarrollo de lactantes y niños. Diferencias anatómicas y fisiológicas. Características psicológicas y de la personalidad. Interacción con el paciente pediátrico. Paciente adolescente</span>
            <span className='fw-light'>Valoración del paciente pediátrico. Triangulo de la valoración pediátrica. Valoración de la escena y seguridad pediátricas. Evaluación primaria pediátricas. Evaluación secundaria pediátricas. Exploración física pediátricas. Revaloración pediátrica. Comparación de valoraciones</span>
            <span className='fw-light'>Consideraciones especiales en la atención pediátrica. Mantenimiento de la vía aérea. Posición de la vía aérea. Eliminación de una obstrucción en la vía aérea. Soporte vital básico del lactante y el niño. Suministro de oxígeno suplementario y ventilaciones. Atención para el shock. Protección contra la hipotermia</span>
            <span className='fw-light'>Urgencias médicas pediátricas. Trastornos respiratorios. Otros trastornos pediátricos</span>
            <span className='fw-light'>Urgencias traumatológicas pediátricas. Patrones de lesión. Quemaduras</span>
            <span className='fw-light'>Maltrato y negligencia infantil. Maltrato infantil y abuso sexual. Rol del OPE en casos de sospecha de maltrato o negligencia</span>
            <span className='fw-light'>Lactantes y niños con problemas especiales. Sondas de traqueotomía. Ventiladores artificiales domésticos. Catéteres intravenosos centrales. Sondas de gastrostomía y alimentación gástrica. Derivación ventriculoperitoneal</span>
            <span className='fw-light'>Docentes:</span>

            <br /><br />
            <span>Urgencias geriátricas</span>
            <br /><br />
            <span className='fw-light'>Enfermedad y lesión en pacientes geriátricos. Depresión. Caídas. Cambios de conducta</span>

        </div>
    )
}

export default TemarioPrehospitalario