import React, { useEffect, useState } from 'react'
import notaAlzheimer from '../../Componentes/multimedia/imagenes/portal/temporales/data-fisp/nota-alzheimer.jpg'
import notaPuig from '../../Componentes/multimedia/imagenes/portal/temporales/desde-adentro/puig.jpeg'
import notaDermatitis from '../../Componentes/multimedia/imagenes/portal/temporales/data-fisp/nota-dermatitis.jpg'
import notaHipertensionArterial from '../../Componentes/multimedia/imagenes/portal/temporales/data-fisp/nota-hipertension.jpg'
import DolorLumbar from '../../Componentes/multimedia/imagenes/portal/temporales/data-fisp/dolor-lumbar.jpg'
import Preservativo from '../../Componentes/multimedia/imagenes/portal/temporales/data-fisp/preservativo.jpg'
import Asperger from '../../Componentes/multimedia/imagenes/portal/temporales/data-fisp/asperger.jpg'
import Obesidad from '../../Componentes/multimedia/imagenes/portal/temporales/data-fisp/obesidad.jpg'
import SaludRenal from '../../Componentes/multimedia/imagenes/portal/temporales/data-fisp/salud-renal.jpg'
import Cardiopatias from '../../Componentes/multimedia/imagenes/portal/temporales/data-fisp/cardiopatias.jpg'
import SindromeDown from '../../Componentes/multimedia/imagenes/portal/temporales/data-fisp/sindrome-down.jpg'
import Bipolaridad from '../../Componentes/multimedia/imagenes/portal/temporales/data-fisp/bipolaridad.jpg'
import Autismo from '../../Componentes/multimedia/imagenes/portal/temporales/data-fisp/autismo.jpg'
import NoeliaMarzol from '../../Componentes/multimedia/imagenes/portal/temporales/desde-adentro/noelia-marzol.jpg'
import GastonPauls from '../../Componentes/multimedia/imagenes/portal/temporales/desde-adentro/gaston-pauls.jpg'
import SoyRada from '../../Componentes/multimedia/imagenes/portal/temporales/desde-adentro/soy-rada.jpg'
import NazarenaVelez from '../../Componentes/multimedia/imagenes/portal/temporales/desde-adentro/nazarena-velez.jpg'
import JuliPuente from '../../Componentes/multimedia/imagenes/portal/temporales/desde-adentro/juli-puente.jpg'
import DetectorOvulacion from '../../Componentes/multimedia/imagenes/portal/temporales/tecno-fisp/detector-ovulacion.jpg'
import BombaInsulina from '../../Componentes/multimedia/imagenes/portal/temporales/tecno-fisp/bomba-insulina.jpg'
import AntiRonquidos from '../../Componentes/multimedia/imagenes/portal/temporales/tecno-fisp/anti-ronquidos.jpg'
import CinturonInteligente from '../../Componentes/multimedia/imagenes/portal/temporales/tecno-fisp/cinturon-inteligente.jpg'
import AntiCaida from '../../Componentes/multimedia/imagenes/portal/temporales/tecno-fisp/anti-caida.webp'
import Esgrima from '../../Componentes/multimedia/imagenes/portal/temporales/vida-sana/esgrima.jpg'
import Arqueria from '../../Componentes/multimedia/imagenes/portal/temporales/vida-sana/arqueria.jpg'
import MMA from '../../Componentes/multimedia/imagenes/portal/temporales/vida-sana/mma.jpg'
import Rollers from '../../Componentes/multimedia/imagenes/portal/temporales/vida-sana/rollers.jpg'
import Crossfit from '../../Componentes/multimedia/imagenes/portal/temporales/vida-sana/crossfit.jpg'
import MamaCultiva from '../../Componentes/multimedia/imagenes/portal/temporales/el-nudo/mama-cultiva.jpg'
import EmbarazoTrombofilia from '../../Componentes/multimedia/imagenes/portal/temporales/el-nudo/embarazo-trombofilia.jpg'
import HackeaCerebro from '../../Componentes/multimedia/imagenes/portal/temporales/el-nudo/hackea-cerebro.jpg'
import DeseoPlacerGoce from '../../Componentes/multimedia/imagenes/portal/temporales/el-nudo/deseo-placer-y-goce.jpg'
import MentirasDieta from '../../Componentes/multimedia/imagenes/portal/temporales/el-nudo/mentiras-dieta.jpg'
import CafeSanJuan from '../../Componentes/multimedia/imagenes/portal/temporales/real-food/cafe-san-juan.jpg'
import GlutenMorgen from '../../Componentes/multimedia/imagenes/portal/temporales/real-food/gluten-morgen.jpg'
import GrandDabbang from '../../Componentes/multimedia/imagenes/portal/temporales/real-food/grand-dabbang.jpg'
import RotiseriaMiramar from '../../Componentes/multimedia/imagenes/portal/temporales/real-food/rotiseria-miramar.jpg'
import Mielisima from '../../Componentes/multimedia/imagenes/portal/temporales/real-food/mielisima.jpg'
import Axios from "axios";

export const PortalContext = () => {
    const [isLogged, setIsLogged] = useState(false);

    const convertTittleToUrl = (title)=>{
        const formattedTitle = title.toLowerCase()
        .replace(/[áéíóúüñ]/g, match => ({'á':'a','é':'e','í':'i','ó':'o','ú':'u','ü':'u','ñ':'n'})[match])
        .replace(/[^a-z0-9\s]/g, '')
        .replace(/\s+/g, '-')
        .replace(/^-+|-+$/g, '');
        
        return formattedTitle
    }

    const lastArticles = [
        {
            title: "Alzheimer: La importancia de mantenerse activo física y mentalmente",
            image: notaAlzheimer,
            link: "/portal-de-noticias/alzheimer-la-importancia-de-mantenerse-activo-fisica-y-mentalmente",
            description: "Es una enfermedad neurodegenerativa a través de la cual se van perdiendo las neuronas y que afecta en su mayoría a personas entre 65 y 85 años.",
            category: "datafisp",
            tag: "DataFISP"
        },{
            title: 'Mario Alonso Puig: "La persona que logra ser líder es quien pudo dejar a un lado su ego"',
            image: notaPuig,
            link: "/portal-de-noticias/mario-alonso-puig-la-persona-que-logra-ser-lider-es-quien-pudo-dejar-a-un-lado-su-ego",
            description: "El Dr. Puig es médico especialista en cirugía general y del aparato digestivo, fellow en cirugía por Harvard University Medical School y lleva más de 20 años impartiendo coaching ejecutivo a miembros de las empresas más importantes en el mundo.",
            category: "desde-adentro",
            tag: "Desde Adentro"
        },{
            title: 'Dermatitis atópica: “Hay un porcentaje muy bajo de pacientes que no responden a los tratamientos tópicos"',
            image: notaDermatitis,
            link: "/portal-de-noticias/dermatitis-atopica-hay-un-porcentaje-muy-bajo-de-pacientes-que-no-responden-a-los-tratamientos-topicos",
            description: "La dermatitis atópica es una enfermedad inflamatoria crónica que cursa con brotes y remisiones, es decir, hay momentos donde el paciente va a estar asintomático y momentos donde van a aparecer los brotes.",
            category: "datafisp",
            tag: "DataFISP"
        },{
            title: 'Hipertension arterial: Estrategias para una Vida Cardiaca Plena y Saludable',
            image: notaHipertensionArterial,
            link: "/portal-de-noticias/hipertension-arterial-estrategias-para-una-vida-cardiaca-plena-y-saludable",
            description: "La hipertensión arterial es una enfermedad crónica que se caracteriza por tener elevados los valores de presión arterial por encima del valor normal de forma constante.",
            category: "datafisp",
            tag: "DataFISP"
        },{
            title: "Noelia Marzol: Maternidad, el día que salvó a su hijo y sus proyectos",
            image: NoeliaMarzol,
            link: "/portal-de-noticias/noelia-marzol-maternidad-el-dia-que-salvo-a-su-hijo-y-sus-proyectos",
            description: "En una entrevista exclusiva con Juan Abraham contó cómo combinó su carrera de bailarina con el embarazo, el momento que salvó la vida a su bebé y su día a día maternando.",
            category: "desde-adentro",
            tag: "Desde Adentro"
        },{
            title: "Gastón Pauls: Adicciones, su costado solidario y el empezar a vivir",
            image: GastonPauls,
            link: "/portal-de-noticias/gaston-pauls-adicciones-su-costado-solidario-y-el-empezar-a-vivir",
            description: "El actor compartió cómo fue vivir 20 años con adicciones, la negación frente a la enfermedad y cómo es su día a día como una persona recuperada.",
            category: "desde-adentro",
            tag: "Desde Adentro"
        },{
            title: "Rada: Estrés, la ayuda de la terapia y la búsqueda por sentirse bien",
            image: SoyRada,
            link: "/portal-de-noticias/rada-estres-la-ayuda-de-la-terapia-y-la-busqueda-por-sentirse-bien",
            description: "Juan Abraham tuvo un mano a mano con el actor y productor quién analizó el antes y el después de su ataque de pánico en el escenario, el proceso que atravesó para dejar de fumar, su año cargado de espectáculos y proyectos nuevos y las ganas de focalizar en el presente.",
            category: "desde-adentro",
            tag: "Desde Adentro"
        },{
            title: "¿Sabías que podés detectar los días de ovulación desde tu celular?",
            image: DetectorOvulacion,
            link: "/portal-de-noticias/sabias-que-podes-detectar-los-dias-de-ovulacion-desde-tu-celular",
            description: "Lanzaron el primer rastreador de ovulación para que las mujeres utilicen en sus casas y sepan cuándo es el momento para concebir.",
            category: "tecnofisp",
            tag: "Tecno FISP"
        },{
            title: "¿Sabías que ahora es más fácil controlar los niveles de glucosa?",
            image: BombaInsulina,
            link: "/portal-de-noticias/sabias-que-ahora-es-mas-facil-controlar-los-niveles-de-glucosa",
            description: "Existe un dispositivo, conocido como bomba de insulina, que imita algunas funciones del páncreas.",
            category: "tecnofisp",
            tag: "Tecno FISP"
        },{
            title: "Dormir sin roncar",
            image: AntiRonquidos,
            link: "/portal-de-noticias/dormir-sin-roncar",
            description: "¿Sabías que existe una almohada antironquidos? Motion Pillow detecta los sonidos y cambia de forma de tal manera que la persona mueve su cabeza hacia un costado. De esta forma, las vías respiratorias se liberan y los  ronquidos cesan",
            category: "tecnofisp",
            tag: "Tecno FISP"
        },{
            title: "Esgrima",
            image: Esgrima,
            link: "/portal-de-noticias/esgrima",
            description: "Caro Martínez visitó a Agustín Gusmán, integrante de la selección nacional de esgrima, y blandió la espada para combatir en una disciplina con una gran trayectoria histórica, pero que comenzó como deporte en el siglo XIX.",
            category: "vida-sana",
            tag: "Vida Sana"
        },{
            title: "Arqueria: una disciplina milenaria para la salud fisica y mental",
            image: Arqueria,
            link: "/portal-de-noticias/arqueria",
            description: "Caro Martínez entrenó su concentración y paciencia en una disciplina en donde juega un rol importante la respiración y el equilibrio mental.",
            category: "vida-sana",
            tag: "Vida Sana"
        },{
            title: "Artes marciales mixtas",
            image: MMA,
            link: "/portal-de-noticias/artes-marciales-mixtas",
            description: "Caro Martínez estuvo en United Fight Center para conocer y practicar MMA, un deporte que reúne técnicas de diferentes artes marciales.",
            category: "vida-sana",
            tag: "Vida Sana"
        },{
            title: "Mamá cultiva",
            image: MamaCultiva,
            link: "/portal-de-noticias/mama-cultiva",
            description: "¿Por qué hay sustancias legales e ilegales? ¿Existe realmente esta diferencia? ¿Que implica la legalización? Caro Martínez habló con Valeria Salech, integrante y una de las fundadoras de Mamá Cultiva, una organización sin fines de lucro que nuclea a familias con hijos que utilizan el cannabis para tratamientos médicos.",
            category: "el-nudo",
            tag: "El Nudo"
        },{
            title: "Embarazo y trombofilia: El diagnóstico a tiempo",
            image: EmbarazoTrombofilia,
            link: "/portal-de-noticias/embarazo-y-trombofilia-el-diagnostico-a-tiempo",
            description: "¿Cómo se transita un embarazo de riesgo? En los últimos años, la trombofilia comenzó a ser conocida a partir del testimonio de famosas, sin embargo, es un trastorno que padecen muchas mujeres en Argentina. Caro Martínez habló con Mariana Litta, miembro de Trombofilia, una red que se creó hace 19 años para contener e informar a embarazadas.",
            category: "el-nudo",
            tag: "El Nudo"
        },{
            title: "Hackea tu cerebro",
            image: HackeaCerebro,
            link: "/portal-de-noticias/hackea-tu-cerebro",
            description: "¿Cómo se alcanzan las metas? ¿Cómo desarrollar una habilidad? ¿Es posible mejorar la capacidad de aprendizaje? Caro Martínez habló con Nicolás Fernández Miranda, profesor, divulgador científico y autor del libro “Hackeá tu cerebro”, para entender cómo se puede utilizar la neurociencia para desafiar límites autoimpuestos, la necesidad de no encasillarse en un solo rol porque el entorno así lo dispuso y la importancia de enfocarse en una actividad a la vez.",
            category: "el-nudo",
            tag: "El Nudo"
        },{
            title: "Café San Juan: pastas únicas en el barrio de San Telmo",
            image: CafeSanJuan,
            link: "/portal-de-noticias/cafe-san-juan-pastas-unicas-en-el-barrio-de-san-telmo",
            description: "Zahira Díaz visitó el barrio más tradicional de Buenos Aires para conocer Café San Juan, un restaurante que se caracteriza por elaborar comidas con ingredientes naturales, frescos y sin aditivos.",
            category: "real-food",
            tag: "Real Food"
        },{
            title: "Gluten Morgen: 100% masa madre",
            image: GlutenMorgen,
            link: "/portal-de-noticias/gluten-morgen-cien-por-ciento-masa-madre",
            description: "Zahira Díaz visitó a Ramón Garriga, el influencer por excelencia de productos hechos a base de masa madre, el cultivo de levaduras que hizo furor en la pandemia y nos encontró a todos intentando hacerla.",
            category: "real-food",
            tag: "Real Food"
        },{
            title: "Grand Dabbang",
            image: GrandDabbang,
            link: "/portal-de-noticias/grand-dabbang-un-recorrido-gastronomico-por-el-mundo",
            description: "¿Es posible estar en varios lugares al mismo tiempo? Zahira Díaz lo logró a través de su paladar recorriendo sabores autóctonos, asiáticos y de latinoamérica.",
            category: "real-food",
            tag: "Real Food"
        },{
            title: "Dolor lumbar",
            image: DolorLumbar,
            link: "/portal-de-noticias/dolor-lumbar",
            description: "¿Sabías que la lumbalgia puede ser multicausal? El Dr. Javier Furman explica que es muy importante tener un tratamiento integral para mejorar la calidad de vida",
            category: "datafisp",
            tag: "DataFISP"
        },{
            title: "El preservativo: único método para la prevención de ITS",
            image: Preservativo,
            link: "/portal-de-noticias/el-preservativo-unico-metodo-para-la-prevencion-de-its",
            description: "La AHF Argentina realizó una encuesta a 6814 personas de las cuales solo el 17% respondió que utiliza el preservativo en sus relaciones sexuales.",
            category: "datafisp",
            tag: "DataFISP"
        },{
            title: "Asperger: Un trastorno del espectro autista",
            image: Asperger,
            link: "/portal-de-noticias/asperger-un-trastorno-del-espectro-autista",
            description: "El asperger es un trastorno del espectro autista y se estima que 44 millones de personas en el mundo tienen este diagnóstico.",
            category: "datafisp",
            tag: "DataFISP"
        },{
            title: "Obesidad: Una enfermedad que contrae otras",
            image: Obesidad,
            link: "/portal-de-noticias/obesidad-una-enfermedad-que-contrae-otras",
            description: "La obesidad es el sobrecrecimiento del tejido adiposo en el cuerpo y es una enfermedad que contrae complicaciones ya que puede generar más de 200 problemas de salud.",
            category: "datafisp",
            tag: "DataFISP"
        },{
            title: "Salud renal: cómo prevenir enfermedades",
            image: SaludRenal,
            link: "/portal-de-noticias/salud-renal-como-prevenir-enfermedades",
            description: "Las funciones del riñón son tan diversas como importantes, pero la función principal es formar la calidad y concentración de la orina.",
            category: "datafisp",
            tag: "DataFISP"
        },{
            title: "Cardiopatías congénitas: qué son y cómo prevenirlas",
            image: Cardiopatias,
            link: "/portal-de-noticias/cardiopatias-congenitas-que-son-y-como-prevenirlas",
            description: "Las cardiopatías congénitas son enfermedades que afectan el corazón y que se producen en el momento de la gestación.",
            category: "datafisp",
            tag: "DataFISP"
        },{
            title: "Síndrome de Down: hablemos de alteración genética, no de enfermedad",
            image: SindromeDown,
            link: "/portal-de-noticias/sindrome-de-down-hablemos-de-alteracion-genetica-no-de-enfermedad",
            description: "El Síndrome de Down es una alteración genética del cromosoma 21, del cual tiene una parcial o total; es por eso que esta condición también se denomina trisomía del par 21",
            category: "datafisp",
            tag: "DataFISP"
        },{
            title: "Autismo",
            image: Autismo,
            link: "/portal-de-noticias/autismo",
            description: "El autismo es un trastorno del neurodesarrollo que afecta de manera diferente a cada persona, es por eso que no todos reciben el mismo tratamiento por parte del equipo médico.",
            category: "datafisp",
            tag: "DataFISP"
        },{
            title: "Deseo, placer y goce",
            image: DeseoPlacerGoce,
            link: "/portal-de-noticias/deseo-placer-y-goce",
            description: "¿Qué es el deseo? ¿Por qué nos reprimimos? ¿Qué es la educación sexual? La Lic. en Sexología, Mariana Kersz ahonda sobre la secualidad para entender de dónde surge el placer",
            category: "el-nudo",
            tag: "El Nudo"
        },{
            title: "Los beneficios de andar en rollers",
            image: Rollers,
            link: "/portal-de-noticias/los-beneficios-de-andar-en-rollers",
            description: "¿Qué hay que tener en cuenta antes de salir a andar en rollers? ¿Cómo puede ayudarnos a mejorar nuestra salud?",
            category: "vida-sana",
            tag: "Vida Sana"
        },{
            title: "Nazarena Vélez: Autoestima, el rol de su familia y aprender a valorarse",
            image: NazarenaVelez,
            link: "/portal-de-noticias/nazarena-velez-autoestima-el-rol-de-su-familia-y-aprender-a-valorarse",
            description: "En una entrevista exclusiva contó cómo los problemas de autoestima, de alimentación y la sobreexigencia laboral la llevaron a ser adicta a las anfetaminas, cómo fue la abstinencia y el tratamiento, y el apoyo incondicional de su familia para superar la depresión.",
            category: "desde-adentro",
            tag: "Desde Adentro"
        },{
            title: "Un cinturón inteligente ayuda a personas no videntes",
            image: CinturonInteligente,
            link: "/portal-de-noticias/un-cinturon-inteligente-ayuda-a-personas-no-videntes",
            description: "GUIDi es un dispositivo que detecta obstáculos a través de vibraciones de modo que funciona como un sistema de navegación que detecta no sólo obstáculos estáticos, sino también personas en movimiento y permite establecer destinos específicos y ser guiado hasta ellos.",
            category: "tecnofisp",
            tag: "TecnoFISP"
        },{
            title: "Rotisería Miramar: Un viaje a España en el medio de Buenos Aires",
            image: RotiseriaMiramar,
            link: "/portal-de-noticias/rotiseria-miramar-un-viaje-a-españa-en-el-medio-de-buenos-aires",
            description: "Zahira Díaz pasó por el bodegón del barrio San Cristóbal para probar comida clásica española, donde cada bocado fue un viaje al otro lado del océano.",
            category: "real-food",
            tag: "Real Food"
        },{
            title: "CrossFit: Entrenamiento funcional a tu vida diaria",
            image: Crossfit,
            link: "/portal-de-noticias/crossfit-entrenamiento-funcional-a-tu-vida-diaria",
            description: "¿Qué es el crossfit? Son ejercicios de alta intensidad que combinan diferentes disciplinas: levantamiento olímpico, gimnasia artística, atletismo y aparatos.",
            category: "vida-sana",
            tag: "Vida Sana"
        },{
            title: "La Mielísima: La magia está en cada gota de miel",
            image: Mielisima,
            link: "/portal-de-noticias/la-mielisima-la-magia-esta-en-cada-gota-de-miel",
            description: "Zahira fue por uno de los productos dulces más nobles que existen. En forma líquida o sólida, la miel demostró que puede ser consumida de diferentes maneras.",
            category: "real-food",
            tag: "Real Food"
        },{
            title: "¿Sabías que existe una tecnología que detecta y protege a los adultos de las caídas?",
            image: AntiCaida,
            link: "/portal-de-noticias/sabias-que-existe-una-tecnologia-que-detecta-y-protege-a-los-adultos-de-las-caidas",
            description: "Existe una nueva tecnología para prevenir lesiones frente a un accidente doméstico. Se trata de un cinturón inflable que expulsa una bolsa de aire de tal manera que se activa antes de que la persona toque el suelo.",
            category: "tecnofisp",
            tag: "TecnoFISP"
        },{
            title: "Juli Puente, la influencer del fitness en el mundo digital",
            image: JuliPuente,
            link: "/portal-de-noticias/juli-puente-la-influencer-del-fitness-en-el-mundo-digital",
            description: "En una entrevista exclusiva, Julieta Puente contó cómo superó sus problemas de alimentación cuando era chica, de la importancia de tener objetivos claros y de cómo hoy puede trabajar en periodismo y a la vez seguir entrenando.",
            category: "desde-adentro",
            tag: "Desde Adentro"
        },{
            title: "Las mentiras de la dieta",
            image: MentirasDieta,
            link: "/portal-de-noticias/las-mentiras-de-la-dieta",
            description: "¿Alguna vez fuiste al nutricionista? ¿Cómo te fue? ¿Sentís que pudiste cambiar tus hábitos? La Dra. Elena Pastor Manfredi afirmó que una buena nutrición no es seguir una dieta ya que, muchas veces, resulta engañosa.",
            category: "el-nudo",
            tag: "El Nudo"
        }
        ]

    return {
        lastArticles,
        isLogged,
        setIsLogged,
        convertTittleToUrl
    };
};

export default PortalContext