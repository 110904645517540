import React from 'react'

const TemariosGestionEmpresasSalud = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Módulo 1: Reseña histórica de la medicina y de las políticas sanitarias</span>
            <ul>
                <li className="fw-light">Principales avances médicos</li>
                <li className="fw-light">La transición de la cosmovisión mística a la lógica</li>
                <li className="fw-light">Historia de la salud pública</li>
            </ul>

            <span>Módulo 2: La organización</span>
            <ul>
                <li className="fw-light">Definición</li>
                <li className="fw-light">Tipos de organización</li>
                <li className="fw-light">Características de las organizaciones</li>
                <li className="fw-light">Definición/diferencias: paciente-cliente-usuario</li>
            </ul>

            <span>Módulo 3: Gestión y estrategia</span>
            <ul>
                <li className="fw-light">Definiciones</li>
                <li className="fw-light">Cadena de la gestión</li>
                <li className="fw-light">Misión</li>
                <li className="fw-light">Visión. Visión compartida</li>
                <li className="fw-light">Las teorías de Mathus</li>
            </ul>

            <span>Módulo 4: La gestión de servicios de salud</span>
            <ul>
                <li className="fw-light">Conceptos de gestión y planificación</li>
                <li className="fw-light">Gestión sanitaria</li>
                <li className="fw-light">Cadena de la gestión</li>
                <li className="fw-light">Gestión por procesos</li>
                <li className="fw-light">Gestión de la demanda</li>
                <li className="fw-light">Gestión de pacientes</li>
                <li className="fw-light">Gestión de la calidad</li>
                <li className="fw-light">El decálogo de la gestión</li>
                <li className="fw-light">Gestión de recursos humanos</li>
            </ul>

            <span>Módulo 5: Sistemas de salud.</span>
            <ul>
                <li className="fw-light">Sistemas sanitarios.</li>
                <li className="fw-light">Financiación.</li>
                <li className="fw-light">Proveedores de salud.</li>
                <li className="fw-light">Sistema nacional de salud.</li>
                <li className="fw-light">Nuevas formas de gestión.</li>
            </ul>

            <span>Módulo 6: Costos en las organizaciones de salud</span>
            <ul>
                <li className="fw-light">Concepto de salud como un bien</li>
                <li className="fw-light">Leyes de mercado</li>
                <li className="fw-light">Centros de costos. Recuperación de costos</li>
                <li className="fw-light">Costos ABC. Ventajas y desventajas</li>
            </ul>

            <span>Módulo 7: Gestión de problemas</span>
            <ul>
                <li className="fw-light">Tormenta de ideas</li>
                <li className="fw-light">Análisis FODA</li>
                <li className="fw-light">Diagrama de causa y efecto</li>
                <li className="fw-light">Diagrama de Pareto</li>
                <li className="fw-light">Diagrama de dispersión</li>
                <li className="fw-light">Hoja de inspección</li>
                <li className="fw-light">Diagrama de flujo</li>
            </ul>

            <span>Módulo 8: Gestión por procesos</span>
            <ul>
                <li className="fw-light">Definición</li>
                <li className="fw-light">Gestión de los procesos asistenciales hospitalarios</li>
                <li className="fw-light">Ciclo PDCA</li>
            </ul>

            <span>Módulo 9: Herramientas de gestión</span>
            <ul>
                <li className="fw-light">Marco lógico</li>
                <li className="fw-light">Matriz de análisis</li>
                <li className="fw-light">Diagrama de Gantt</li>
                <li className="fw-light">Cuadro de mando integral</li>
                <li className="fw-light">Árbol de problemas</li>
                <li className="fw-light">Árbol de objetivos</li>
                <li className="fw-light">Manuales de procedimientos</li>
            </ul>

            <span>Módulo 10: Control de gestión y elaboración de indicadores</span>
            <ul>
                <li className="fw-light">Definición</li>
                <li className="fw-light">Tipos de indicadores y estándares de la atención médica</li>
                <li className="fw-light">Auditoría médica.</li>
                <li className="fw-light">Nomencladores</li>
            </ul>

            <span>Módulo 11: La gestión de la calidad</span>
            <ul>
                <li className="fw-light">Definición</li>
                <li className="fw-light">Eficacia</li>
                <li className="fw-light">Eficiencia</li>
                <li className="fw-light">Equidad</li>
                <li className="fw-light">Universalidad</li>
                <li className="fw-light">Programa de gestión de la calidad</li>
                <li className="fw-light">Ciclo de la mejora</li>
                <li className="fw-light">Gestión de pacientes</li>
                <li className="fw-light">Gestión de la demanda</li>
            </ul>
        </div>
    )
}

export default TemariosGestionEmpresasSalud