import React from 'react'

const TemarioToxinaBotulinica = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Introducción. Conceptos básicos de toxina botulínica tipo A. La consulta médica.</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Toxina botulínica, su principio activo</li>
                <li className='fw-light'>Estructura y serotipos</li>
                <li className='fw-light'>Mecanismo de acción</li>
                <li className='fw-light'>Duración del efecto</li>
                <li className='fw-light'>Preparados comerciales y dosificación</li>
                <li className='fw-light'>Contraindicaciones</li>
                <li className='fw-light'>Efectos secundarios</li>
                <li className='fw-light'>Toxicidad</li>
                <li className='fw-light'>Efectos terapéuticos</li>
                <li className='fw-light'>Efectos sistémicos</li>
                <li className='fw-light'>Breve resumen histórico</li>
                <li className='fw-light'>La consulta medica</li>
                <li className='fw-light'>Documentación y organización</li>
                <li className='fw-light'>Habilidades y aspectos relacionados con el trato hacia los pacientes</li>
                <li className='fw-light'>Empatía – asertividad – juicio critico</li>
            </ul>

            <span>Musculatura Facial</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Anatomía Facial: estudio detallado de los músculos principales del rostro, su ubicación, función y relación con otras estructuras faciales.</li>
                <li className='fw-light'>Expresión facial: compresión de cómo los músculos faciales contribuyen a la expresión facial normal y cómo se relacionan con las emociones</li>
                <li className='fw-light'>Evaluación de la musculatura facial: tono muscular, simetría, detectar desequilibrios o tensiones musculares</li>
            </ul>

            <span>Relación entre estética y salud mental</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Percepciones de la apariencia física pueden afectar la autoestima, el bienestar social y las relaciones interpersonales</li>
                <li className='fw-light'>Evaluación psicológica en pacientes estéticos</li>
                <li className='fw-light'>Comunicación efectiva y empatía</li>
                <li className='fw-light'>Papel de la terapia psicológica: exploración de diferentes modalidades terapéuticas</li>
                <li className='fw-light'>Estrategias de afrontamiento y autocuidado</li>
                <li className='fw-light'>Consideraciones éticas relacionadas con los tratamientos estéticos y el papel del profesional en el cuidado integral físico y mental de los pacientes</li>
            </ul>

            <span>Tratamientos estéticos con Toxina botulínica Tipo A en tercio superior del rostro</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Anatomía del tercio superior: descripción de estructuras faciales relevantes, entrecejo, frente, peri orbiculares y ojos</li>
                <li className='fw-light'>Arrugas de expresión en cada zona</li>
                <li className='fw-light'>Acción de toxina en cada área</li>
                <li className='fw-light'>Indicaciones y contraindicaciones de toxina botulínica tipo A en tercio superior</li>
                <li className='fw-light'>Procedimiento de aplicación: dosis y puntos de aplicación. Técnicas</li>
                <li className='fw-light'>Resultados esperados</li>
                <li className='fw-light'>Cuidados pre y post tratamiento</li>
            </ul>

            <span>Tratamientos estéticos con Toxina botulínica tipo A en tercio inferior de rostro y cuello</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Anatomía del tercio inferior del rostro y cuello, estructuras faciales relevantes en esta área como músculos de la mandíbula, pliegues nasolabiales y músculos del cuello</li>
                <li className='fw-light'>Arrugas dinámicas del tercio inferior del rostro y cuello</li>
                <li className='fw-light'>Mecanismo de acción de la toxina botulínica tipo A para relajar los músculos faciales y reducir las arrugas dinámicas</li>
                <li className='fw-light'>Indicaciones y contraindicaciones sumado a situaciones en las que no se recomienda su aplicación</li>
                <li className='fw-light'>Procedimientos de aplicación: descripción paso a paso incluyendo dosis recomendadas y técnica de inyección</li>
                <li className='fw-light'>Resultados esperados</li>
                <li className='fw-light'>Cuidados pretratamientos y postratamientos</li>
            </ul>

            <span>Tratamiento de Hiperhidrosis con toxina botulínica tipo A</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Hiperhidrosis: definición y descripción</li>
                <li className='fw-light'>Indicaciones para la aplicación de toxina botulínica tipo A en pacientes adecuados para recibir tratamiento</li>
                <li className='fw-light'>Descripción de la hiperhidrosis: áreas más afectadas como axilas, palmas de las manos y plantas de los pies</li>
                <li className='fw-light'>Mecanismo de acción de la toxina botulínica tipo A en hiperhidrosis: explicación de cómo actúa para bloquear de forma temporal las glándulas sudoríparas y así reducir la producción excesiva de sudor.</li>
                <li className='fw-light'>Procedimiento de aplicación de toxina botulínica tipo A en hiperhidrosis en áreas afectadas incluyendo dosis, puntos y técnicas e inyección</li>
                <li className='fw-light'>Resultados esperados: discusión sobre los resultados después del tratamiento, incluyendo la reducción significativa de la sudoración excesiva y la mejora en la calidad de vida de los pacientes</li>
                <li className='fw-light'>Cuidados post tratamiento: recomendaciones para el cuidado posterior, posibles efectos secundarios y cómo manejarlos</li>
            </ul>

            <span>Tratamientos complementarios e integrales a Toxina botulínica tipo A para el beneficio del paciente</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Peelings Mecánicos/Químicos</li>
                <li className='fw-light'>Electroporación</li>
                <li className='fw-light'>Crioterapia</li>
                <li className='fw-light'>Radiofrecuencia</li>
                <li className='fw-light'>Radiofrecuencia fraccionada</li>
                <li className='fw-light'>Rellenos y Fillers</li>
                <li className='fw-light'>Dermapen</li>
                <li className='fw-light'>Carboxiterapia</li>
                <li className='fw-light'>Mesoterapia Facial</li>
            </ul>
        </div>
    )
}

export default TemarioToxinaBotulinica