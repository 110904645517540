import React, { useEffect, useRef, useState } from 'react';
import Imagen from '../../multimedia/imagenes/servicios-tecnologicos/consultoria.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar';
import SideNav from '../../Navbars/SideNav';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';
import { Link } from 'react-router-dom';
import IMG_EvaluacionEstrategias from '../../multimedia/imagenes/servicios-tecnologicos/consultoria-tecnologia/evaluacion-estrategias.jpg';
import IMG_IntegracionSistemas from '../../multimedia/imagenes/servicios-tecnologicos/consultoria-tecnologia/integracion-sistemas.jpg';
import IMG_CumplimientoNormativa from '../../multimedia/imagenes/servicios-tecnologicos/consultoria-tecnologia/cumplimiento-normativa.jpg';
import IMG_OptimizacionProcesos from '../../multimedia/imagenes/servicios-tecnologicos/consultoria-tecnologia/optimizacion-procesos.jpg';
import IMG_Telemedicina from '../../multimedia/imagenes/servicios-tecnologicos/consultoria-tecnologia/telemedicina.jpg';

const ServiciosOfrecidos = [{
    servicio: "Evaluación y Estrategia Tecnológica",
    descripcion: "Realizamos una evaluación exhaustiva de las necesidades de su empresa y diseñamos una estrategia tecnológica que maximice la eficiencia, la calidad y la seguridad en su operación. Esto incluye la selección de sistemas de información médica, soluciones de telemedicina, gestión de datos de pacientes y más.",
    imagen: IMG_EvaluacionEstrategias,
},{
    servicio: "Integración de Sistemas",
    descripcion: "Ayudamos a integrar sistemas de salud heterogéneos para garantizar una comunicación fluida entre plataformas y dispositivos. Esto mejora la coordinación del cuidado y reduce los errores médicos.",
    imagen: IMG_IntegracionSistemas,
},{
    servicio: "Cumplimiento Normativo",
    descripcion: "Mantenerse al día con las regulaciones en constante cambio en el sector de la salud es esencial. Le ayudamos a cumplir con los estándares de privacidad de datos, seguridad de la información y regulaciones específicas del sector.",
    imagen: IMG_CumplimientoNormativa,
},{
    servicio: "Optimización de Procesos",
    descripcion: "Identificamos áreas donde la tecnología puede optimizar sus procesos internos, desde la gestión de citas hasta la logística de suministros médicos.",
    imagen: IMG_OptimizacionProcesos,
},{
    servicio: "Telemedicina y Salud Digital",
    descripcion: "Le ayudamos a implementar soluciones de telemedicina, aplicaciones móviles de salud y otras tecnologías digitales que mejoren la experiencia de sus pacientes y amplíen su alcance.",
    imagen: IMG_Telemedicina,
}]

const ConsultoriaTecnologias = () => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        const progressBar = document.getElementById('progress-bar');
        const updateProgressBar = () => {
            const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
            const progress = (window.scrollY / totalHeight) * 100;
            progressBar.style.width = progress + "%";
        };
        window.addEventListener("scroll", updateProgressBar);
        return () => {
            window.removeEventListener("scroll", updateProgressBar);
        };
    }, []);

    return (
        <>
            <main className='servicio-tecnologico-detalle' id="main-element">
                <SideNav />
                <div className="servicio-main-image container-fluid px-0">
                    <img id='consultoria-mobile' className='w-100' src={Imagen} alt="Consultoría en Tecnologías de la Salud para Empresas" />
                </div>
                <div className="container-servicio-content pt-0 pb-5 my-sm-5 mt-0 px-2 px-sm-5">
                    <div className="container-xxl">
                        <div className="sticky-title" id='sticky-title'>
                            <h1 className='poppins'>Consultoría en Tecnologías de la Salud para Empresas</h1>
                            <div id="progress-bar"></div>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 mt-5'>En un mundo cada vez más interconectado y centrado en la tecnología, la industria de la salud se encuentra en constante evolución. Las tecnologías de la salud han revolucionado la forma en que abordamos el cuidado de la salud, desde la gestión de registros médicos hasta la telemedicina y la optimización de procesos hospitalarios. En este contexto, ofrecemos nuestros servicios de consultoría en tecnologías de la salud a empresas de todos los sectores.</p>
                        <p className='parrafo-servicio fs-5 pb-2'><span>Nuestra Experiencia:</span></p>
                        <p className='parrafo-servicio fs-5 pb-2'>Contamos con un equipo de expertos altamente cualificados y con amplia experiencia en el campo de las tecnologías de la salud. Hemos trabajado con una variedad de organizaciones, desde hospitales y clínicas hasta empresas de seguros de salud, laboratorios farmacéuticos y proveedores de dispositivos médicos. Nuestra profunda comprensión de los desafíos y oportunidades en este sector nos permite ofrecer soluciones adaptadas a las necesidades específicas de cada cliente.</p>
                        <p className='parrafo-servicio fs-5 pb-2'><span>Servicios Ofrecidos:</span></p>
                        <div className="d-flex flex-column gap-4 mb-5">
                            {
                                ServiciosOfrecidos.map((servicio,index)=>{
                                    return(
                                        <div className="d-flex box-shadow border componente-clave" key={index}>
                                            <div className="bg-dark col-md-4 col-12">
                                                <img className='w-100 h-100 object-fit-cover ratio-2x1' src={servicio.imagen} alt={servicio.servicio} />
                                            </div>
                                            <div className="col-md-8 col-12 ps-md-4 px-4">
                                                <p className='parrafo-servicio my-4 fs-5 d-flex flex-column gap-1 justify-content-center'>
                                                    <span className='pb-2 me-4 border-bottom'>{servicio.servicio}</span>
                                                    <p className='fw-normal pt-2 mb-0'>{servicio.descripcion}</p>
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 text-center'><span>Beneficios de nuestra consultoría</span></p>
                        <div className='parrafo-servicio fs-5 p-3 px-sm-5 py-sm-4 mb-5 rounded-4 bg-light box-shadow-medium'>
                                <ul className='px-md-5 px-0 mx-md-5 mx-0 mb-0'>
                                    <li className='fw-medium px-2 py-2'>Mayor eficiencia operativa.</li>
                                    <li className='fw-medium px-2 py-2'>Mejora de la calidad del cuidado de la salud.</li>
                                    <li className='fw-medium px-2 py-2'>Cumplimiento normativo garantizado.</li>
                                    <li className='fw-medium px-2 py-2'>Reducción de costos a largo plazo.</li>
                                    <li className='fw-medium px-2 py-2'>Adaptación a las últimas tendencias tecnológicas en salud.</li>
                                </ul>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2'>En un entorno empresarial cada vez más competitivo, la adopción de tecnologías de la salud efectivas es fundamental para el éxito y el crecimiento sostenible. Nuestra consultoría le ayudará a aprovechar al máximo las oportunidades que brinda la tecnología, mejorando sus operaciones y la atención que brinda a sus clientes.</p>
                        <p className='parrafo-servicio fs-5 pb-2'>Si está listo para llevar su empresa al siguiente nivel en el mundo de las tecnologías de la salud, no dude en ponerse en contacto con nosotros. Estamos comprometidos a ayudarle a alcanzar sus objetivos de manera eficaz y rentable. ¡Contáctenos hoy mismo para iniciar esta emocionante transformación!</p>
                        <Link to='/servicios/contacto/'>
                            <button className='py-3 position-relative text-white text-shadow d-flex align-items-center justify-content-center mb-5 rounded-4'>
                                CONSULTA TU PRESUPUESTO
                                <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </button>
                        </Link>                        
                    </div>
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default transicionBottomTop(ConsultoriaTecnologias);