import React, { useEffect, useRef, useState } from 'react';
import Imagen from '../../multimedia/imagenes/servicios-tecnologicos/implementacion-tecnologia.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar';
import SideNav from '../../Navbars/SideNav';
import { Link } from 'react-router-dom';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';
import IMG_RegistrosElectronicos from '../../multimedia/imagenes/servicios-tecnologicos/implementacion-tecnologias/registros-electronicos.jpg';
import IMG_Telemedicina from '../../multimedia/imagenes/servicios-tecnologicos/implementacion-tecnologias/telemedicina.jpg';
import IMG_Interoperabilidad from '../../multimedia/imagenes/servicios-tecnologicos/implementacion-tecnologias/interoperabilidad.jpg';
import IMG_AnalisisDatos from '../../multimedia/imagenes/servicios-tecnologicos/implementacion-tecnologias/analisis-datos.jpg';
import IMG_CapacitacionSoporte from '../../multimedia/imagenes/servicios-tecnologicos/implementacion-tecnologias/capacitacion-soporte.jpg';

const ServiciosOfrecidos = [{
    servicio: "Implementación de Registros Médicos Electrónicos (EMR)",
    descripcion: "Ayudamos a las entidades gubernamentales a migrar de registros en papel a sistemas EMR avanzados. Esto no solo reduce el consumo de papel, sino que también mejora la eficiencia en la gestión de datos médicos y la calidad de la atención.",
    imagen: IMG_RegistrosElectronicos,
},{
    servicio: "Telemedicina y Salud Digital",
    descripcion: "Facilitamos la implementación de soluciones de telemedicina que conectan a pacientes y médicos, lo que permite una atención médica remota efectiva y la expansión de la atención a áreas remotas.",
    imagen: IMG_Telemedicina,
},{
    servicio: "Interoperabilidad de Sistemas",
    descripcion: "Garantizamos que los sistemas de salud del gobierno puedan comunicarse eficazmente entre sí, lo que facilita el intercambio de datos y la coordinación de la atención entre diferentes instalaciones y proveedores de atención médica.",
    imagen: IMG_Interoperabilidad,
},{
    servicio: "Análisis de Datos de Salud",
    descripcion: "Utilizamos análisis avanzados de datos de salud para ayudar a los gobiernos a tomar decisiones informadas y a mejorar la gestión de la salud pública, desde la prevención de enfermedades hasta la asignación de recursos.",
    imagen: IMG_AnalisisDatos,
},{
    servicio: "Capacitación y Soporte",
    descripcion: "Ofrecemos capacitación integral y un sólido programa de soporte para garantizar que los profesionales de la salud y los funcionarios gubernamentales puedan aprovechar al máximo las nuevas tecnologías implementadas.",
    imagen: IMG_CapacitacionSoporte,
}]

const FirmaDigital = () => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        const progressBar = document.getElementById('progress-bar');
        const updateProgressBar = () => {
            const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
            const progress = (window.scrollY / totalHeight) * 100;
            progressBar.style.width = progress + "%";
        };
        window.addEventListener("scroll", updateProgressBar);
        return () => {
            window.removeEventListener("scroll", updateProgressBar);
        };
    }, []);

    return (
        <>
            <main className='servicio-tecnologico-detalle' id="main-element">
                <SideNav />
                <div className="servicio-main-image container-fluid px-0">
                    <img className='w-100' src={Imagen} alt="Implementación de tecnologías" />
                </div>
                <div className="container-servicio-content pt-0 pb-5 my-sm-5 mt-0 px-2 px-sm-5">
                    <div className="container-xxl">
                        <div className="sticky-title" id='sticky-title'>
                            <h1 className='poppins'>Implementación de tecnologías</h1>
                            <div id="progress-bar"></div>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 mt-5'><span>Transformando la Salud Pública a través de la Implementación de Tecnologías Avanzadas</span></p>
                        <p className='parrafo-servicio fs-5 pb-2'>En el contexto de la evolución constante del sector de la salud y la creciente demanda de servicios de atención médica eficaces y accesibles, nos complace presentar nuestro servicio de Implementación de Tecnologías de Salud en Gobiernos.</p>
                        <p className='parrafo-servicio fs-5 pb-2'>En FISP, hemos asumido el compromiso de ayudar a las administraciones gubernamentales a avanzar hacia un futuro de atención médica más eficiente, transparente y centrada en el paciente, aprovechando las últimas innovaciones tecnológicas.</p>
                        <p className='parrafo-servicio fs-5 pb-2'><span>Nuestra Experiencia:</span></p>
                        <p className='parrafo-servicio fs-5 pb-2'>Con una sólida trayectoria en la implementación exitosa de soluciones de tecnología de la salud en todo el mundo, FISP se ha convertido en un socio de confianza para gobiernos que buscan transformar sus sistemas de atención médica. Nuestra experiencia abarca desde la gestión de registros médicos electrónicos hasta la telemedicina y la optimización de procesos hospitalarios. </p>
                        <p className='parrafo-servicio fs-5 pb-2'>Conocemos los desafíos únicos que enfrentan las instituciones gubernamentales de salud y estamos listos para abordarlos de manera eficaz.</p>
                        <p className='parrafo-servicio fs-5 pb-2'><span>Nuestros Servicios:</span></p>
                        <div className="d-flex flex-column gap-4 mb-5">
                            {
                                ServiciosOfrecidos.map((servicio,index)=>{
                                    return(
                                        <div className="d-flex box-shadow border componente-clave" key={index}>
                                            <div className="bg-dark col-md-4 col-12">
                                                <img className='w-100 h-100 object-fit-cover ratio-2x1 border' src={servicio.imagen} alt={servicio.servicio} />
                                            </div>
                                            <div className="col-md-8 col-12 ps-md-4 px-4">
                                                <p className='parrafo-servicio my-4 fs-5 d-flex flex-column gap-1 justify-content-center'>
                                                    <span className='pb-2 me-4 border-bottom'>{servicio.servicio}</span>
                                                    <p className='fw-normal pt-2 mb-0'>{servicio.descripcion}</p>
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 text-center'><span>Beneficios de nuestra solución:</span></p>
                        <div className='parrafo-servicio fs-5 p-3 px-sm-5 py-sm-4 mb-5 rounded-4 bg-light box-shadow-medium'>
                                <ul className='px-md-5 px-0 mx-md-5 mx-0 mb-0'>
                                    <li className='fw-medium px-2 py-2'>Mayor eficiencia en la atención médica.</li>
                                    <li className='fw-medium px-2 py-2'>Mejora en la calidad y la precisión de los diagnósticos.</li>
                                    <li className='fw-medium px-2 py-2'>Reducción de costos operativos a largo plazo.</li>
                                    <li className='fw-medium px-2 py-2'>Mayor acceso a la atención médica en áreas remotas.</li>
                                    <li className='fw-medium px-2 py-2'>Gestión de la salud pública más efectiva.</li>
                                </ul>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2'>En FISP, creemos firmemente que la implementación de tecnologías de salud avanzadas en gobiernos es un paso esencial hacia un sistema de atención médica más sostenible y centrado en el paciente.</p>
                        <p className='parrafo-servicio fs-5 pb-2'>Estamos comprometidos a trabajar en estrecha colaboración con su gobierno para lograr una transformación exitosa que beneficie a todos los ciudadanos..</p>
                        <p className='parrafo-servicio fs-5 pb-2'>Permítanos ser su socio en esta misión de mejorar la salud y el bienestar de su comunidad.</p>
                        <p className='parrafo-servicio fs-5 pb-2'>Contáctenos hoy mismo para iniciar esta emocionante transformación.</p>
                        <Link to='/servicios/contacto/'>
                            <button className='py-3 position-relative text-white text-shadow d-flex align-items-center justify-content-center mb-5 rounded-4'>
                                CONSULTA TU PRESUPUESTO
                                <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </button>
                        </Link>
                    </div>
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default transicionBottomTop(FirmaDigital);