import React from 'react'

const TemarioPeelingCosmiatras = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Introducción</span>
            <br /><br />
            <ul>
                <li className='fw-normal'>Estructura de la piel</li>
                <li className='fw-normal'>Tratamientos estéticos más frecuentes</li>
                <li className='fw-normal'>Acné. Rosácea. Cicatrices. Melasma. Fotoenvejecimiento</li>
                <li className='fw-normal'>Aparatología.</li>
            </ul>
            <span>Workshop práctico online</span>
            <br /><br />
                <ul>
                    <li className='fw-normal'>Peelings superficiales</li>
                    <li className='fw-normal'>Ácidos</li>
                    <li className='fw-normal'>Máscaras faciales. Sus tipos y pasos de tratamiento estético.</li>
                    <li className='fw-normal'>Complicaciones</li>
                </ul>
        </div>
    )
}

export default TemarioPeelingCosmiatras