import React from 'react'
import { Link } from 'react-router-dom'
import ServiciosImage from '../Componentes/multimedia/imagenes/home/servicios-home.jpg'
import EducacionImage from '../Componentes/multimedia/imagenes/home/educacion-home.jpg'
import PortalImage from '../Componentes/multimedia/imagenes/home/portal-home.jpg'
import HomeLogo from '../Componentes/multimedia/imagenes/home/FISP_Entero.png'
import LogoOscuro from '../Componentes/multimedia/imagenes/logo-iniciales-oscuro.png'
import transicionEscalera from './Transiciones/transicionEscalera'
import transicionBottomTop from './Transiciones/transicionBottomTop'

const Home = () => {
    return (
        <>
            <div className="home-mobile-logo">
                <img className='h-75' src={LogoOscuro} alt="Logo FISP" />
            </div>
            <main className="container-fluid home">
                <div className="row d-flex align-items-center overflow-hidden main-home-container position-relative vh-100">
                    <div className="main-home-logo z-10">
                        <img className='object-fit-cover' src={HomeLogo} alt="Logo Fundacion Iberoamericana de Salud Publica" />
                    </div>
                    <div className="home-section z-0 col-lg-4 col-12 px-0 position-relative d-flex justify-content-center align-items-center h-100">
                        <Link to='/servicios/' className='position-relative z-1 poppins fs-1 w-100 h-100 d-flex align-items-center justify-content-center'>
                            <div className='w-100 h-100 overflow-hidden'>
                                <img className='w-100 h-100 object-fit-cover start-0 top-0 home-image' src={ServiciosImage} alt="Servicios FISP" />
                            </div>
                            <button className="home-button">
                                <h1 className='fw-bold fs-2 z-3 home-section-title myriad text-shadow-bold p-0 m-0'>SERVICIOS</h1>
                            </button>
                        </Link>
                    </div>
                    <div className="home-section z-0 col-lg-4 col-12 px-0 position-relative d-flex justify-content-center align-items-center h-100">
                        <Link to='/educativo/' className='position-relative z-1 poppins fs-1 w-100 h-100 d-flex align-items-center justify-content-center'>
                            <div className='w-100 h-100 overflow-hidden'>
                                <img className='w-100 h-100 object-fit-cover start-0 top-0 home-image' src={EducacionImage} alt="Educacion FISP" />
                            </div>
                            <button className="home-button">
                                <h1 className='fw-bold fs-2 z-3 home-section-title myriad text-shadow-bold p-0 m-0'>EDUCACIÓN</h1>
                            </button>
                        </Link>
                    </div>
                    <div className="home-section z-0 col-lg-4 col-12 px-0 position-relative d-flex justify-content-center align-items-center h-100">
                        <Link to='/portal-de-noticias/' className='position-relative z-1 poppins fs-1 w-100 h-100 d-flex align-items-center justify-content-center'>
                            <div className='w-100 h-100 overflow-hidden'>
                                <img className='w-100 h-100 object-fit-cover start-0 top-0 home-image' src={PortalImage} alt="Portal de noticias FISP" />
                            </div>
                            <button className="home-button">
                                <h1 className='fw-bold fs-2 z-3 home-section-title myriad text-shadow-bold p-0 m-0'>NOTICIAS</h1>
                            </button>
                        </Link>
                    </div>
                </div>
            </main>
        </>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(Home) : transicionBottomTop(Home);
