import React, { useEffect, useRef } from 'react'
import SideNav from '../Navbars/SideNav'
import RegularNavbar from '../Navbars/RegularNavbar'
import Aguero from '../multimedia/imagenes/fundacion/staff-academico/Aguero.png'
import Dadic from '../multimedia/imagenes/fundacion/staff-academico/Dadic.png'
import DeVicentiis from '../multimedia/imagenes/fundacion/staff-academico/DeVicentiis.png'
import Fausto from '../multimedia/imagenes/fundacion/staff-academico/Fausto.png'
import Manfredi from '../multimedia/imagenes/fundacion/staff-academico/Manfredi.png'
import Pastor from '../multimedia/imagenes/fundacion/staff-academico/Pastor.png'
import Pietrantonio from '../multimedia/imagenes/fundacion/staff-academico/Pietrantonio.png'
import Pingitore from '../multimedia/imagenes/fundacion/staff-academico/Pingitore.png'
import Rotella from '../multimedia/imagenes/fundacion/staff-academico/Rotella.png'
import Guallan from '../multimedia/imagenes/fundacion/staff-academico/Guallan.png'
import Slipak from '../multimedia/imagenes/fundacion/staff-academico/Slipak.png'
import Vargas from '../multimedia/imagenes/fundacion/staff-academico/Vargas.jpg'
import Alet from '../multimedia/imagenes/fundacion/staff-academico/Alet.jpg'
import Zareba from '../multimedia/imagenes/fundacion/staff-academico/Zareba.jpg'
import Buscio from '../multimedia/imagenes/fundacion/staff-academico/Buscio.jpg'
import Blanco from '../multimedia/imagenes/fundacion/staff-academico/Blanco.jpg'
import Kersz from '../multimedia/imagenes/fundacion/staff-academico/Kersz.jpg'
import Lauretta from '../multimedia/imagenes/fundacion/staff-academico/Lauretta.jpg'

import transicionEscalera from '../Transiciones/transicionEscalera'
import transicionBottomTop from '../Transiciones/transicionBottomTop'


const Staff = () => {
    const isMountedRef = useRef(false);
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }   
    },[])

    const staffAcademico = [
        [{
            foto: Aguero,
            nombre: "Dr. Abel Luis Agüero",
            descripcion: "Director Académico",
        },{
            foto: Buscio,
            nombre: "Lic. Sol Buscio",
            descripcion: "Directora de Psicología" 
        },{
            foto: Pingitore,
            nombre: "Dra. Sylvia Pingitore",
            descripcion: "Directora de Dermatología",
        },{
            foto: Rotella,
            nombre: "Dr. Ricardo Martín Rotella",
            descripcion: "Director de Medicina Regenerativa y Precisión",
        },{
            foto: Manfredi,
            nombre: "Dra. Elena Pastor Manfredi",
            descripcion: "Directora de Nutrición",
        },{
            foto: DeVicentiis,
            nombre: "Lic. Leonardo de Vincentiis",
            descripcion: "Director de Enfermería",
        },{
            foto: Kersz,
            nombre: "Lic. Mariana Kersz",
            descripcion: "Directora de Sexología",
        },{
            foto: Lauretta,
            nombre: "Dr. Paolo Lauretta",
            descripcion: "Director de Inteligencia Artificial en Medicina",
        }],
        [{
            foto: Fausto,
            nombre: "Dr. Sergio Adrián Fausto",
            descripcion: "Director de Deportología y Traumatología",
        },{
            foto: Vargas,
            nombre: "Dr. Agustín López Vargas",
            descripcion: "Director de Cirugía",
        },{
            foto: Blanco,
            nombre: "Barbara Blanco",
            descripcion: "Directora de Fitness",
        },{
            foto: Pietrantonio,
            nombre: "Dr. Carlos A. Di Pietrantonio",
            descripcion: "Director de Epidemiología",
        },{
            foto: Dadic,
            nombre: "Dr. Francisco Dadic",
            descripcion: "Director de Toxicología",
        },{
            foto: Slipak,
            nombre: "Dr. Óscar Eduardo Slipak ",
            descripcion: "Director de Psiquiatría",
        },{
            foto: Pastor,
            nombre: "Dr. Raul Pastor",
            descripcion: "Director de Calidad de vida",
        },{
            foto: Zareba,
            nombre: "Dra. Natalia Zareba",
            descripcion: "Directora de Cardiología"
        },{
            foto: Alet,
            nombre: "Dr. Matías Alet",
            descripcion: "Director de Neurología"
        }]
    ]
    return (
        <main className='s' id='main-element'>
            <SideNav />
            <div className="container-xxl">
                <div className="staff-container py-5">
                    <h1 className='text-center poppins py-5' id='staff'>Nuestro equipo de trabajo</h1>
                    <p className='fs-5 text-center px-4'>La Fundación Iberoamericana de Salud Pública está integrada por un equipo altamente calificado y comprometido, dedicado a brindar información de calidad. Cada miembro desempeña un papel fundamental en nuestra misión de promover la salud y el bienestar en la  comunidad.</p>
                    <p className='fs-5 text-center px-4'>En FISP creemos que la salud es un activo invaluable y nos comprometemos a mantenerlo en su máximo potencial de la mano de un equipo de reconocidos profesionales médicos del país especializados en distintas áreas.</p>
                    <div className="staff-academico py-5 d-flex flex-column align-items-center">
                        <h2 className='poppins ms-3 d-flex align-items-center'>
                            <svg className='me-3' width="36" height="36" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M5 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
                                <path d="M9 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
                                <path d="M5 8h4" />
                                <path d="M9 16h4" />
                                <path d="M13.803 4.56l2.184 -.53c.562 -.135 1.133 .19 1.282 .732l3.695 13.418a1.02 1.02 0 0 1 -.634 1.219l-.133 .041l-2.184 .53c-.562 .135 -1.133 -.19 -1.282 -.732l-3.695 -13.418a1.02 1.02 0 0 1 .634 -1.219l.133 -.041z" />
                                <path d="M14 9l4 -1" />
                                <path d="M16 16l3.923 -.98" />
                            </svg>
                            STAFF Académico 
                        </h2>
                        <div className="col-12 py-2 py-sm-5">
                            <div className="carousel-container">
                                <div className="carousel-slide-right">
                                    {
                                        staffAcademico[0].map((member,index)=>{
                                            return(
                                                <div className="carousel-element" key={index}>
                                                    <div className="overlay d-flex flex-column">
                                                        <p className='px-3 my-0 text-center fw-regular'>{member.nombre}</p>
                                                        <p className='px-3 text-center fw-light'>{member.descripcion}</p>
                                                    </div>
                                                    <img loading='lazy' src={member.foto} alt={member.descripcion} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="carousel-slide-right">
                                    {
                                        staffAcademico[0].map((member,index)=>{
                                            return(
                                                <div className="carousel-element" key={index}>
                                                    <div className="overlay d-flex flex-column">
                                                        <p className='px-3 my-0 text-center fw-regular'>{member.nombre}</p>
                                                        <p className='px-3 text-center fw-light'>{member.descripcion}</p>
                                                    </div>
                                                    <img loading='lazy' src={member.foto} alt={member.descripcion} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="carousel-container">
                                <div className="carousel-slide-left">
                                    {
                                        staffAcademico[1].map((member,index)=>{
                                            return(
                                                <div className="carousel-element" key={index}>
                                                    <div className="overlay d-flex flex-column">
                                                        <p className='px-3 my-0 text-center fw-regular'>{member.nombre}</p>
                                                        <p className='px-3 text-center fw-light'>{member.descripcion}</p>
                                                    </div>
                                                    <img loading='lazy' src={member.foto} alt={member.descripcion} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="carousel-slide-left">
                                    {
                                        staffAcademico[1].map((member,index)=>{
                                            return(
                                                <div className="carousel-element" key={index}>
                                                    <div className="overlay d-flex flex-column">
                                                        <p className='px-3 my-0 text-center fw-regular'>{member.nombre}</p>
                                                        <p className='px-3 text-center fw-light'>{member.descripcion}</p>
                                                    </div>
                                                    <img loading='lazy' src={member.foto} alt={member.descripcion} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RegularNavbar />
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(Staff) : transicionBottomTop(Staff);
